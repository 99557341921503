import { TFunction } from "i18next";
import * as yup from "yup";

import { TargetCustomer } from "@/types/Api";

type Roles = NonNullable<TargetCustomer["roles"]>[number];

export const TARGET_CUSTOMER_ROLES: { label: Roles; value: Roles }[] = [
  {
    label: "Staff",
    value: "Staff",
  },
  {
    label: "Student",
    value: "Student",
  },
];

export const targetCustomerSchema = (t: TFunction) => {
  const requiredCriteriaMsg = t("menu.validation.required", {
    what: t("menu.targetCustomer.oneOfcriterias", {
      ns: "dashboard",
    }),
  });
  return yup.object().shape(
    {
      name: yup.string().required(
        t("menu.validation.required", {
          what: t("menu.name", { ns: "dashboard" }),
        }),
      ),
      colleges: yup.array().when(["cohort", "users", "roles"], {
        is: (
          cohort?: string | null,
          users?: number[] | null,
          roles?: string[] | null,
        ) => !!cohort || (users?.length ?? 0) > 0 || (roles?.length ?? 0) > 0,
        then: (schema) => schema.nullable(),
        otherwise: (schema) =>
          schema.required(requiredCriteriaMsg).min(1, requiredCriteriaMsg),
      }),
      cohort: yup.string().when(["colleges", "users", "roles"], {
        is: (
          colleges?: (string | number)[],
          users?: number[] | null,
          roles?: string[] | null,
        ) =>
          (colleges?.length ?? 0) > 0 ||
          (users?.length ?? 0) > 0 ||
          (roles?.length ?? 0) > 0,
        then: (schema) => schema.nullable(),
        otherwise: (schema) => schema.required(requiredCriteriaMsg),
      }),
      users: yup
        .array()
        .of(yup.string())
        .when(["cohort", "colleges", "roles"], {
          is: (
            cohort?: string | null,
            colleges?: (string | number)[] | null,
            roles?: string[] | null,
          ) =>
            !!cohort || (colleges?.length ?? 0) > 0 || (roles?.length ?? 0) > 0,
          then: (schema) => schema.nullable(),
          otherwise: (schema) =>
            schema.required(requiredCriteriaMsg).min(1, requiredCriteriaMsg),
        }),
      roles: yup
        .array()
        .of(yup.string())
        .when(["cohort", "users", "colleges"], {
          is: (
            cohort?: string | null,
            users?: number[] | null,
            colleges?: (number | string)[] | null,
          ) =>
            !!cohort || (users?.length ?? 0) > 0 || (colleges?.length ?? 0) > 0,
          then: (schema) => schema.nullable(),
          otherwise: (schema) =>
            schema.required(requiredCriteriaMsg).min(1, requiredCriteriaMsg),
        }),
    },
    [
      ["cohort", "colleges"],
      ["cohort", "users"],
      ["cohort", "roles"],
      ["users", "roles"],
      ["users", "colleges"],
      ["roles", "colleges"],
    ],
  );
};

import { PlusOutlined } from "@ant-design/icons";
import { Form, Input, Typography, UploadFile } from "antd";
import { useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  LayoutDetailsForm,
  LayoutDetailsFormProps,
  UploadImage,
} from "@/components";
import { useImage } from "@/hooks";
import { SUPPORTED_IMAGE_TYPES } from "@/lib/constants";
import { NotificationType } from "@/types/Api";

const Style = () => {
  return (
    <style>
      {`
            .ant-upload-list-picture-card-container,
            .ant-upload.ant-upload-select-picture-card {
                width: 56px;
                height: 56px;
            }
       `}
    </style>
  );
};

interface Props extends LayoutDetailsFormProps<NotificationType> {}

const NotiTypesForm = ({
  title,
  isEdit,
  onEdit,
  isSubmitLoading,
  onSubmit,
  onClose,
  form,
}: Props) => {
  const { t } = useTranslation("dashboard");
  const { uploadImage } = useImage();
  const formThumbnail = form.getValues("icon");
  const [fileList, setFiledList] = useState<UploadFile[]>(
    formThumbnail
      ? [
          {
            uid: crypto.randomUUID(),
            name: formThumbnail,
            status: "done",
            url: formThumbnail,
            thumbUrl: formThumbnail,
          },
        ]
      : [],
  );

  return (
    <LayoutDetailsForm
      form={form}
      title={title}
      onEdit={onEdit}
      isEdit={isEdit}
      isSubmitLoading={isSubmitLoading}
      onSubmit={onSubmit}
      onClose={onClose}
    >
      <Style />
      <Controller
        control={form.control}
        name="name"
        render={({ field, fieldState: { error } }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.vnTitle")}
                </Typography.Text>
              }
              validateStatus={error ? "error" : ""}
              required={isEdit}
              help={error?.message}
            >
              <Input
                {...field}
                placeholder={t("menu.editHere")}
                disabled={!isEdit}
                value={field.value}
              />
            </Form.Item>
          );
        }}
      />
      <Controller
        control={form.control}
        name="en_name"
        render={({ field, fieldState: { error } }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.enTitle")}
                </Typography.Text>
              }
              required={isEdit}
              validateStatus={error ? "error" : ""}
              help={error?.message}
            >
              <Input
                {...field}
                placeholder={t("menu.editHere")}
                status={error && "error"}
                disabled={!isEdit}
                value={field.value}
              />
            </Form.Item>
          );
        }}
      />
      <Controller
        control={form.control}
        name="icon"
        render={({ fieldState: { error } }) => {
          return (
            <Form.Item
              label={
                // eslint-disable-next-line i18next/no-literal-string
                <Typography.Text className="font-medium">Icon</Typography.Text>
              }
              className="col-span-2"
              validateStatus={error ? "error" : ""}
              help={error?.message}
              required={isEdit}
            >
              <UploadImage
                showUploadList={{
                  showDownloadIcon: false,
                  showPreviewIcon: true,
                  showRemoveIcon: isEdit,
                }}
                disabled={!isEdit}
                accept={SUPPORTED_IMAGE_TYPES.join(",")}
                onRemove={(file) => {
                  setFiledList((prev) => {
                    const result = prev.filter((item) => item.uid !== file.uid);
                    return [...result];
                  });
                  form.setValue("icon", "");
                }}
                customRequest={(options) => {
                  void uploadImage(
                    { file: options.file as File },
                    {
                      onSuccess: (thumbnailUrl) => {
                        form.setValue("icon", thumbnailUrl);
                        setFiledList([
                          {
                            name: thumbnailUrl,
                            url: thumbnailUrl,
                            thumbUrl: thumbnailUrl,
                            status: "done",
                            uid: crypto.randomUUID(),
                          },
                        ]);
                      },
                    },
                  );
                }}
                fileList={fileList}
                emptyComponent={isEdit ? null : undefined}
              >
                {isEdit && fileList.length === 0 && (
                  <div className="flex flex-col items-center gap-1">
                    <PlusOutlined className="" />
                    <span>{t("menu.upload")}</span>
                  </div>
                )}
              </UploadImage>
              <Typography.Text className="opacity-70">
                {t("menu.notification.imageFormat")}
              </Typography.Text>
            </Form.Item>
          );
        }}
      />
    </LayoutDetailsForm>
  );
};

export default NotiTypesForm;

import { useMutation } from "@tanstack/react-query";
import { useAtomValue, useSetAtom } from "jotai";

import { globalStore } from "@/jotai-atoms";
import { queryClient } from "@/lib/config/queryClient";
import { fetchApiClient } from "@/lib/utils/fetchUtils";
import { WebFacilityPayload } from "@/types/Api";

import { facilitiesAtoms, facilitiesQueries } from "./store";

export const useFacilities = () => {
  return useAtomValue(facilitiesAtoms.list);
};

export const useAllFacilityTypes = () =>
  useAtomValue(facilitiesAtoms.allFacilityType);

export const useFacilitiesFilter = () => {
  const filter = useAtomValue(facilitiesAtoms.filter.currentValueAtom);
  const setFilter = useSetAtom(facilitiesAtoms.filter.debouncedValueAtom);
  return { filter, setFilter };
};

export const useFacilitiesDetails = (id: string) => {
  return useAtomValue(facilitiesAtoms.details(id));
};

export const useUpdateFacility = () =>
  useMutation({
    mutationFn: async (params: { id: string; data: WebFacilityPayload }) => {
      let image = '';
      if (params.data.image) {
        const pathname = new URL(params.data.image).pathname;
        const path = pathname.split("/").slice(2).join("/");
        image = path;
      }
      try {
        await fetchApiClient.facilities.updateAFacility(
          +params.id,
          {
            ...params.data,
            image,
          }
        );
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
    onSuccess: (_, { id }) => {
      if (id) {
        queryClient.removeQueries({
          queryKey: facilitiesQueries.details(+id).queryKey,
        });
        facilitiesAtoms.details.remove(id);
      }
    },
  });

export const useCreateFacility = () =>
  useMutation({
    mutationFn: async (data: WebFacilityPayload) => {
      try {
        await fetchApiClient.facilities.createAFacility(data);
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
  });

export const useDeleteFacilities = () =>
  useMutation({
    mutationFn: async (id: string) => {
      try {
        await fetchApiClient.facilities.deleteAFacility(id);
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: facilitiesQueries.list({
          page: globalStore.get(facilitiesAtoms.filter.currentValueAtom)?.page,
        }).queryKey,
        exact: false,
      });
    },
  });

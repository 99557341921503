import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { Input, Typography, Form, Select, notification } from "antd";
import { t } from "i18next";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { LayoutDetailsForm, MainDashboard } from "@/components";
import { filterSelectOptions } from "@/lib/utils";
import { SkillAdd } from "@/types/Api";

import { addSkillResolver, COHORT_OPTIONS } from "../-data";
import { useCreateOASISSkill } from "../-data/hooks";

export const Route = createFileRoute("/_auth/_dashboard/oasis/skill/add")({
  component: () => <OASISAddSkillPage />,
  beforeLoad: () => {
    return {
      title: t("menu.add", { ns: "dashboard" }),
    };
  },
});

function OASISAddSkillPage() {
  const form = useForm<SkillAdd>({
    defaultValues: { en_name: "", name: "" },
    resolver: addSkillResolver,
  });
  const { t } = useTranslation("dashboard");
  const navigate = useNavigate();
  const { mutate, isPending } = useCreateOASISSkill();

  const handleClose = () => {
    void navigate({ to: "/oasis/skill" });
  };
  const handleSubmit = (data: SkillAdd) => {
    mutate(data, {
      onSuccess: () => {
        notification.success({
          message: t("menu.success"),
          description: t("menu.create.success", {
            what: t("menu.OASIS.skillText", "skill").toLowerCase(),
          }),
        });
        void navigate({ to: "/oasis/skill" });
      },
      onError: () => {
        notification.error({
          message: t("menu.error"),
          description: t("menu.create.error", {
            what: t("menu.OASIS.skillText", "skill").toLowerCase(),
          }),
        });
      },
    });
  };

  return (
    <MainDashboard>
      <LayoutDetailsForm
        form={form}
        isEdit
        onClose={handleClose}
        onSubmit={handleSubmit}
        title={t("menu.add")}
        isSubmitLoading={isPending}
      >
        <Controller
          control={form.control}
          name="en_name"
          render={({ field, fieldState: { error } }) => {
            return (
              <Form.Item
                label={
                  <Typography.Text className="font-medium">
                    {t("menu.enTitle")}
                  </Typography.Text>
                }
                validateStatus={error ? "error" : ""}
                help={error?.message}
                required
              >
                <Input
                  {...field}
                  placeholder={t("menu.editHere")}
                  value={field.value ?? ""}
                />
              </Form.Item>
            );
          }}
        />
        <Controller
          control={form.control}
          name="name"
          render={({ field, fieldState: { error } }) => {
            return (
              <Form.Item
                label={
                  <Typography.Text className="font-medium">
                    {t("menu.title")}
                  </Typography.Text>
                }
                validateStatus={error ? "error" : ""}
                help={error?.message}
                required
              >
                <Input
                  {...field}
                  placeholder={t("menu.editHere")}
                  value={field.value ?? ""}
                />
              </Form.Item>
            );
          }}
        />
        <Controller
          control={form.control}
          name="cohort"
          render={({ field, fieldState: { error } }) => {
            return (
              <Form.Item
                label={
                  <Typography.Text className="font-medium">
                    {t("menu.cohort")}
                  </Typography.Text>
                }
                className="col-span-2"
                validateStatus={error ? "error" : ""}
                help={error?.message}
                required
              >
                <Select
                  showArrow
                  options={COHORT_OPTIONS}
                  placeholder={t("menu.cohort")}
                  filterOption={filterSelectOptions}
                  {...field}
                  value={field.value || null}
                />
              </Form.Item>
            );
          }}
        />
      </LayoutDetailsForm>
    </MainDashboard>
  );
}

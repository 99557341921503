import { EditOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Typography } from "antd";
import { ReactNode, useEffect } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

const Style = () => {
  return (
    <style>
      {`
        h4.ant-typography {
          margin-bottom: 0px;
        }
        .ck-editor__editable_inline:not(.ck-comment__input *) {
          height: 200px;
          overflow-y: auto;
        }
        .ant-upload.ant-upload-select-picture-card  {
          width: 100%;
        }
        .ant-picker-input input[disabled],
        .ant-input[disabled], 
        .ant-checkbox-disabled+span,
        .ant-radio-disabled+span,
        .ant-select-disabled span {
          color: black;
        }
      `}
    </style>
  );
};

export interface LayoutDetailsFormProps<TForm extends FieldValues> {
  form: UseFormReturn<TForm>;
  onSubmit?: (data: TForm) => void;
  isSubmitLoading?: boolean;
  title?: string;
  onEdit?: () => void;
  isEdit?: boolean;
  onClose?: () => void;
  children?: ReactNode;
  rightContent?: ReactNode;
}

export const LayoutDetailsForm = <TForm extends FieldValues>({
  form,
  onSubmit = () => {
    return;
  },
  isSubmitLoading,
  isEdit = false,
  onEdit,
  title,
  onClose,
  children,
  rightContent,
}: LayoutDetailsFormProps<TForm>) => {
  const { t, i18n } = useTranslation("dashboard");

  const {
    trigger,
    formState: { submitCount },
  } = form;

  useEffect(() => {
    if (submitCount > 0) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      trigger();
    }
  }, [trigger, i18n.language, submitCount]);

  return (
    <Form
      onFinish={() => {
        void form.handleSubmit(onSubmit)();
      }}
      layout="vertical"
      className="relative flex h-full flex-col bg-white p-6 py-4"
    >
      <Style />
      <div className="flex">
        <Typography.Title className="mb-0" level={4}>
          {title}
        </Typography.Title>
        <div className="ml-auto flex items-center justify-end gap-2">
          {rightContent}
          {onEdit && !isEdit && (
            <Button
              type="link"
              className="text-brand-primary"
              icon={<EditOutlined />}
              onClick={onEdit}
            >
              {t("menu.edit")}
            </Button>
          )}
        </div>
      </div>
      <Divider className="-mx-6 my-3" />
      <div className="grid grid-cols-2 gap-x-4">{children}</div>
      <div className="mt-auto flex w-full">
        {isEdit ? (
          <div className="ml-auto flex gap-4">
            <Button size="large" onClick={onClose} className="w-[114px]">
              {t("menu.close")}
            </Button>
            <Button
              size="large"
              type="primary"
              className="w-[114px] bg-brand-primary"
              htmlType="submit"
              loading={isSubmitLoading}
            >
              {t("menu.save")}
            </Button>
          </div>
        ) : (
          <Button
            size="large"
            onClick={onClose}
            type="primary"
            className="ml-auto w-[114px] bg-brand-primary"
          >
            {t("menu.close")}
          </Button>
        )}
      </div>
    </Form>
  );
};

import { eventServiceQueries } from "@/jotai-atoms/event-service";
import { createLoader, getDefaultPaginateValues } from "@/lib/utils";

import { facilitiesQueries } from "./store";

const allFacilityQuery = () => facilitiesQueries.allFacilityType({});

export const facilitiesManagementLoader = createLoader(() => [
  facilitiesQueries.list(getDefaultPaginateValues()),
  facilitiesQueries.allFacilityType({}),
]);
export const facilityDetailLoader = createLoader<{ id: string }>((props) => [
  allFacilityQuery(),
  facilitiesQueries.details(Number(props.params.id)),
]);
export const facilityAddLoader = createLoader(() => [allFacilityQuery()]);

export const eventServiceRequestsLoader = createLoader(() => [
  eventServiceQueries.list(getDefaultPaginateValues()),
]);
export const eventServiceRequestDetailLoader = createLoader<{ id: string }>(
  (props) => [
    eventServiceQueries.details(Number(props.params.id)),
    eventServiceQueries.allFacilities({}),
  ],
);

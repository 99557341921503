import { TFunction } from "i18next";
import * as yup from "yup";

export const newsSchema = (t: TFunction) =>
  yup.object().shape(
    {
      title: yup.string().when("en_title", {
        is: (en_title?: string) => !en_title || en_title.length === 0,
        then: (schema) =>
          schema.required(
            t("menu.validation.required", {
              what: t("menu.eitherTitle", { ns: "dashboard" }),
            }),
          ),
        otherwise: (schema) => schema.nullable(),
      }),
      en_title: yup.string().when("title", {
        is: (title: string | undefined) => !title || title.length === 0,
        then: (schema) =>
          schema.required(
            t("menu.validation.required", {
              what: t("menu.eitherTitle", { ns: "dashboard" }),
            }),
          ),
        otherwise: (schema) => schema.nullable(),
      }),
      categories: yup
        .array()
        .of(yup.number())
        .min(
          1,
          t("menu.validation.required", {
            what: t("menu.categories", { ns: "dashboard" }),
          }),
        ),
      content: yup.string().when(["title", "external_id"], {
        is: (title?: string, external_id?: number) =>
          (!!title || (title?.length ?? 0) > 0) && !external_id,
        then: (schema) =>
          schema
            .required(
              t("menu.validation.required", {
                what: t("menu.nContent", { ns: "dashboard" }),
              }),
            )
            .max(
              500000,
              t("menu.validation.tooLarge", {
                what: t("menu.nContent", { ns: "dashboard" }),
              }),
            ),
        otherwise: (schema) => schema.nullable(),
      }),
      en_content: yup.string().when(["en_title", "en_external_id"], {
        is: (value?: string, en_external_id?: number) =>
          (!!value || (value?.length ?? 0) > 0) && !en_external_id,
        then: (schema) =>
          schema
            .required(
              t("menu.validation.required", {
                what: t("menu.nContent", { ns: "dashboard" }),
              }),
            )
            .max(
              500000,
              t("menu.validation.tooLarge", {
                what: t("menu.nContent", { ns: "dashboard" }),
              }),
            ),
        otherwise: (schema) => schema.nullable(),
      }),
      thumbnail: yup.string().required(
        t("menu.validation.required", {
          what: t("menu.thumbnail", { ns: "dashboard" }),
        }),
      ),
      url: yup
        .string()
        .nullable()
        .url(t("menu.validation.mustValidUrl", { ns: "dashboard" })),
      en_url: yup
        .string()
        .nullable()
        .url(t("menu.validation.mustValidUrl", { ns: "dashboard" })),
      published: yup.boolean().nullable(),
      external_id: yup.number().nullable(),
    },
    [
      ["title", "en_title"],
      ["title", "content"],
      ["en_title", "en_content"],
      ["content", "external_id"],
      ["en_content", "en_external_id"],
    ],
  );

export const newsCategorySchema = (t: TFunction) =>
  yup.object().shape({
    name: yup.string().required(
      t("menu.validation.required", {
        what: t("menu.name", { ns: "dashboard" }),
      }),
    ),
    en_name: yup.string().nullable(),
  });

import { useAtomValue, useSetAtom } from "jotai";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";
import { atomFamily } from "jotai/vanilla/utils";

import { useAppMutation } from "@/hooks/useAppMutation";
import { queryClient } from "@/lib/config/queryClient";
import { getDefaultPaginateValues } from "@/lib/utils";
import { fetchApiClient } from "@/lib/utils/fetchUtils";
import { createQuery } from "@/lib/utils/tanstack-query";
import {
  WebEventServicePayload,
  WebEventServiceUpdateStatus,
  WebServiceRatingPayload,
} from "@/types/Api";
import { EventServiceParams } from "@/types/ApiParams";

import atomWithDebounce from "./atomWithDebounce";

const createEventService = createQuery("EVENT_SERVICE");

export type EventServiceRequestsFilterState =
  EventServiceParams<"getListEventServicesWithPagination">;

export const eventServiceQueries = {
  list: createEventService(
    fetchApiClient.eventServices.getListEventServicesWithPagination,
  ),
  details: createEventService(fetchApiClient.eventServices.getAnEventService),
  allFacilities: createEventService(fetchApiClient.facilities.getAllFacilities),
};

const eventServiceListFilter =
  atomWithDebounce<EventServiceRequestsFilterState>(getDefaultPaginateValues());

export const eventServiceAtoms = {
  list: atomWithSuspenseQuery((get) =>
    eventServiceQueries.list(get(eventServiceListFilter.debouncedValueAtom)),
  ),
  filter: eventServiceListFilter,
  details: atomFamily((id: string) =>
    atomWithSuspenseQuery(() => eventServiceQueries.details(Number(id))),
  ),
  allFacilities: atomWithSuspenseQuery(() =>
    eventServiceQueries.allFacilities({}),
  ),
};
export const useEventServiceRequests = () => {
  return useAtomValue(eventServiceAtoms.list);
};

export const useEventServiceRequestsFilter = () => {
  const filter = useAtomValue(eventServiceAtoms.filter.currentValueAtom);
  const setFilter = useSetAtom(eventServiceAtoms.filter.debouncedValueAtom);
  return { filter, setFilter };
};

export const useEventServiceRequestsDetails = (id: string) => {
  return useAtomValue(eventServiceAtoms.details(id));
};

export const useAllFacilities = () => {
  return useAtomValue(eventServiceAtoms.allFacilities);
};

export const useUpdateEventService = () => {
  return useAppMutation({
    mutationFn: async (
      data: WebEventServicePayload & { requestId: number },
    ) => {
      try {
        await fetchApiClient.eventServices.updateAnEventService(
          data.requestId,
          data,
        );
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
    onSuccess: (_, { event }) => {
      if (event) {
        queryClient.removeQueries({
          queryKey: eventServiceQueries.details(event).queryKey,
        });
        eventServiceAtoms.details.remove(event.toString());
      }
    },
  });
};

export const useCreateEventService = () => {
  return useAppMutation({
    mutationFn: async (data: WebEventServicePayload) => {
      try {
        await fetchApiClient.eventServices.createAnEventService(data);
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
  });
};

export const useCreateEventServiceRating = () => {
  return useAppMutation({
    mutationFn: async (data: WebServiceRatingPayload) => {
      try {
        await fetchApiClient.serviceRatings.createAServiceRating(data);
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
  });
};

export const useUpdateEventServiceStatus = () => {
  return useAppMutation({
    mutationFn: async (props: {
      id: number;
      data: WebEventServiceUpdateStatus;
    }) => {
      try {
        await fetchApiClient.eventServices.updateAnEventServiceStatus(
          props.id,
          props.data,
        );
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
  });
};

import { FunnelPlotOutlined } from "@ant-design/icons";
import { useNavigate } from "@tanstack/react-router";
import { Button, Dropdown, Select, Typography } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ListHeader, Stack } from "@/components";
import { cn, filterSelectOptions } from "@/lib/utils";

import { useFacilitiesFilter } from "../../-data/hooks";
import { FacilitiesFilterState } from "../../-data/store";

interface Props {
  onSearchChange: (value: string) => void;
  facilityTypeOptions: { label: string; value: string | number }[];
}

const FacilitiesManagementHeader = ({
  onSearchChange,
  facilityTypeOptions,
}: Props) => {
  const { t } = useTranslation("dashboard");
  const navigate = useNavigate();
  const { filter, setFilter } = useFacilitiesFilter();
  const [facilitiesFilter, setFacilitiesFilter] =
    useState<FacilitiesFilterState>();

  const [open, setOpen] = useState(false);
  const shouldDisableResetBtn = !filter?.type;

  const handleAddNew = () => {
    void navigate({
      to: "/facilities/management/add",
    });
  };

  const handleResetFilter = () => {
    setFilter((prev) => ({ ...prev, page: 1, status: [] }));
    setFacilitiesFilter({ type: undefined });
    setOpen(false);
  };
  const handleApplyFilter = () => {
    setFilter((prev) => ({
      ...prev,
      page: 1,
      type: facilitiesFilter?.type,
    }));
    setOpen(false);
  };

  return (
    <ListHeader
      search={filter?.search}
      onChange={onSearchChange}
      onAddNewClick={handleAddNew}
      title={t("menu.list")}
      filter={
        <Dropdown
          open={open}
          onOpenChange={setOpen}
          trigger={["click"]}
          menu={{
            className: "w-[272px]",
            items: [
              {
                label: (
                  <Stack className="justify-between gap-6">
                    <Button
                      size="small"
                      type="text"
                      onClick={handleResetFilter}
                      disabled={shouldDisableResetBtn}
                      className={cn(
                        !shouldDisableResetBtn &&
                          "text-[var(--ant-primary-color)]",
                      )}
                    >
                      {t("menu.button.reset")}
                    </Button>
                    <Button
                      size="small"
                      type="text"
                      className="bg-brand-primary text-white"
                      onClick={handleApplyFilter}
                    >
                      {t("menu.button.ok")}
                    </Button>
                  </Stack>
                ),
                className: cn("hover:bg-transparent cursor-default px-2"),
                key: "action",
              },
              { type: "divider" },
              {
                label: (
                  <div>
                    <Typography.Text className="font-medium">
                      {t("menu.facilities.type")}
                    </Typography.Text>
                    <Select
                      showArrow
                      allowClear
                      maxTagTextLength={24}
                      placeholder={t("menu.select", {
                        what: t("menu.facilities.type").toLowerCase(),
                      })}
                      className="w-full"
                      options={facilityTypeOptions}
                      onChange={(type) => {
                        setFacilitiesFilter((prev) => {
                          return {
                            ...prev,
                            type,
                          };
                        });
                      }}
                      value={facilitiesFilter?.type}
                      filterOption={filterSelectOptions}
                    />
                  </div>
                ),
                className: "hover:bg-transparent bg-transparent",
                key: "status",
              },
            ],
          }}
        >
          <Stack
            className="cursor-pointer gap-1"
            onClick={() => {
              setOpen(!open);
            }}
          >
            <span>{t("menu.filter")}</span>
            <FunnelPlotOutlined className="text-[#8C8C8C]" />
          </Stack>
        </Dropdown>
      }
    />
  );
};

export default FacilitiesManagementHeader;

import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "@tanstack/react-router";
import { Button, Table, Modal, notification } from "antd";
import { useTranslation } from "react-i18next";

import { ListHeader } from "@/components";
import { useOnRowTable } from "@/hooks/useOnRowTable";

import {
  useDeleteTypes,
  useEventTypes,
  useEventTypesFilter,
} from "../../-data/hooks";

const TypesList = () => {
  const { confirm } = Modal;
  const { data, isFetching } = useEventTypes();
  const { eventTypesFilter, setEventTypesFilter } = useEventTypesFilter();
  const navigate = useNavigate();
  const { mutate: deleteTypes } = useDeleteTypes();
  const { t } = useTranslation("dashboard");
  const onRow = useOnRowTable({ to: "/events/types/$id" });

  const handleDeleteTypes = (id?: number) => {
    if (id) {
      confirm({
        title: "Are you sure delete this task?",
        icon: <ExclamationCircleOutlined />,
        content: "This action will permanently remove a type.",
        onOk() {
          deleteTypes(+id, {
            onSuccess: () => {
              notification.success({
                message: "Success",
                description: "Delete event type successfully",
              });
              void navigate({ to: "/events/types" });
            },
            onError: (error) => {
              notification.error({
                message: "Error",
                description: `Delete event types failed: ${(error as Error | undefined)?.message ?? ""}`,
              });
              void navigate({ to: "/events/types" });
            },
          });
          return;
        },
      });
    }
  };
  return (
    <div className="bg-white p-6 py-4">
      <ListHeader
        search={eventTypesFilter?.search}
        onChange={(search) => {
          setEventTypesFilter((prev) => {
            return {
              ...prev,
              search,
              page: 1,
            };
          });
        }}
        onAddNewClick={() => {
          void navigate({ to: "/events/types/add" });
        }}
        title={t("menu.eventTypes.list")}
      />
      <Table
        onRow={onRow}
        dataSource={data?.results}
        loading={isFetching}
        columns={[
          {
            title: "ID",
            key: "id",
            width: "1ch",
            render: (_, record) => {
              return (
                <Link to={`/events/types/${record.id?.toString() ?? ""}`}>
                  {record.id}
                </Link>
              );
            },
          },
          {
            title: t("menu.name"),
            key: "name",
            render: (_, record) => {
              return <span>{record.name}</span>;
            },
          },
          {
            title: t("menu.action"),
            key: "action",
            render: (_, record) => {
              return (
                <Button
                  type="text"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteTypes(record.id);
                  }}
                  icon={<DeleteOutlined />}
                  danger
                ></Button>
              );
            },
          },
        ]}
        pagination={{
          position: ["bottomCenter"],
          total: data?.count,
          showSizeChanger: false,
          disabled: isFetching,
          current: eventTypesFilter?.page,
          onChange: (page) => {
            setEventTypesFilter((prev) => {
              return {
                ...prev,
                page,
              };
            });
          },
        }}
      />
    </div>
  );
};
export default TypesList;

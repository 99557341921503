import { createFileRoute, Link, useNavigate } from "@tanstack/react-router";
import { Table, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { ListHeader } from "@/components";
import { useOnRowTable } from "@/hooks/useOnRowTable";

import { useListNotiTypes, useListNotiTypesFilter } from "../-data/hooks";
import { notificationTypeLoader } from "../-data/loaders";
import { MainDashboard } from "../../../../../components/MainDashboard";

export const Route = createFileRoute("/_auth/_dashboard/notifications/types/")({
  loader: notificationTypeLoader,
  component: () => <NotificationTypeListPage />,
  beforeLoad: () => {
    return {
      title: null,
    };
  },
});

const NotificationTypeListPage = () => {
  const { data, isFetching } = useListNotiTypes();
  const { filter: notiTypesFilter, setFilter: setNotiTypesFilter } =
    useListNotiTypesFilter();
  const navigate = useNavigate();
  const { t } = useTranslation("dashboard");
  const onRow = useOnRowTable({ to: "/notifications/types/$id" });

  const handlePaginateClick = (page: number) => {
    setNotiTypesFilter((prev) => {
      return {
        ...prev,
        page,
      };
    });
  };

  const handleChange = (search: string) => {
    setNotiTypesFilter((prev) => {
      return {
        ...prev,
        search,
        page: 1,
      };
    });
  };

  const dataSource = (data?.results ?? []).map((item) => ({
    ...item,
    key: item.name,
  }));

  return (
    <MainDashboard>
      <div className="bg-white p-6 py-4">
        <ListHeader
          isLoading={isFetching}
          search={notiTypesFilter.search}
          onChange={handleChange}
          title={t("menu.notification.types", { ns: "dashboard" })}
          onAddNewClick={() => {
            void navigate({ to: "/notifications/types/add" });
          }}
        />
        <Table
          loading={isFetching}
          pagination={{
            position: ["bottomCenter"],
            total: data?.count,
            showSizeChanger: false,
            current: notiTypesFilter.page,
            onChange: handlePaginateClick,
          }}
          dataSource={dataSource}
          onRow={onRow}
          columns={[
            {
              title: "ID",
              key: "id",
              width: "1ch",
              render: (_, record) => {
                return (
                  <Link
                    className="text-[var(--ant-primary-color)]"
                    to={`/notifications/types/${record.id?.toString() ?? ""}`}
                  >
                    {record.id}
                  </Link>
                );
              },
            },
            {
              title: t("menu.enName"),
              key: "name",
              render: (_, record) => {
                return (
                  <Typography.Text>
                    {record.en_name || record.name || "-"}
                  </Typography.Text>
                );
              },
            },
          ]}
        />
      </div>
    </MainDashboard>
  );
};

import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { createFileRoute, Link } from "@tanstack/react-router";
import { Button, Modal, notification, Table } from "antd";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components";
import { useCreateHandleFilterFunc } from "@/hooks/useCreateHandleFilterFunc";
import { useDataSource } from "@/hooks/useDataSource";
import { useOnRowTable } from "@/hooks/useOnRowTable";
import { calculateTableRecordByIndex, removeHtmlTags } from "@/lib/utils";

import FacilitiesManagementHeader from "../-components/ListHeaders/FacilitiesManagementHeader";
import {
  useAllFacilityTypes,
  useDeleteFacilities,
  useFacilities,
  useFacilitiesFilter,
} from "../-data/hooks";
import { facilitiesManagementLoader } from "../-data/loaders";

export const Route = createFileRoute(
  "/_auth/_dashboard/facilities/management/",
)({
  component: () => <FacilitiesManagementPage />,
  beforeLoad: () => {
    return {
      title: null,
    };
  },
  loader: facilitiesManagementLoader,
});

function FacilitiesManagementPage() {
  const { t } = useTranslation("dashboard");
  const onRow = useOnRowTable({ to: "/facilities/management/$id" });
  const { data, isFetching } = useFacilities();
  const { filter, setFilter } = useFacilitiesFilter();
  const { handleSearchChange, handlePaginateChange } =
    useCreateHandleFilterFunc(setFilter);
  const dataSource = useDataSource(data?.results);
  const { mutate } = useDeleteFacilities();

  const { data: facilitiesTypes } = useAllFacilityTypes();

  return (
    <MainDashboard>
      <div className="bg-white p-6 py-4">
        <FacilitiesManagementHeader
          facilityTypeOptions={
            facilitiesTypes?.map((item) => ({
              label: item.name,
              value: item.id,
            })) ?? []
          }
          onSearchChange={handleSearchChange}
        />
        <Table
          loading={isFetching}
          pagination={{
            position: ["bottomCenter"],
            total: data?.count,
            showSizeChanger: false,
            current: filter?.page,
            onChange: handlePaginateChange,
          }}
          onRow={onRow}
          dataSource={dataSource}
          columns={[
            {
              title: "No.",
              key: "id",
              width: "1ch",
              render: (_, record, index) => {
                return (
                  <Link
                    className="text-[var(--ant-primary-color)]"
                    to="/oasis/advisor/$id"
                    params={{ id: String(record.id) }}
                  >
                    {calculateTableRecordByIndex(index, filter?.page)}
                  </Link>
                );
              },
            },
            {
              title: t("menu.image.image"),
              key: "image",
              render: (_, record) => (
                <img
                  src={record.image ?? ""}
                  className="object-contain"
                  width={75}
                  height={50}
                />
              ),
            },
            {
              title: t("menu.name"),
              key: "name",
              render: (_, record) => record.name,
            },
            {
              title: t("menu.facilities.description"),
              key: "size",
              render: (_, record) => removeHtmlTags(record.description),
            },
            {
              title: t("menu.facilities.type"),
              key: "type",
              render: (_, record) => record.type_name ?? "--/--",
            },
            {
              title: t("menu.facilities.quantity"),
              key: "quantity",
              render: (_, record) => record.quantity ?? 0,
            },
            {
              title: t("menu.action"),
              key: "action",
              render: (_, { id }) => (
                <Button
                  type="text"
                  onClick={(e) => {
                    e.stopPropagation();
                    Modal.confirm({
                      title: t("menu.facilities.deleteFacilityTitle"),
                      icon: (
                        <ExclamationCircleOutlined className="!text-red-500" />
                      ),
                      content: t("menu.facilities.deleteFacilityDesc"),
                      onOk() {
                        mutate(String(id), {
                          onSuccess: () => {
                            notification.success({
                              message: t("menu.success"),
                              description: t("menu.delete.success", {
                                what: t(
                                  "menu.facilities.facility",
                                  "facility",
                                ).toLowerCase(),
                              }),
                            });
                          },
                          onError: () => {
                            notification.error({
                              message: t("menu.error"),
                              description: t("menu.delete.error", {
                                what: t(
                                  "menu.facilities.facility",
                                  "facility",
                                ).toLowerCase(),
                              }),
                            });
                          },
                        });
                      },
                    });
                  }}
                  icon={<DeleteOutlined className="text-xl" />}
                  danger
                />
              ),
            },
          ]}
        />
      </div>
    </MainDashboard>
  );
}

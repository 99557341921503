import { useQueryClient } from "@tanstack/react-query";
import { Link, useNavigate } from "@tanstack/react-router";
import { Table, Modal, Badge, BadgeProps, Switch, notification } from "antd";
import { useTranslation } from "react-i18next";

import { ListHeader } from "@/components";
import { useOnRowTable } from "@/hooks/useOnRowTable";
import { formatFullDateTime } from "@/lib/utils";

import {
  useGroupEvents,
  useListEventGroupFilter,
  usePublishGroupEvent,
} from "../../-data/hooks";

const STATUS_COLOR: Record<string, BadgeProps["status"]> = {
  UPCOMING: "processing",
  ONGOING: "success",
  COMPLETED: "default",
  CANCELLED: "error",
};

const GroupEventList = () => {
  const { confirm } = Modal;
  const { t } = useTranslation("dashboard");
  const { data: groupEvents, isFetching } = useGroupEvents();
  const {
    filter: eventGroupListFilter,
    setFilter: setDebounceEventGroupListFilter,
  } = useListEventGroupFilter();
  const { mutate: publishGroupEvent, isPending: ispublishGroupEventLoading } =
    usePublishGroupEvent();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const onRow = useOnRowTable({ to: "/events/groups/$id" });

  return (
    <div className="bg-white p-6 py-4">
      <ListHeader
        onAddNewClick={() => {
          void navigate({ to: "/events/groups/add" });
        }}
        title={t("menu.list")}
      />
      <Table
        dataSource={groupEvents?.results}
        loading={isFetching}
        onRow={onRow}
        columns={[
          {
            title: "ID",
            key: "id",
            render: (_, record) => {
              return (
                <Link
                  to="/events/groups/$id"
                  params={{ id: record.id?.toString() ?? "" }}
                >
                  {record.id}
                </Link>
              );
            },
          },
          {
            title: t("menu.title"),
            key: "title",
            render: (_, record) => {
              return <span>{record.title}</span>;
            },
          },
          {
            title: t("menu.status"),
            key: "status",
            render: (_, record) => {
              let statusText = "";
              if (record.status) {
                statusText =
                  record.status.charAt(0).toUpperCase() +
                  record.status.slice(1).toLowerCase();
              }
              return (
                <Badge
                  className="flex flex-nowrap items-center"
                  status={
                    record.status ? STATUS_COLOR[record.status] : "default"
                  }
                  text={statusText}
                />
              );
            },
          },
          {
            title: t("menu.events.eventGroupType"),
            key: "type",
            render: (_, record) => {
              return <span>{record.type}</span>;
            },
          },
          {
            title: t("menu.startDate"),
            key: "start_at",
            render: (_, record) => {
              return <span> {formatFullDateTime(record.start_at)}</span>;
            },
          },
          {
            title: t("menu.endDate"),
            key: "end_at",
            render: (_, record) => {
              return <span> {formatFullDateTime(record.end_at)}</span>;
            },
          },
          {
            title: t("menu.published"),
            key: "published",
            className: "!text-center",
            render: (_, record) => {
              return (
                <div className="flex justify-center">
                  <Switch
                    onClick={(checked, e) => {
                      e.stopPropagation();
                      confirm({
                        title: t("menu.events.markEventGroupAs", {
                          what: checked
                            ? t("menu.published")
                            : t("menu.unpublished"),
                        }),
                        content: t("menu.events.showEventGroupAs", {
                          what: checked
                            ? t("menu.published")
                            : t("menu.unpublished"),
                        }),
                        autoFocusButton: null,
                        cancelButtonProps: {
                          size: "large",
                          className: "w-[108px]",
                        },
                        okButtonProps: {
                          size: "large",
                          className: "w-[108px] bg-brand-primary",
                        },
                        onOk: () => {
                          if (!record.id) return;
                          publishGroupEvent(record.id, {
                            onSuccess: () => {
                              notification.success({
                                message: t("menu.events.publishEventGroup", {
                                  status: t("menu.success"),
                                }),
                                description: t("menu.events.publishSuccess"),
                              });
                              void queryClient.invalidateQueries({
                                queryKey: ["event-group-list"],
                                exact: false,
                              });
                            },
                            onError: (err) => {
                              notification.error({
                                message: t("menu.events.publishEventGroup", {
                                  status: t(
                                    "menu.statuses.failed",
                                  ).toLowerCase(),
                                }),
                                description: (err as Error | undefined)
                                  ?.message,
                              });
                            },
                          });
                        },
                        maskClosable: true,
                      });
                    }}
                    checked={record.published}
                  />
                </div>
              );
            },
          },
          {
            title: t("menu.events.media.media"),
            key: "media",
            className: "!text-center",
            render: (_, record) => {
              return (
                <div className="text-center">
                  <Link
                    to="/events/groups/$id/media"
                    params={{ id: record.id?.toString() ?? "" }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {t("menu.view")}
                  </Link>
                </div>
              );
            },
          },
        ]}
        pagination={{
          position: ["bottomCenter"],
          total: groupEvents?.count,
          showSizeChanger: false,
          disabled: isFetching || ispublishGroupEventLoading,
          current: eventGroupListFilter.page,
          onChange: (page) => {
            setDebounceEventGroupListFilter((prev) => {
              return {
                ...prev,
                page,
              };
            });
          },
        }}
      />
    </div>
  );
};
export default GroupEventList;

import {
  createFileRoute,
  useNavigate,
  useParams,
} from "@tanstack/react-router";
import { notification } from "antd";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components/MainDashboard";
import useYupResolver from "@/hooks/useYupResolver";
import { mappingContentToBase64 } from "@/lib/utils";

import BenefitsForm from "../../../-components/BenefitsForm";
import { benefitSchema, BenefitUpdate } from "../../../-data";
import { useBenefitsDetails, useUpdateBenefits } from "../../../-data/hooks";
import { benefitsDetailLoader } from "../../../-data/loaders";

export const Route = createFileRoute(
  "/_auth/_dashboard/benefits/_list/(details)/$id/edit",
)({
  component: () => <BenefitsDetailsPage />,
  loader: benefitsDetailLoader,
  beforeLoad: () => {
    return { title: t("menu.edit", { ns: "dashboard" }) };
  },
});

function BenefitsDetailsPage() {
  const { id } = useParams({
    from: "/_auth/_dashboard/benefits/_list/$id/edit",
  });
  const { data } = useBenefitsDetails(Number(id));
  const { resolver } = useYupResolver<BenefitUpdate>(benefitSchema);
  const form = useForm<BenefitUpdate>({
    defaultValues: {
      ...data,
      type: data?.type?.id,
    },
    resolver,
  });

  const { t } = useTranslation("dashboard");
  const { mutate, isPending } = useUpdateBenefits();
  const navigate = useNavigate();

  const handleSubmit = (data: BenefitUpdate) => {
    const mutateData = mappingContentToBase64(
      {
        ...data,
        created_by: data.created_by?.id,
      },
      "content",
      "en_content",
      "eligibility",
      "en_eligibility",
      "how_to_redeem",
      "en_how_to_redeem",
      "summary",
      "en_summary",
    );
    mutate(mutateData, {
      onSuccess: () => {
        notification.success({
          message: t("menu.success"),
          description: t("menu.update.success", {
            what: t("menu.benefits.benefits"),
          }),
        });
        void navigate({ to: "/benefits/$id", params: { id } });
      },
      onError: () => {
        notification.error({
          message: t("menu.error"),
          description: t("menu.update.error", {
            what: t("menu.benefits.benefits"),
          }),
        });
      },
    });
  };

  return (
    <MainDashboard>
      <BenefitsForm
        isEdit
        form={form}
        isSubmitLoading={isPending}
        title={t("menu.edit")}
        onSubmit={handleSubmit}
      />
    </MainDashboard>
  );
}

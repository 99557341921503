import { CloseCircleOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  FormInstance,
  notification,
  Typography,
  Upload,
  UploadFile,
} from "antd";
import FormItem from "antd/es/form/FormItem";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useUploadXlsx } from "@/hooks/useUploadXlsx";

interface Props {
  formData: FormInstance;
  isEditMode: boolean;
  defaultValue?: string;
}

const ServiceTemplate = ["service", "template"];

const FileTemplate = ({ formData, isEditMode, defaultValue }: Props) => {
  const { isPending, mutate } = useUploadXlsx();
  const { t } = useTranslation("dashboard");
  const initialValue =
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    defaultValue || (formData.getFieldValue(ServiceTemplate) as string);
  const [fileList, setFiledList] = useState<UploadFile[]>(
    initialValue
      ? [
          {
            uid: crypto.randomUUID(),
            name: initialValue,
            status: "done",
            url: initialValue,
            thumbUrl: initialValue,
          },
        ]
      : [],
  );

  const fileTemplate = Form.useWatch(ServiceTemplate, formData) as
    | string
    | undefined;
  const prevFileTemplate = useRef(initialValue);
  const isFakePath = fileTemplate?.includes("fakepath");

  useEffect(() => {
    if (fileTemplate && !isFakePath) {
      prevFileTemplate.current = fileTemplate;
    }
  }, [fileTemplate, isFakePath]);

  const fileTemplateText = useMemo(() => {
    if (!fileTemplate) {
      return prevFileTemplate.current || "";
    }
    if (isFakePath) {
      return prevFileTemplate.current;
    }
    return fileTemplate;
  }, [fileTemplate, isFakePath]);

  const handleDeleteFile = () => {
    prevFileTemplate.current = "";
    formData.setFieldValue(ServiceTemplate, "");
    setFiledList([]);
  };

  return (
    <FormItem
      className="col-span-2 mb-8"
      help={t("menu.facilities.templateHelp")}
      name={ServiceTemplate}
      label={
        <Typography.Text className="font-medium">
          {t("menu.facilities.template")}
        </Typography.Text>
      }
    >
      <div className="flex flex-col gap-1">
        <div className="-mt-2">
          {fileTemplateText ? (
            <div className="flex gap-1 items-center">
              <a
                href={fileTemplateText}
                target="_blank"
                rel="noopener noreferrer"
              >
                {fileTemplateText}
              </a>
              <Button
                onClick={handleDeleteFile}
                type="text"
                icon={<CloseCircleOutlined />}
                disabled={!isEditMode}
              />
            </div>
          ) : (
            t("menu.facilities.noTemplate")
          )}
        </div>
        <Upload
          accept=".xlsx,.xls"
          showUploadList={false}
          customRequest={(options) => {
            const { file } = options;
            mutate(
              { file: file as File, publicMode: true },
              {
                onSuccess: (data) => {
                  formData.setFieldValue(ServiceTemplate, data);
                  setFiledList([
                    {
                      name: data,
                      url: data,
                      thumbUrl: data,
                      status: "done",
                      uid: crypto.randomUUID(),
                    },
                  ]);
                  notification.success({
                    message: t("menu.success"),
                    description: t("menu.facilities.uploadSuccess"),
                  });
                },
              },
            );
          }}
          fileList={fileList}
        >
          <Button
            disabled={!isEditMode}
            type="primary"
            loading={isPending}
            icon={<UploadOutlined />}
          >
            {t("menu.upload")}
          </Button>
        </Upload>
      </div>
    </FormItem>
  );
};

export default FileTemplate;

import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { notification } from "antd";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components";
import useYupResolver from "@/hooks/useYupResolver";
import { useUserInfo } from "@/jotai-atoms/user";
import { mappingContentToBase64 } from "@/lib/utils";

import BenefitsForm from "../-components/BenefitsForm";
import { benefitSchema, BenefitUpdate } from "../-data";
import { useCreateBenefits } from "../-data/hooks";

export const Route = createFileRoute("/_auth/_dashboard/benefits/_list/add")({
  beforeLoad: () => {
    return {
      title: t("menu.add", { ns: "dashboard" }),
    };
  },
  component: () => <AddBenefitsPage />,
});

function AddBenefitsPage() {
  const userInfo = useUserInfo();

  const { t } = useTranslation("dashboard");
  const { resolver } = useYupResolver<BenefitUpdate>(benefitSchema);
  const form = useForm<BenefitUpdate>({
    defaultValues: {
      title: "",
      en_title: "",
      content: "",
      en_content: "",
      eligibility: "",
      en_eligibility: "",
      how_to_redeem: "",
      en_how_to_redeem: "",
      summary: "",
      en_summary: "",
    },
    resolver,
  });
  const { mutate, isPending } = useCreateBenefits();
  const navigate = useNavigate();

  const handleSubmit = (data: BenefitUpdate) => {
    const mutateData = mappingContentToBase64(
      { ...data, created_by: userInfo.data?.user.id },
      "content",
      "en_content",
      "en_eligibility",
      "en_eligibility",
      "how_to_redeem",
      "en_how_to_redeem",
      "summary",
      "en_summary",
    );
    mutate(mutateData, {
      onSuccess: () => {
        notification.success({
          message: t("menu.success"),
          description: t("menu.create.success", {
            what: t("menu.benefits.benefits"),
          }),
        });
        void navigate({ to: "/benefits" });
      },
      onError: () => {
        notification.error({
          message: t("menu.error"),
          description: t("menu.create.error", {
            what: t("menu.benefits.benefits"),
          }),
        });
      },
    });
  };

  return (
    <MainDashboard>
      <BenefitsForm
        form={form}
        isEdit
        isSubmitLoading={isPending}
        title={t("menu.add", { ns: "dashboard" })}
        onSubmit={handleSubmit}
      />
    </MainDashboard>
  );
}

import {
  EditOutlined,
  EyeOutlined,
  PlusOutlined,
  VerticalAlignBottomOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "@tanstack/react-router";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Select,
  Table,
  Tooltip,
  Typography,
  Upload,
  UploadFile,
  UploadProps,
} from "antd";
import dayjs from "dayjs";
import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";

import { DatePicker, Editor } from "@/components";
import { useImage, useResizeImage } from "@/hooks";
import { SUPPORTED_IMAGE_TYPES, BACKEND_API_URI } from "@/lib/constants";
import { cn, filterSelectOptions } from "@/lib/utils";
import { GroupEvent, GroupEventAdd, ReadTargetCustomer } from "@/types/Api";

import { useGroupValidEvents } from "../../-data/hooks";
import { useAllTargetCustomer } from "../../../customers/-data/hooks";

const { Title } = Typography;

const createLabelProxy = (label: string, component: ReactNode) => {
  return new Proxy(component as object, {
    get(target, prop) {
      if (prop === "toLowerCase") {
        return () => label.toLowerCase();
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return Reflect.get(target, prop);
    },
  });
};

interface IGroupEventDetail {
  type: "view" | "create";
  data?: GroupEvent | undefined;
  groupId?: number[];
  submitAction: {
    onSubmit: (data: GroupEventAdd & { id?: number }) => void;
    isLoading: boolean;
  };
}

const GroupEventDetail = ({
  data,
  type,
  submitAction,
  groupId,
}: IGroupEventDetail) => {
  const [form] = Form.useForm();
  const hasDefaultValue = type !== "create";
  const [isEditMode, setEditMode] = useState(!hasDefaultValue);
  const [warningThumbnail, setWarningThumbnail] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation("dashboard");

  const { data: validEvents } = useGroupValidEvents();
  const { data: targetCustomerList } = useAllTargetCustomer();
  const optionsGroupType = [
    {
      label: t("menu.events.weekOfWelcome"),
      value: "wow",
    },
  ];
  const { mutate: resizeImage } = useResizeImage();
  const [fileList, setFileList] = useState<UploadFile[]>(
    data?.thumbnail
      ? [
          {
            uid: "-1",
            name: data.thumbnail,
            status: "done",
            url: data.thumbnail,
            thumbUrl: data.thumbnail,
          },
        ]
      : [],
  );

  const { uploadImage } = useImage();
  const beforeUpload = (file: UploadFile) => {
    const isImageType = SUPPORTED_IMAGE_TYPES.includes(file.type ?? "");
    if (!isImageType) {
      void message.error(`${file.name} is not a image file`);
    }
    return isImageType || Upload.LIST_IGNORE;
  };
  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFileList([]);
    // Handle get file from Upload Component
    if (
      newFileList[newFileList.length - 1].originFileObj &&
      newFileList.length > 0
    ) {
      setFileList([
        {
          uid: "-1",
          percent: 50,
          name:
            newFileList[newFileList.length - 1].originFileObj?.name ??
            t("menu.events.imageTitle"),
          status: "uploading",
        },
      ]);

      const file = newFileList[newFileList.length - 1].originFileObj as File;
      void uploadImage(
        { file },
        {
          onSuccess: (thumbnailUrl) => {
            const filename = new URL(thumbnailUrl).pathname.replace(
              "/files/",
              "",
            );
            setFileList([
              {
                uid: "-1",
                name:
                  newFileList[newFileList.length - 1].originFileObj?.name ??
                  t("menu.events.imageTitle"),
                status: "done",
                url: thumbnailUrl,
                thumbUrl: thumbnailUrl,
              },
            ]);
            resizeImage({ images: [{ path: filename }] });
            if (warningThumbnail) {
              setWarningThumbnail(false);
            }
          },
        },
      );
    }
  };
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  const handlePreview = () => {
    if (fileList[0].url) {
      setPreviewImage(fileList[0].url);
      setPreviewOpen(true);
    }
  };
  const handleCancel = () => {
    setPreviewOpen(false);
  };
  const handleSubmit = () => {
    if (fileList.length === 0) {
      setWarningThumbnail(true);
    }
  };
  const onFinish = (values: GroupEventAdd) => {
    if (
      fileList.length > 0 &&
      fileList[0].status !== "uploading" &&
      fileList[0].url
    ) {
      const newThumbnail = fileList[0].url.replace(
        /^https?:\/\/[^\\/]+\/files\//,
        "",
      );
      const updatedValues = {
        ...values,
        thumbnail: newThumbnail,
      };

      submitAction.onSubmit(updatedValues);
      setEditMode(false);
    } else {
      setWarningThumbnail(true);
    }
  };
  const [vnDescription, setVnDescription] = useState(
    hasDefaultValue ? data?.description : undefined,
  );
  const handleVnDescriptionEditorChange = (data: string) => {
    setVnDescription(data);
  };
  const [enDescription, setEnDescription] = useState(
    hasDefaultValue ? data?.en_description : undefined,
  );
  const handleEnDescriptionEditorChange = (data: string) => {
    setEnDescription(data);
  };
  // const [bio, setBio] = useState(hasDefaultValue ? data?.bio : "");
  // const handleBioEditorChange = (data: string) => {
  //   setBio(data);
  // };
  const [isTargetCustomersDisabled, setIsTargetCustomersDisabled] = useState(
    data?.type === "wow",
  );

  const handleGroupTypeChange = (value: string) => {
    if (value === "wow") {
      form.setFieldsValue({ target_customers: [] }); // Clear the Target Customers field
      setIsTargetCustomersDisabled(true);
    } else {
      setIsTargetCustomersDisabled(false);
    }
  };

  return (
    <>
      <style>
        {`
      .ant-picker-input input[disabled] {
        color: black !important;
      }
      .ant-checkbox-disabled + span {
      color: black !important;  
      font-weight: 600;
      }
      .ant-select-disabled.ant-select-multiple .ant-select-selection-item{
      color: black !important;
      }
      .ant-select-selection-item{
      color: black;}
    `}
      </style>
      <div className="bg-white">
        <div className="flex justify-between px-6 pb-1.5 pt-4">
          <Title level={4}>
            {type === "view"
              ? isEditMode
                ? t("menu.edit")
                : t("menu.details")
              : t("menu.add")}
          </Title>
          {!isEditMode && (
            <Button
              size="large"
              type="link"
              className="text-brand"
              icon={<EditOutlined />}
              onClick={() => {
                setEditMode(true);
              }}
            >
              {t("menu.edit")}
            </Button>
          )}
        </div>
        <Divider className="m-0" />
        <Form
          form={form}
          name="group_event_details"
          layout="vertical"
          initialValues={{ remember: true }}
          className="m-6 grid grid-cols-2 gap-x-4"
          onFinish={onFinish}
        >
          <Form.Item
            name="en_title"
            label={t("menu.events.enEventGroupName")}
            initialValue={
              hasDefaultValue && data?.en_title ? data.en_title : undefined
            }
            rules={[
              {
                required: true,
                message: t("menu.validation.required", {
                  what: t("menu.events.enEventGroupName"),
                }),
              },
            ]}
          >
            <Input
              className={hasDefaultValue ? "!text-gray-800" : ""}
              disabled={!isEditMode}
              placeholder={t("menu.editHere")}
            />
          </Form.Item>
          <Form.Item
            name="title"
            label={t("menu.events.vnEventGroupName")}
            initialValue={
              hasDefaultValue && data?.title ? data.title : undefined
            }
            rules={[
              {
                required: true,
                message: t("menu.validation.required", {
                  what: t("menu.events.vnEventGroupName"),
                }),
              },
            ]}
          >
            <Input
              className={hasDefaultValue ? "!text-gray-800" : ""}
              disabled={!isEditMode}
              placeholder={t("menu.editHere")}
            />
          </Form.Item>
          <Form.Item
            name="subtitle"
            label={t("menu.events.vnEventGroupSubtitle")}
            initialValue={
              hasDefaultValue && data?.subtitle ? data.subtitle : undefined
            }
            rules={[
              {
                required: true,
                message: t("menu.validation.required", {
                  what: t("menu.events.vnEventGroupSubtitle"),
                }),
              },
            ]}
          >
            <Input
              className={hasDefaultValue ? "!text-gray-800" : ""}
              disabled={!isEditMode}
              placeholder={t("menu.editHere")}
            />
          </Form.Item>
          <Form.Item
            name="en_subtitle"
            label={t("menu.events.enEventGroupSubtitle")}
            initialValue={
              hasDefaultValue && data?.en_subtitle
                ? data.en_subtitle
                : undefined
            }
            rules={[
              {
                required: true,
                message: t("menu.validation.required", {
                  what: t("menu.events.enEventGroupSubtitle"),
                }),
              },
            ]}
          >
            <Input
              className={hasDefaultValue ? "!text-gray-800" : ""}
              disabled={!isEditMode}
              placeholder={t("menu.editHere")}
            />
          </Form.Item>
          <Form.Item
            name="en_description"
            label={t("menu.enDescription")}
            rules={[
              {
                required: true,
                message: t("menu.validation.required", {
                  what: t("menu.enDescription"),
                }),
              },
            ]}
            initialValue={
              hasDefaultValue && data?.en_description
                ? data.en_description
                : undefined
            }
          >
            <Editor
              onChange={handleEnDescriptionEditorChange}
              data={enDescription}
              disabled={!isEditMode}
            ></Editor>
          </Form.Item>
          <Form.Item
            name="description"
            label={t("menu.vnDescription")}
            rules={[
              {
                required: true,
                message: t("menu.validation.required", {
                  what: t("menu.vnDescription"),
                }),
              },
            ]}
            initialValue={
              hasDefaultValue && data?.description
                ? data.description
                : undefined
            }
          >
            <Editor
              onChange={handleVnDescriptionEditorChange}
              data={vnDescription}
              disabled={!isEditMode}
            />
          </Form.Item>
          {!isEditMode && (
            <>
              <Form.Item
                label={t("menu.startDate")}
                initialValue={dayjs(data?.start_at)}
              >
                <DatePicker
                  className="w-full"
                  disabled={!isEditMode}
                  value={data?.start_at ? dayjs(data.start_at) : undefined}
                  showTime
                />
              </Form.Item>
              <Form.Item label={t("menu.endDate")}>
                <DatePicker
                  className="w-full"
                  disabled={!isEditMode}
                  value={data?.start_at ? dayjs(data.end_at) : undefined}
                  showTime
                />
              </Form.Item>
            </>
          )}
          <Form.Item
            className="col-span-2"
            name="type"
            label={t("menu.events.eventGroupType")}
            initialValue={hasDefaultValue && data?.type ? data.type : undefined}
          >
            <Select
              allowClear
              className={hasDefaultValue ? "!text-gray-800" : ""}
              placeholder={t("menu.select", {
                what: t("menu.events.eventGroupType"),
              })}
              disabled={!isEditMode}
              options={optionsGroupType}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={handleGroupTypeChange}
            />
          </Form.Item>
          <Form.Item
            className="col-span-2"
            name="target_customers"
            label={t("menu.targetCustomer.targetCustomer")}
            rules={
              isTargetCustomersDisabled
                ? undefined
                : [
                    {
                      required: true,
                      message: t("menu.validation.required", {
                        what: t("menu.targetCustomer.targetCustomer"),
                      }),
                    },
                  ]
            }
            initialValue={
              hasDefaultValue && data?.target_customers
                ? data.target_customers.map((item) => item.id)
                : undefined
            }
          >
            <Select
              mode="multiple"
              className={hasDefaultValue ? "!text-gray-800" : ""}
              placeholder={t("menu.select", {
                what: t("menu.targetCustomer.targetCustomer"),
              })}
              disabled={!isEditMode || isTargetCustomersDisabled}
              options={(
                targetCustomerList as ReadTargetCustomer[] | undefined
              )?.map((item) => {
                return {
                  label: item.name,
                  value: item.id,
                };
              })}
            />
          </Form.Item>

          <Form.Item
            className="col-span-2"
            name="published"
            valuePropName="checked"
            initialValue={data?.published}
          >
            <Checkbox className="!text-gray-800" disabled={!isEditMode}>
              {t("menu.published")}
            </Checkbox>
          </Form.Item>
          <Form.Item
            className="col-span-2"
            name="events"
            label={t("menu.events.single")}
            rules={[
              {
                required: true,
                message: t("menu.events.requireAtLeastOneSingleEvent"),
              },
            ]}
            initialValue={
              hasDefaultValue && data?.events
                ? data.events.map((event) => event.value)
                : groupId
            }
          >
            <Select
              mode="multiple"
              className={hasDefaultValue ? "!text-gray-800" : ""}
              placeholder={t("menu.select", { what: t("menu.events.single") })}
              disabled={!isEditMode}
              options={[
                ...(validEvents ?? []),
                ...(data?.events ?? []).filter(
                  (item) => !validEvents?.some((ve) => ve.value === item.value),
                ),
              ].map((item) => {
                return {
                  label: createLabelProxy(
                    item.label,
                    <Tooltip
                      placement="top"
                      title={
                        <Link
                          to="/events/$id/registration"
                          params={{ id: item.value.toString() }}
                          className="flex items-center gap-1 text-xs text-white"
                        >
                          {t("menu.facilities.viewCheckedIn")} <EyeOutlined />
                        </Link>
                      }
                    >
                      {item.label}
                    </Tooltip>,
                  ) as unknown as string,
                  value: item.value,
                };
              })}
              filterOption={filterSelectOptions}
            />
          </Form.Item>
          {hasDefaultValue && (
            <Form.Item
              label={t("menu.notification.statistics")}
              className="col-span-2"
            >
              <Table
                dataSource={[data?.ratings ?? {}]}
                columns={[
                  {
                    title: t("menu.feedback.rating"),
                    render: () => {
                      return <div>{t("menu.notification.total")}</div>;
                    },
                  },
                  {
                    title: t("menu.star", { number: 1 }),
                    dataIndex: "one_star",
                    key: "one_star",
                  },
                  {
                    title: t("menu.star", { number: 2 }),
                    dataIndex: "two_star",
                    key: "two_star",
                  },
                  {
                    title: t("menu.star", { number: 3 }),
                    dataIndex: "three_star",
                    key: "three_star",
                  },
                  {
                    title: t("menu.star", { number: 4 }),
                    dataIndex: "four_star",
                    key: "four_star",
                  },
                  {
                    title: t("menu.star", { number: 5 }),
                    dataIndex: "five_star",
                    key: "five_star",
                  },
                  {
                    title: t("menu.exportExcel"),
                    className: "!text-center",
                    render: () => {
                      return (
                        <Button
                          size="large"
                          type="link"
                          target="_blank"
                          href={`${BACKEND_API_URI}/group-events/${data?.id?.toString() ?? ""}/rating/?type=export`}
                        >
                          <VerticalAlignBottomOutlined width={24} height={24} />
                        </Button>
                      );
                    },
                  },
                ]}
                pagination={false}
              />
            </Form.Item>
          )}

          <Form.Item
            label={
              <div>
                <span className="text-red-600">*</span> {t("menu.thumbnail")}
              </div>
            }
            className="col-span-2"
          >
            <Upload
              listType="picture-card"
              fileList={fileList}
              disabled={!isEditMode}
              accept={SUPPORTED_IMAGE_TYPES.join(",")}
              onPreview={handlePreview}
              beforeUpload={beforeUpload}
              onChange={handleChange}
              onRemove={() => {
                setFileList([]);
              }}
            >
              {isEditMode && (
                <div>
                  {fileList.length > 0 ? (
                    <>
                      <EditOutlined />
                      <div>{t("menu.edit")}</div>
                    </>
                  ) : (
                    <>
                      <PlusOutlined />
                      <div>{t("menu.upload")}</div>
                    </>
                  )}
                </div>
              )}
            </Upload>
          </Form.Item>
          <div className="col-span-2">
            {fileList.length === 0 ? (
              <div className="-mt-4 mb-4">
                {!isEditMode ? (
                  <img className="-mt-8" src="/NoThumbnail.svg" alt="" />
                ) : (
                  <div>
                    {warningThumbnail && (
                      <span className="text-red-600">
                        {t("menu.validation.required", {
                          what: t("menu.thumbnail"),
                        })}
                      </span>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div>
                {fileList[0].status === "uploading" && warningThumbnail && (
                  <span className="text-red-600">
                    {t("menu.waitUntilImageLoaded")}
                  </span>
                )}
              </div>
            )}
          </div>

          <Modal
            open={previewOpen}
            title={t("menu.events.previewThumbnail")}
            footer={null}
            onCancel={handleCancel}
          >
            <img
              alt="previewImage"
              style={{ width: "100%" }}
              src={previewImage}
            />
          </Modal>
          <div className="col-span-2">
            <Form.Item className="float-end">
              <Button
                onClick={() => {
                  void navigate({ to: "/events/groups" });
                }}
                size="large"
                className={cn(!isEditMode ? "bg-brand" : "", "w-[144px]")}
                type={isEditMode ? "default" : "primary"}
                htmlType="submit"
              >
                {t("menu.close")}
              </Button>
              {isEditMode && (
                <Button
                  className="ml-5 border-brand bg-brand w-[144px]"
                  type="primary"
                  size="large"
                  onClick={handleSubmit}
                  htmlType="submit"
                >
                  {t("menu.save")}
                </Button>
              )}
            </Form.Item>
          </div>
        </Form>
      </div>
    </>
  );
};

export default GroupEventDetail;

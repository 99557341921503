import { createFileRoute } from "@tanstack/react-router";
import { t } from "i18next";

export const Route = createFileRoute("/_auth/_dashboard/facilities/management")(
  {
    beforeLoad: () => {
      return {
        title: t("menu.facilities.management", { ns: "dashboard" }),
      };
    },
  },
);

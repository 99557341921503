import { Link } from "@tanstack/react-router";
import { Layout, Menu, MenuProps, Typography } from "antd";
import { SetStateAction } from "jotai";
import { Dispatch, memo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Stack } from "@/components";
import { cn } from "@/lib/utils";

import { useMenuItems } from "../-data/hooks";

const { Sider } = Layout;

const Style = memo(() => {
  return (
    <style>
      {`    .app_sidebar {
              .ant-menu-submenu {
                margin: 4px 0;
              }
              .ant-menu-submenu-title {
                margin: 0px;
                position: relative;
                &::after{
                  content: "";
                  opacity: 0;
                  position: absolute;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  border-right: 3px solid var(--color-brand-secondary);
                }
              }
              .ant-menu-item.ant-menu-item-selected,
              .ant-menu-submenu-selected:not(:has(.ant-menu-item-selected))
              {
                  background-color: var(--color-brand-primary);
                  a {
                    color: white;
                  }
                  &, .ant-menu-submenu-arrow{
                    color: var(--ant-primary-1);
                  }
                  .ant-menu-submenu-title {
                    &::after{
                      opacity: 1;
                    }
                    &:hover{
                      color: var(--ant-primary-1);
                    }

                    a:hover {
                      color: var(--ant-primary-1);
                    }
                  }
              }
              .ant-menu-item.ant-menu-item-selected::after, 
              .ant-menu-submenu-selected::after {
                border-right-color: var(--color-brand-secondary)
              } 
            }
            
        `}
    </style>
  );
});

interface Props {
  collapsed: boolean;
  setCollapse: Dispatch<SetStateAction<boolean>>;
}

export const Sidebar = ({ collapsed }: Props) => {
  const { menuItems, currentGroup, currentPage, groupKeys } = useMenuItems();
  const [openKeys, setOpenKeys] = useState([currentGroup]);
  const buildVersion = import.meta.env.VITE_BUILD_VERSION || "";
  const { t } = useTranslation("dashboard");

  const onOpenChange: MenuProps["onOpenChange"] = (keys) => {
    const latestOpenKey = keys.find((key) => !openKeys.includes(key));
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (!groupKeys.includes(latestOpenKey!)) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <>
      <Style />
      <Sider
        className="fixed bottom-0 left-0 top-0 h-screen"
        trigger={null}
        collapsible
        collapsed={collapsed}
        theme="light"
      >
        <Link to="/">
          <Stack className="gap-3 p-4">
            <img src="/vinuni.png" className="h-8 w-8" />
            {!collapsed && (
              <Typography className="text-lg font-medium text-brand-primary">
                VINUNI
              </Typography>
            )}
          </Stack>
        </Link>
        <Menu
          mode="inline"
          className="app_sidebar"
          items={menuItems}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          defaultOpenKeys={[currentGroup]}
          selectedKeys={[currentPage]}
        />
        {buildVersion.length > 0 && (
          <div
            className={cn(
              "fixed bottom-10 z-10",
              collapsed ? "left-1.5" : "left-2.5",
            )}
          >
            <span>
              <Typography.Text
                className={cn(
                  "block duration-300",
                  collapsed ? "translate-x-0" : "translate-x-7",
                )}
              >
                {collapsed ? "v" : t("menu.version", { ns: "dashboard" })}:{" "}
                {buildVersion.slice(0, 8)}
              </Typography.Text>
            </span>
          </div>
        )}
      </Sider>
    </>
  );
};

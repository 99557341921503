import { useNavigate, useRouter } from "@tanstack/react-router";
import { Breadcrumb } from "antd";
import { memo, ReactNode, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { cn } from "@/lib/utils";

interface Props {
  children?: ReactNode;
  className?: string;
}

export const MainDashboard = memo(({ children, className }: Props) => {
  const router = useRouter();
  const navigate = useNavigate();
  const { i18n } = useTranslation("dashboard");

  useEffect(() => {
    i18n.on("languageChanged", () => {
      void router.invalidate();
    });
  }, [i18n, router]);

  const crumbs = router.state.matches.reduce<{ title: string; path: string }[]>(
    (prev, cur) => {
      const {
        pathname,
        context: { title },
      } = cur;

      if (title) {
        return [
          ...prev,
          {
            path: pathname,
            title,
          },
        ];
      }
      return prev;
    },
    [],
  );

  return (
    <>
      <div
        className={cn("border-t border-t-[#F0F0F0] bg-white px-6", className)}
      >
        <Breadcrumb className="p-4">
          {crumbs.map((item) => {
            return (
              <Breadcrumb.Item
                onClick={() => {
                  void navigate({ to: item.path });
                }}
                key={item.path}
                className="ant-breadcrumb-link cursor-pointer"
              >
                {item.title}
              </Breadcrumb.Item>
            );
          })}
        </Breadcrumb>
      </div>
      <div className="flex-1 p-6">{children}</div>
    </>
  );
});

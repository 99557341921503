import { keepPreviousData, queryOptions } from "@tanstack/react-query";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";
import { atomFamily } from "jotai/vanilla/utils";

import atomWithDebounce from "@/jotai-atoms/atomWithDebounce";
import { getDefaultPaginateValues } from "@/lib/utils";
import { fetchApi, fetchApiClient } from "@/lib/utils/fetchUtils";
import { createQuery } from "@/lib/utils/tanstack-query";
import { PaginateFilterState } from "@/types";
import { NotificationTemplate } from "@/types/Api";
import { NotificationTemplatesParams } from "@/types/ApiParams";

export type NotificationListFilterType =
  NotificationTemplatesParams<"getListNotificationTemplates">;

export interface NotificationStatisticType {
  sent_at: string;
  view_count: number;
  success_user_count: number;
  fail_user_count: number;
  total_user_count: number;
  success_device_count: number;
  fail_device_count: number;
  total_device_count: number;
  status: NotificationTemplate["status"];
}

const NOTIFICATION = "NOTIFICATION";
const createNotificationQuery = createQuery(NOTIFICATION);
const { getListNotificationTemplates, getANotificationTemplate } =
  fetchApiClient.notificationTemplates;
const { getListNotificationTypes, getANotificationType } =
  fetchApiClient.notificationTypes;

export const notificationQueries = {
  list: createNotificationQuery(getListNotificationTemplates, {
    placeholderData: keepPreviousData,
  }),
  details: createNotificationQuery(getANotificationTemplate),
  allNotiTypes: createNotificationQuery(getListNotificationTypes),
  listNotiTypes: createNotificationQuery(getListNotificationTypes, {
    placeholderData: keepPreviousData,
  }),
  notiTypesDetails: createNotificationQuery(getANotificationType),
  statistics: (id: string) =>
    queryOptions({
      queryKey: ["notification-details", "statistics", id],
      queryFn: async () => {
        const response = await fetchApi(
          `notification_templates/${id}/statistics/`,
        );
        const result = await response.json<{
          data?: NotificationStatisticType[];
        }>();
        return result.data;
      },
    }),
};

const notificationListFilterAtom = atomWithDebounce<NotificationListFilterType>(
  getDefaultPaginateValues(),
);

const notiTypesFilterAtom = atomWithDebounce<PaginateFilterState>(
  getDefaultPaginateValues(),
);

export const notificationAtoms = {
  filter: notificationListFilterAtom,
  list: atomWithSuspenseQuery((get) => {
    return notificationQueries.list(
      get(notificationListFilterAtom.debouncedValueAtom),
    );
  }),
  details: atomFamily((id: number | string) =>
    atomWithSuspenseQuery(() => notificationQueries.details(id.toString())),
  ),
  notiTypeFilter: notiTypesFilterAtom,
  listNotiTypes: atomWithSuspenseQuery((get) => {
    return notificationQueries.listNotiTypes(
      get(notiTypesFilterAtom.debouncedValueAtom),
    );
  }),
  allNotiTypes: atomWithSuspenseQuery(() => {
    return notificationQueries.allNotiTypes();
  }),
  notiTypeDetails: atomFamily((id: number | string) =>
    atomWithSuspenseQuery(() =>
      notificationQueries.notiTypesDetails(id.toString()),
    ),
  ),
  statistic: atomFamily((id: number | string) =>
    atomWithSuspenseQuery(() => notificationQueries.statistics(id.toString())),
  ),
};

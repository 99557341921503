import { yupResolver } from "@hookform/resolvers/yup";
import { TFunction } from "i18next";
import { useMemo } from "react";
import { FieldValues, Resolver } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

const useYupResolver = <T extends FieldValues>(
  factorySchema: (t: TFunction) => yup.ObjectSchema<yup.AnyObject>,
) => {
  const { t } = useTranslation("dashboard");
  const schema = useMemo(() => factorySchema(t), [factorySchema, t]);

  const resolver = useMemo(
    () => yupResolver(schema) as unknown as Resolver<T>,
    [schema],
  );

  return { resolver };
};

export default useYupResolver;

import { Input, Typography, Form } from "antd";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { LayoutDetailsFormProps, LayoutDetailsForm } from "@/components";
import { BenefitType } from "@/types/Api";

interface Props extends LayoutDetailsFormProps<BenefitType> {}

const BenefitsTypeForm = ({
  form,
  onEdit,
  isEdit,
  onClose,
  onSubmit,
  isSubmitLoading,
  title,
}: Props) => {
  const { t } = useTranslation("dashboard");

  return (
    <LayoutDetailsForm
      form={form}
      onEdit={onEdit}
      isEdit={isEdit}
      onClose={onClose}
      onSubmit={onSubmit}
      isSubmitLoading={isSubmitLoading}
      title={title}
    >
      <Controller
        control={form.control}
        name="en_name"
        render={({ field, fieldState: { error } }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.enName")}
                </Typography.Text>
              }
              validateStatus={error ? "error" : ""}
              help={error?.message}
            >
              <Input
                {...field}
                disabled={!isEdit}
                value={field.value ?? ""}
                placeholder={t("menu.editHere")}
              />
            </Form.Item>
          );
        }}
      />
      <Controller
        control={form.control}
        name="name"
        render={({ field, fieldState: { error } }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.vnName")}
                </Typography.Text>
              }
              validateStatus={error ? "error" : ""}
              help={error?.message}
            >
              <Input
                {...field}
                disabled={!isEdit}
                value={field.value}
                placeholder={t("menu.editHere")}
              />
            </Form.Item>
          );
        }}
      />
    </LayoutDetailsForm>
  );
};

export default BenefitsTypeForm;

import { TFunction } from "i18next";
import * as yup from "yup";

import { validateThumbnail } from "@/lib/utils";
import { Benefit } from "@/types/Api";

export interface BenefitUpdate extends Omit<Benefit, "type"> {
  type: number;
}

export const benefitSchema = (t: TFunction) =>
  yup.object().shape({
    title: yup.string().required(
      t("menu.validation.required", {
        what: t("menu.title", { ns: "dashboard" }),
      }),
    ),
    content: yup
      .string()
      .nullable()
      .max(
        500000,
        t("menu.validation.tooLarge", {
          what: t("menu.content", { ns: "dashboard" }),
        }),
      ),
    en_content: yup
      .string()
      .nullable()
      .max(
        500000,
        t("menu.validation.tooLarge", {
          what: t("menu.enContent", { ns: "dashboard" }),
        }),
      ),
    en_eligibility: yup.string().nullable(),
    eligibility: yup.string().nullable(),
    en_how_to_redeem: yup.string().nullable(),
    how_to_redeem: yup.string().nullable(),
    thumbnail: yup
      .string()
      .required(
        t("menu.validation.required", {
          what: t("menu.thumbnail", { ns: "dashboard" }),
        }),
      )
      .test(
        "is-file-or-uri",
        t("menu.validation.thumbnailValid", { ns: "dashboard" }),
        validateThumbnail,
      ),
    type: yup.number().required(
      t("menu.validation.required", {
        what: t("menu.benefits.type.types", { ns: "dashboard" }),
      }),
    ),
    end_date: yup.string().required(
      t("menu.validation.required", {
        what: t("menu.date", { ns: "dashboard" }),
      }),
    ),
    start_date: yup.string().required(
      t("menu.validation.required", {
        what: t("menu.date", { ns: "dashboard" }),
      }),
    ),
  });

export const benefitTypeSchema = (t: TFunction) =>
  yup.object().shape(
    {
      name: yup.string().when("en_name", {
        is: (value: string) => value,
        then: (schema) => schema.nullable(),
        otherwise: (schema) =>
          schema.required(
            t("menu.validation.required", {
              what: t("menu.vnName", { ns: "dashboard" }),
            }),
          ),
      }),
      en_name: yup.string().when("name", {
        is: (value: string) => value,
        then: (schema) => schema.nullable(),
        otherwise: (schema) =>
          schema.required(
            t("menu.validation.required", {
              what: t("menu.enName", { ns: "dashboard" }),
            }),
          ),
      }),
    },
    [["name", "en_name"]],
  );

import { Checkbox, Form, Input, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { WebEventServicePayload, Event } from "@/types/Api";

interface Props {
  note?: string | null;
  hasDefaultValue?: boolean;
  isEditMode: boolean;
  isRequestNotNew?: boolean;
  requestFacility?: WebEventServicePayload & Event["service"];
}

interface FormNoteProps {
  shouldShowNote: boolean;
  value?: string | null;
  hasDefaultValue?: boolean;
  isEditMode: boolean;
  name: string | string[];
  title: string;
}

const FormNote = ({
  shouldShowNote,
  value,
  hasDefaultValue,
  name,
  isEditMode,
  title,
}: FormNoteProps) => {
  const { t } = useTranslation("dashboard");

  return (
    <div className="flex items-center">
      <Typography className="font-medium whitespace-nowrap">{title}</Typography>
      {shouldShowNote && (
        <>
          <Typography className="font-medium whitespace-nowrap mx-2">
            - {t("menu.note")}
          </Typography>
          <Form.Item
            name={name}
            initialValue={value}
            className="mb-0 min-w-[320px]"
          >
            <Input
              className={hasDefaultValue ? "!text-gray-800" : ""}
              disabled={!isEditMode}
              placeholder={t("menu.editHere")}
            />
          </Form.Item>
        </>
      )}
    </div>
  );
};

const ServiceNote = ({
  hasDefaultValue,
  isEditMode,
  isRequestNotNew,
  requestFacility,
}: Props) => {
  const { t } = useTranslation("dashboard");
  const security = Form.useWatch([
    "service",
    "security_service",
  ]) as unknown as boolean;
  const av = Form.useWatch(["service", "av_service"]) as unknown as boolean;
  const setup = Form.useWatch([
    "service",
    "setup_service",
  ]) as unknown as boolean;
  const technical_service = Form.useWatch([
    "service",
    "technical_service",
  ]) as unknown as boolean;

  const showSecurityNote = !!security;
  const showAvNote = !!av;
  const showSetupNote = !!setup;
  const showTechnicalServiceNote = !!technical_service;

  return (
    <>
      <Typography className="font-medium whitespace-nowrap col-span-2 mt-8">
        {t("menu.facilities.services")}
      </Typography>
      <div className="col-span-1 mt-4">
        <Form.Item
          name={["service", "security_service"]}
          valuePropName="checked"
          initialValue={requestFacility?.security_service}
        >
          <Checkbox
            className="!text-gray-800  w-full"
            disabled={!isEditMode || isRequestNotNew}
          >
            <FormNote
              title={t("menu.facilities.security")}
              isEditMode={isEditMode}
              hasDefaultValue={hasDefaultValue}
              value={requestFacility?.security_service_note}
              shouldShowNote={showSecurityNote}
              name={["service", "security_service_note"]}
            />
          </Checkbox>
        </Form.Item>

        <Form.Item
          name={["service", "av_service"]}
          valuePropName="checked"
          initialValue={requestFacility?.av_service}
        >
          <Checkbox
            className="!text-gray-800  w-full"
            disabled={!isEditMode || isRequestNotNew}
          >
            <FormNote
              title={t("menu.facilities.av")}
              isEditMode={isEditMode}
              hasDefaultValue={hasDefaultValue}
              value={requestFacility?.av_service_note}
              shouldShowNote={showAvNote}
              name={["service", "av_service_note"]}
            />
          </Checkbox>
        </Form.Item>

        <Form.Item
          name={["service", "setup_service"]}
          valuePropName="checked"
          initialValue={requestFacility?.setup_service}
        >
          <Checkbox
            className="!text-gray-800  w-full"
            disabled={!isEditMode || isRequestNotNew}
          >
            <FormNote
              title={t("menu.facilities.settingUp")}
              isEditMode={isEditMode}
              hasDefaultValue={hasDefaultValue}
              value={requestFacility?.setup_service_note}
              shouldShowNote={showSetupNote}
              name={["service", "setup_service_note"]}
            />
          </Checkbox>
        </Form.Item>

        <Form.Item
          name={["service", "technical_service"]}
          valuePropName="checked"
          initialValue={requestFacility?.technical_service}
        >
          <Checkbox
            className="!text-gray-800  w-full"
            disabled={!isEditMode || isRequestNotNew}
          >
            <FormNote
              title={t("menu.facilities.technical")}
              isEditMode={isEditMode}
              hasDefaultValue={hasDefaultValue}
              value={requestFacility?.technical_service_note}
              shouldShowNote={showTechnicalServiceNote}
              name={["service", "technical_service_note"]}
            />
          </Checkbox>
        </Form.Item>
      </div>
    </>
  );
};

export default ServiceNote;

import { notification } from "antd";
import { useAtomValue, useSetAtom } from "jotai";
import { useForm, UseFormReturn, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useAppMutation } from "@/hooks/useAppMutation";
import useYupResolver from "@/hooks/useYupResolver";
import { queryClient } from "@/lib/config/queryClient";
import { fetchApiClient } from "@/lib/utils/fetchUtils";
import { NotificationFormType, NotiRecurrenceForm } from "@/types";
import {
  CreateNotificationTemplateRequest,
  NotificationType,
  UpdateNotificationTemplateRequest,
} from "@/types/Api";

import { useAllTargetCustomer } from "../../customers/-data/hooks";
import { useAllEvents } from "../../events/-data/hooks";
import { LOCALIZED_WEEKDAYS, RECURRENCE, WEEKDAYS } from "./";
import {
  notificationSchema,
  notiRecurrenceFormSchema,
  notiTypesSchema,
} from "./index";
import { notificationAtoms, notificationQueries } from "./store";

export const useListNotifications = () => {
  return useAtomValue(notificationAtoms.list);
};

export const useListNotificationFilter = () => {
  const filter = useAtomValue(notificationAtoms.filter.currentValueAtom);
  const setFilter = useSetAtom(notificationAtoms.filter.debouncedValueAtom);
  return { filter, setFilter };
};

export const useListNotiTypes = () => {
  return useAtomValue(notificationAtoms.listNotiTypes);
};

export const useAllNotiTypes = () => {
  return useAtomValue(notificationAtoms.allNotiTypes);
};

export const useListNotiTypesFilter = () => {
  const setFilter = useSetAtom(
    notificationAtoms.notiTypeFilter.debouncedValueAtom,
  );
  const filter = useAtomValue(
    notificationAtoms.notiTypeFilter.currentValueAtom,
  );
  return {
    filter,
    setFilter,
  };
};

export const useNotificationDetails = (id: string | number) => {
  return useAtomValue(notificationAtoms.details(id));
};

const useNotificationStatistic = (id: string | number) => {
  return useAtomValue(notificationAtoms.statistic(id));
};

export const useNotificationResolver = () =>
  useYupResolver<NotificationFormType>(notificationSchema);

export const useNotiFormDetail = (id: string) => {
  const { data: notificationDetails } = useNotificationDetails(id);
  const { resolver } = useNotificationResolver();
  const form = useForm<NotificationFormType>({
    defaultValues: {
      ...notificationDetails,
      type: notificationDetails?.type.id,
      related_event: notificationDetails?.related_event?.id,
      target_customer: notificationDetails?.target_customer?.id,
      created_by: notificationDetails?.created_by?.id,
    },
    resolver,
  });
  const { data: targetCustomerList } = useAllTargetCustomer();
  const { data: events } = useAllEvents();
  const { data: notiTypes } = useAllNotiTypes();
  const { data: statistic } = useNotificationStatistic(id);
  return {
    form,
    targetCustomerList,
    events,
    notiTypes,
    statistic,
    notificationDetails,
  };
};

export const useNotiTypeDetails = (id: string | number) => {
  return useAtomValue(notificationAtoms.notiTypeDetails(id));
};

export const useUpdateNotificationDetails = () => {
  return useAppMutation({
    mutationFn: async (
      data: UpdateNotificationTemplateRequest & {
        id: string;
        created_by?: number;
        type?: number;
      },
    ) => {
      try {
        await fetchApiClient.notificationTemplates.updateANotificationTemplate(
          data.id,
          data,
        );
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
    onSuccess: (_, { id }) => {
      queryClient.removeQueries({
        queryKey: notificationQueries.details(id).queryKey,
      });
      notificationAtoms.details.remove(id);
    },
  });
};

export const useCreateNotification = () => {
  return useAppMutation({
    mutationFn: async (
      data: CreateNotificationTemplateRequest & {
        created_by?: number;
      },
    ) => {
      try {
        await fetchApiClient.notificationTemplates.createNewNotificationTemplate(
          data,
        );
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
  });
};

export const useCreateNotiTypes = () => {
  return useAppMutation({
    mutationFn: async (data: NotificationType & { icon: string | null }) => {
      try {
        if (!data.icon) {
          throw new Error("Something went wrong!");
        }
        await fetchApiClient.notificationTypes.createANotificationTypes(data);
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
  });
};

export const useUpdateNotiTypes = () => {
  return useAppMutation({
    mutationFn: async (data: NotificationType) => {
      try {
        await fetchApiClient.notificationTypes.updateANotificationType(
          data.id?.toString() ?? "",
          data,
        );
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
    onSuccess: (_, { id }) => {
      queryClient.removeQueries({
        queryKey: notificationQueries.notiTypesDetails(id?.toString() ?? "")
          .queryKey,
      });
      notificationAtoms.notiTypeDetails.remove(id?.toString() ?? "");
    },
  });
};

export const usePushNotification = () => {
  const { t } = useTranslation("dashboard");
  return useAppMutation({
    mutationFn: async (id: string) => {
      try {
        await fetchApiClient.notificationTemplates.pushNotificationTemplate(id);
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
    onSuccess: () => {
      notification.success({
        message: t("menu.success"),
        description: t("menu.notification.pushNotiSuccess"),
      });
    },
    onError: () => {
      notification.error({
        message: t("menu.error"),
        description: t("menu.notification.pushNotiError"),
      });
    },
  });
};

export const useNotiTypesForm = (id: string | number) => {
  const { data } = useNotiTypeDetails(id);
  const { resolver } = useYupResolver<NotificationType>(notiTypesSchema);
  const form = useForm<NotificationType>({
    defaultValues: data,
    resolver,
  });
  return { form, data };
};

export const useNotiRecurrenceForm = ({
  defaultValues,
  notiForm,
}: {
  notiForm: UseFormReturn<NotificationFormType>;
  defaultValues: NotiRecurrenceForm;
}) => {
  const { t, i18n } = useTranslation("dashboard");
  const isVi = i18n.language === "vi";
  const { resolver } = useYupResolver<NotiRecurrenceForm>(
    notiRecurrenceFormSchema,
  );
  const recurrenceForm = useForm<NotiRecurrenceForm>({
    defaultValues,
    resolver,
    mode: "all",
    reValidateMode: "onChange",
  });
  const { weekdays, start, end } = useWatch({
    control: recurrenceForm.control,
  });

  const isFormError =
    Object.entries(recurrenceForm.formState.errors).length > 0;

  const localize = (day: keyof typeof WEEKDAYS) => {
    return LOCALIZED_WEEKDAYS[isVi ? "vi" : "en"][day];
  };

  const getRecurrenceText = () => {
    if (isFormError || !weekdays || weekdays.length === 0 || !start || !end) {
      return "";
    }
    const day = weekdays.map(localize).join(", ");
    return t("menu.notification.recurrence.occursEvery", {
      start: start.toString(),
      end: end.toString(),
      day,
    });
  };

  const recurrenceText = getRecurrenceText();

  const options: { label: string; value: keyof typeof RECURRENCE }[] = [
    {
      label: t("menu.notification.recurrence.never"),
      value: "NEVER",
    },
    {
      label:
        notiForm.watch("recurrence") !== "NEVER" && recurrenceText.length > 0
          ? recurrenceText
          : t("menu.notification.recurrence.custom"),
      value: "WEEKLY",
    },
  ];

  return {
    options,
    recurrenceText,
    recurrenceForm,
  };
};

import { keepPreviousData, queryOptions } from "@tanstack/react-query";
import { atom } from "jotai";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";
import { atomFamily } from "jotai/vanilla/utils";

import atomWithDebounce from "@/jotai-atoms/atomWithDebounce";
import { fetchApiClient } from "@/lib/utils/fetchUtils";
import { PaginateFilterState } from "@/types";
import { Event } from "@/types/Api";

type EventListQuery = Parameters<
  typeof fetchApiClient.events.getListEventsWithPagination
>[0];

interface GroupEventListQuery extends PaginateFilterState {
  // Add more filter here
}
interface GroupEventMediaQuery {
  /** A page number within the paginated result set. */
  page?: number;
  /** @minLength 1 */
  search?: string;
  group_event?: number;
}

type EventSpeakerQuery = Parameters<
  typeof fetchApiClient.speakers.getListSpeakers
>[0];

export type EventRegistrationQuery = Parameters<
  typeof fetchApiClient.events.listEventRegistrationsWithPagination
>[1] & { id: string };

export type EventTypesQuery = Parameters<
  typeof fetchApiClient.events.getListEventTypesWithPagination
>[0];

export const eventQueries = {
  all: () =>
    queryOptions({
      queryKey: ["event-list", "all"],
      queryFn: async () => {
        const response =
          await fetchApiClient.events.allEventsMinimalDataForDropList();
        return response.data;
      },
    }),
  list: (params: EventListQuery) =>
    queryOptions({
      queryKey: ["event-list", params],
      queryFn: async () => {
        const response =
          await fetchApiClient.events.getListEventsWithPagination(params);
        return response.data;
      },
      placeholderData: keepPreviousData,
    }),
  listSpeakers: (props: EventSpeakerQuery) =>
    queryOptions({
      queryKey: ["event-speakers-list", props],
      queryFn: async () => {
        try {
          const response = await fetchApiClient.speakers.getListSpeakers(props);
          return response.data;
        } catch (error) {
          throw new Error("Something went wrong!");
        }
      },
      placeholderData: keepPreviousData,
    }),
  speakerDetails: (id: string) => {
    return queryOptions({
      queryKey: ["event-speakers-detail", id],
      queryFn: async () => {
        try {
          const response = await fetchApiClient.speakers.getDetailSpeaker(+id);
          return response.data;
        } catch (error) {
          throw new Error("Something went wrong!");
        }
      },
    });
  },
  details: (id: number) =>
    queryOptions({
      queryKey: ["event-detail", Number(id)],
      queryFn: async () => {
        try {
          const { data } = await fetchApiClient.events.getDetailEvent(id);
          return data;
        } catch (error) {
          throw new Error("Something went wrong!");
        }
      },
    }),
  group: (params: GroupEventListQuery) =>
    queryOptions({
      queryKey: ["event-group-list", params],
      queryFn: async () => {
        const response =
          await fetchApiClient.groupEvents.getListGroupEventsWithPagination(
            params,
          );
        return response.data;
      },
    }),
  groupValid: () =>
    queryOptions({
      queryKey: ["event-group-valid-list"],
      queryFn: async () => {
        const response = await fetchApiClient.groupEvents.getValidEvents();
        return response.data;
      },
    }),
  groupDetails: (id: number) =>
    queryOptions({
      queryKey: ["event-group-details", Number(id)],
      queryFn: async () => {
        const response =
          await fetchApiClient.groupEvents.getDetailGroupEvent(id);
        return response.data;
      },
    }),
  groupMediaFilter: (params: GroupEventMediaQuery) =>
    queryOptions({
      queryKey: ["event-group-media-list", params],
      queryFn: async () => {
        const response =
          await fetchApiClient.groupEventMedia.getListGroupEventMediaWithPagination(
            params,
          );
        return response.data;
      },
    }),
  groupEventMediaDetail: (id: string) =>
    queryOptions({
      queryKey: ["event-group-media-detail", Number(id)],
      queryFn: async () => {
        const response =
          await fetchApiClient.groupEventMedia.getDetailGroupEventMedia(
            Number(id),
          );
        return response.data;
      },
    }),
  registrations: ({ id, ...params }: EventRegistrationQuery) => {
    return queryOptions({
      queryKey: ["event-list-registration", { params, id }],
      queryFn: async () => {
        const response =
          await fetchApiClient.events.listEventRegistrationsWithPagination(
            +id,
            params,
          );
        return response.data;
      },
      placeholderData: keepPreviousData,
    });
  },
  types: (props: EventTypesQuery) =>
    queryOptions({
      queryKey: ["event-types", props],
      queryFn: async () => {
        try {
          const response =
            await fetchApiClient.events.getListEventTypesWithPagination(props);
          return response.data;
        } catch (error) {
          throw new Error("Something went wrong");
        }
      },
      placeholderData: keepPreviousData,
    }),
  typesDetails: (id: string) =>
    queryOptions({
      queryKey: ["event-types-details", id],
      queryFn: async () => {
        try {
          const response = await fetchApiClient.events.getDetailEventType(+id);
          return response.data;
        } catch (error) {
          throw new Error("Something went wrong");
        }
      },
    }),
};

const eventListFilterAtom = atomWithDebounce<EventListQuery>({
  search: "",
  status: undefined,
  page: 1,
  published: undefined,
  source: undefined,
});

const eventSpeakerFilterAtom = atomWithDebounce<EventSpeakerQuery>({
  page: 1,
  search: "",
});

const groupEventListFilterAtom = atomWithDebounce<GroupEventListQuery>({
  search: "",
  page: 1,
});

const groupEventListMediaFilterAtom = atomWithDebounce<GroupEventMediaQuery>({
  search: "",
  page: 1,
});

const registrationFilterAtom = atomWithDebounce<
  Omit<EventRegistrationQuery, "id">
>({
  search: "",
  page: 1,
});

const eventTypesFilterAtom = atomWithDebounce<EventTypesQuery>({
  page: 1,
  search: "",
});

export const eventAtoms = {
  all: atomWithSuspenseQuery(() => eventQueries.all()),
  filter: eventListFilterAtom,
  list: atomWithSuspenseQuery((get) =>
    eventQueries.list(get(eventListFilterAtom.debouncedValueAtom)),
  ),
  details: atomFamily((id: number) =>
    atomWithSuspenseQuery(() => eventQueries.details(id)),
  ),
  // Types
  typesFilter: eventTypesFilterAtom,
  listTypes: atomWithSuspenseQuery((get) =>
    eventQueries.types(get(eventTypesFilterAtom.debouncedValueAtom)),
  ),
  typesDetails: atomFamily((id: string) =>
    atomWithSuspenseQuery(() => eventQueries.typesDetails(id)),
  ),
  //Speakers
  speakerFilter: eventSpeakerFilterAtom,
  listSpeakers: atomWithSuspenseQuery((get) =>
    eventQueries.listSpeakers(get(eventSpeakerFilterAtom.debouncedValueAtom)),
  ),
  speakerDetails: atomFamily((id: string) =>
    atomWithSuspenseQuery(() => eventQueries.speakerDetails(id)),
  ),
  // Registration
  registrationFilter: registrationFilterAtom,
  registration: atomFamily((id: string) => {
    return atomWithSuspenseQuery((get) =>
      eventQueries.registrations({
        ...get(registrationFilterAtom.debouncedValueAtom),
        id,
      }),
    );
  }),
  // Event Group
  groupFilter: groupEventListFilterAtom,
  groupValid: atomWithSuspenseQuery(() => eventQueries.groupValid()),
  group: atomWithSuspenseQuery((get) =>
    eventQueries.group(get(groupEventListFilterAtom.debouncedValueAtom)),
  ),
  groupDetails: atomFamily((id: number) =>
    atomWithSuspenseQuery(() => eventQueries.groupDetails(id)),
  ),
  groupEventIds: atom<number[]>([]),
  groupMedia: atomFamily((id: number | string) =>
    atomWithSuspenseQuery((get) =>
      eventQueries.groupMediaFilter({
        ...get(groupEventListMediaFilterAtom.debouncedValueAtom),
        group_event: Number(id),
      }),
    ),
  ),
  groupMediaDetail: atomFamily((id: string) =>
    atomWithSuspenseQuery(() => eventQueries.groupEventMediaDetail(id)),
  ),
};

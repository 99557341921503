import { Card, Form, Input, Select, Typography } from "antd";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { LayoutDetailsForm, LayoutDetailsFormProps } from "@/components";
import { filterSelectOptions } from "@/lib/utils";
import {
  CreateTargetCustomerRequest,
  TargetCustomerCohorts,
  TargetCustomerColleges,
  UpdateTargetCustomerRequest,
  UserBasicInfo,
} from "@/types/Api";

import { TARGET_CUSTOMER_ROLES } from "../-data";

interface Props
  extends LayoutDetailsFormProps<
    CreateTargetCustomerRequest & UpdateTargetCustomerRequest
  > {
  colleges: TargetCustomerColleges[];
  cohorts: TargetCustomerCohorts[];
  created_by: string;
  users: UserBasicInfo[];
}

const TargetCustomerForm = ({
  form,
  isEdit,
  colleges,
  created_by,
  cohorts,
  users,
  ...props
}: Props) => {
  const { t } = useTranslation("dashboard");

  return (
    <LayoutDetailsForm form={form} isEdit={isEdit} {...props}>
      <Controller
        control={form.control}
        name="name"
        render={({ field, fieldState: { error } }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.name")}
                </Typography.Text>
              }
              help={error?.message}
              validateStatus={error ? "error" : ""}
              className="col-span-2"
              required
            >
              <Input
                {...field}
                disabled={!isEdit}
                value={field.value}
                placeholder={t("menu.editHere")}
              />
            </Form.Item>
          );
        }}
      />

      <Form.Item
        label={
          <Typography.Text className="font-medium">
            {t("menu.criterias")}
          </Typography.Text>
        }
        className="col-span-2"
        required
      >
        <Card bodyStyle={{ paddingBlock: "16px", paddingBottom: 0 }}>
          <Controller
            control={form.control}
            name="roles"
            render={({ field, fieldState: { error } }) => {
              return (
                <Form.Item
                  label={
                    <Typography.Text className="font-medium">
                      {t("menu.roles")}
                    </Typography.Text>
                  }
                  help={error?.message}
                  validateStatus={error ? "error" : ""}
                >
                  <Select
                    {...field}
                    onChange={(e) => {
                      field.onChange([e]);
                    }}
                    onClear={() => {
                      form.reset((values) => {
                        return {
                          ...values,
                          roles: null,
                        };
                      });
                    }}
                    value={field.value?.[0]}
                    showArrow
                    showSearch
                    allowClear
                    maxTagTextLength={24}
                    options={TARGET_CUSTOMER_ROLES}
                    filterOption={filterSelectOptions}
                    disabled={!isEdit}
                    placeholder={t("menu.select", { what: t("menu.roles") })}
                  />
                </Form.Item>
              );
            }}
          />
          <Controller
            control={form.control}
            name="cohort"
            render={({ field, fieldState: { error } }) => {
              return (
                <Form.Item
                  label={
                    <Typography.Text className="font-medium">
                      {t("menu.cohort")}
                    </Typography.Text>
                  }
                  className="col-span-2"
                  validateStatus={error ? "error" : ""}
                  help={error?.message}
                >
                  <Select
                    {...field}
                    onClear={() => {
                      form.reset((values) => {
                        return {
                          ...values,
                          cohort: null,
                        };
                      });
                    }}
                    showArrow
                    showSearch
                    allowClear
                    maxTagTextLength={24}
                    options={cohorts.map(({ label }) => {
                      return {
                        label,
                        value: label,
                      };
                    })}
                    filterOption={filterSelectOptions}
                    placeholder={t("menu.select", { what: t("menu.cohort") })}
                    disabled={!isEdit}
                  />
                </Form.Item>
              );
            }}
          />
          <Controller
            control={form.control}
            name="colleges"
            render={({ field, fieldState: { error } }) => {
              return (
                <Form.Item
                  label={
                    <Typography.Text className="font-medium">
                      {t("menu.college")}
                    </Typography.Text>
                  }
                  className="col-span-2"
                  validateStatus={error ? "error" : ""}
                  help={error?.message}
                >
                  <Select
                    {...field}
                    mode="multiple"
                    onClear={() => {
                      form.reset((values) => {
                        return {
                          ...values,
                          college: null,
                        };
                      });
                    }}
                    showArrow
                    showSearch
                    allowClear
                    maxTagTextLength={24}
                    options={colleges.map(({ label }) => {
                      return {
                        label,
                        value: label,
                      };
                    })}
                    filterOption={filterSelectOptions}
                    placeholder={t("menu.select", { what: t("menu.college") })}
                    disabled={!isEdit}
                  />
                </Form.Item>
              );
            }}
          />

          <Controller
            control={form.control}
            name="users"
            render={({ field, fieldState: { error } }) => {
              return (
                <Form.Item
                  label={
                    <Typography.Text className="font-medium">
                      {t("menu.targetCustomer.users")}
                    </Typography.Text>
                  }
                  className="col-span-2"
                  validateStatus={error ? "error" : ""}
                  help={error?.message}
                >
                  <Select
                    {...field}
                    mode="multiple"
                    showArrow
                    showSearch
                    maxTagTextLength={24}
                    options={users.map((item) => {
                      return {
                        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                        label: item.email || item.username,
                        value: item.id,
                      };
                    })}
                    filterOption={filterSelectOptions}
                    placeholder={t("menu.select", {
                      what: t("menu.targetCustomer.userEmail"),
                    })}
                    disabled={!isEdit}
                    value={field.value}
                  />
                </Form.Item>
              );
            }}
          />
        </Card>
      </Form.Item>

      <Form.Item
        label={
          <Typography.Text className="font-medium">
            {t("menu.notification.createdBy")}
          </Typography.Text>
        }
        className="col-span-2"
      >
        <Input disabled value={created_by} />
      </Form.Item>
    </LayoutDetailsForm>
  );
};

export default TargetCustomerForm;

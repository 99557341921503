import { createFileRoute, redirect } from "@tanstack/react-router";

import { ErrorResetAlert, PendingComponent } from "@/components";
import { userQueries } from "@/jotai-atoms/user";
import { checkUserRole } from "@/lib/utils";

import { getSupportedRoutePath, uniqueBy } from "../-data";

export const Route = createFileRoute("/_auth/_dashboard")({
  loader: (props) => {
    const {
      context: { queryClient },
    } = props;
    const data = queryClient.getQueryData(userQueries.userInfo().queryKey);
    const isRole = checkUserRole(data?.user.groups ?? []);
    const supportedRoute = [];
    if (!isRole("ADMIN")) {
      // if not admin check support route for any role!
      if (isRole("STAFF")) {
        supportedRoute.push(...getSupportedRoutePath("STAFF"));
      }
      if (isRole("SAM")) {
        supportedRoute.push(...getSupportedRoutePath("SAM"));
      }
      if (isRole("OP")) {
        supportedRoute.push(...getSupportedRoutePath("OP"));
      }
      const routes = uniqueBy(supportedRoute, (item) => item);
      const isNoRouteSupport = routes.every(
        (route) => !location.pathname.includes(route),
      );
      // if user go to a route that role not supported redirect to root route, root route will care the rest!
      if (isNoRouteSupport) {
        return redirect({ to: "/" });
      }
    }
  },
  pendingMinMs: 0,
  pendingMs: 0,
  pendingComponent: () => <PendingComponent />,
  errorComponent: (props) => <ErrorResetAlert {...props} />,
});

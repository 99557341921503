import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useNavigate } from "@tanstack/react-router";
import {
  Form,
  Input,
  Select,
  Checkbox,
  Upload,
  Button,
  Card,
  UploadFile,
  Divider,
  Typography,
  UploadProps,
  Modal,
  message,
  Menu,
  notification,
} from "antd";
import { RuleObject } from "antd/lib/form";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import dayjs, { Dayjs } from "dayjs";
import { ReactNode, useEffect, useState } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { DatePicker, Editor, TimePicker } from "@/components";
import RatingForm from "@/components/RatingForm";
import RequestFacilitiesForm from "@/components/RequestFacilitiesForm";
import { useImage, useResizeImage } from "@/hooks";
import {
  useAllFacilities,
  useCreateEventService,
  useCreateEventServiceRating,
  useUpdateEventService,
} from "@/jotai-atoms/event-service";
import { useUserInfo } from "@/jotai-atoms/user";
import { queryClient } from "@/lib/config/queryClient";
import { DATE_HOUR_MINUTES, SUPPORTED_IMAGE_TYPES } from "@/lib/constants";
import {
  checkUserRole,
  cn,
  filterSelectOptions,
  getMinSetupTimeBeforeEvent,
  transformHtmlContent,
} from "@/lib/utils";
import {
  Event,
  EventList,
  EventType,
  EventUpdate,
  SpeakerList,
  TargetCustomerColleges,
  UserBasicInfo,
  WebEventService,
  WebEventServicePayload,
  WebServiceRatingPayload,
} from "@/types/Api";

const { Title } = Typography;
const { confirm } = Modal;
const { TextArea } = Input;
export interface IEventUpdate extends Omit<EventUpdate, "agendas"> {
  agendas?: EventList["agendas"];
  end_at?: string;
  read_only?: boolean;
  service?: WebEventServicePayload & WebEventService;
}
export interface LayoutDetailPageProps<TFormData extends FieldValues> {
  children?: ReactNode;
  title?: string;
  form: UseFormReturn<TFormData>;
  // onSubmitValid: (data: TFormData) => void;
  className?: string;
  // handleConfirmDelete?: () => void;
  // shouldRenderDeleteButton?: boolean;
  submitAction: {
    onSubmit: (data: TFormData) => void;
    isLoading: boolean;
  };
  deleteAction?: {
    onDelete: () => void;
    isLoading: boolean;
  };
}
interface EventForm extends LayoutDetailPageProps<IEventUpdate> {
  type?: "view" | "create";
  createByEmail: string;
  eventSpeakers: SpeakerList[];
  eventTypes: EventType[];
  colleges: TargetCustomerColleges[];
  eventDetail?: Event;
}

const EventForm = ({
  form,
  type,
  submitAction,
  eventSpeakers,
  eventTypes,
  title,
  colleges,
  eventDetail,
}: EventForm) => {
  const [menuKeys, setMenuKeys] = useState<"title" | "request" | "rating">(
    "title",
  );
  const user = useUserInfo();
  const isRole = checkUserRole(user.data?.user.groups ?? []);
  const isSAM = isRole("SAM");
  const isAdmin = isRole("ADMIN");
  const { mutate: updateEventService } = useUpdateEventService();
  const { mutate: createEventService } = useCreateEventService();
  const { mutate: createEventServiceRating } = useCreateEventServiceRating();

  const navigate = useNavigate();
  const defaultValues = form.formState.defaultValues;
  const [isEditMode, setEditMode] = useState(type === "create");
  const optionsSpeakers = eventSpeakers.map((speaker) => ({
    label: speaker.name,
    value: speaker.id,
  }));
  const optionsEventTypes = eventTypes.map((eventType) => ({
    label: eventType.en_name ?? "",
    value: eventType.id,
  }));
  const { data: facilities } = useAllFacilities();
  const { t } = useTranslation("dashboard");

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  useEffect(() => {
    const hasThumailDefault = defaultValues?.thumbnail;
    if (defaultValues?.external_id && defaultValues.external_thumbnail_url) {
      setFileList([
        {
          uid: "-1",
          name: defaultValues.external_thumbnail_url,
          status: "done",
          url: defaultValues.external_thumbnail_url,
          thumbUrl: defaultValues.external_thumbnail_url,
        },
      ]);
    } else if (hasThumailDefault) {
      setFileList([
        {
          uid: "-1",
          name: defaultValues.thumbnail,
          status: "done",
          url: defaultValues.thumbnail,
          thumbUrl: defaultValues.thumbnail,
        },
      ]);
    }
  }, [defaultValues]);
  const { uploadImage } = useImage();
  const { mutate: resizeImage } = useResizeImage();

  const transformedAgendas = (defaultValues?.agendas ?? []).map((agenda) => ({
    ...agenda,
    start_time: dayjs(agenda?.start_time, "HH:mm:ss"),
    end_time: dayjs(agenda?.end_time, "HH:mm:ss"),
  }));

  const beforeUpload = (file: UploadFile) => {
    const isImageType = SUPPORTED_IMAGE_TYPES.includes(file.type ?? "");
    if (!isImageType) {
      void message.error(t("menu.isNotImageFile", { file: file.name }));
    }
    return isImageType || Upload.LIST_IGNORE;
  };
  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    if (newFileList.length === 0) {
      setFileList([]);
      return;
    }

    const newFile = newFileList[newFileList.length - 1].originFileObj as File;

    setFileList([
      {
        uid: "-1",
        percent: 50,
        name: newFile.name,
        status: "uploading",
      },
    ]);

    void uploadImage(
      { file: newFile },
      {
        onSuccess: (thumbnailUrl) => {
          setFileList([
            {
              uid: "-1",
              name: newFile.name,
              status: "done",
              url: thumbnailUrl,
              thumbUrl: thumbnailUrl,
            },
          ]);
          formData.setFieldValue("thumbnail", thumbnailUrl);

          resizeImage({ images: [{ path: thumbnailUrl }] });

          if (warningThumnbnail) {
            setWarningThumnbnail(false);
          }
        },
        onError: (err) => {
          void message.error(
            `${t("menu.errorUploadFile", "")}: ${(err as Error | undefined)?.message ?? ""}`,
          );
          setFileList([]);
        },
      },
    );
  };

  // Handle State
  const [viDescription, setViDescription] = useState("");
  const [enDescription, setEnDescription] = useState("");
  const [contactContent, setContactContent] = useState("");
  const handleViEditorChange = (data: string) => {
    setViDescription(data);
  };
  const handleEnEditorChange = (data: string) => {
    setEnDescription(data);
  };
  const handleContactEditorChange = (data: string) => {
    setContactContent(data);
  };

  //Handle preview thumnail
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [warningThumnbnail, setWarningThumnbnail] = useState(false);

  const handlePreview = () => {
    if (fileList[0].url) {
      setPreviewImage(fileList[0].url);
      setPreviewOpen(true);
    }
  };
  const handleCancel = () => {
    setPreviewOpen(false);
  };

  // Handle submit form
  const isEventHasService = !!eventDetail?.service;
  const username = isEventHasService
    ? (eventDetail.service?.requester.last_name ?? "") +
      (eventDetail.service?.requester.first_name ?? "")
    : (user.data?.user.last_name ?? "") + (user.data?.user.first_name ?? "");

  const hours = getMinSetupTimeBeforeEvent(eventDetail);

  if (import.meta.env.DEV) {
    console.log(
      `time diffing event from ${dayjs().add(hours, "hours").toDate().toLocaleString()} to ${dayjs(eventDetail?.start_at).toDate().toLocaleString()}`,
      dayjs(eventDetail?.start_at).diff(dayjs().add(hours, "hours"), "hours"),
    );
  }

  const shouldShowFacilityRequestForm =
    isEventHasService ||
    dayjs(eventDetail?.start_at).diff(dayjs().add(hours, "hours"), "hours") >=
      hours;

  const onFinish = (values: IEventUpdate) => {
    if (!viTitle && !enTitle) {
      window.scrollTo(0, 0);
      setIsWarmingTitle(true);
      return;
    }
    if (fileList.length > 0 && fileList[0].url) {
      const updatedAgendas = values.agendas?.map((agenda) => {
        const start = dayjs(agenda.start_time);
        const end = dayjs(agenda.end_time);

        const formattedStart = start.format("YYYY-MM-DDTHH:mm:ss");
        const formattedEnd = end.format("YYYY-MM-DDTHH:mm:ss");

        return {
          ...agenda,
          detail: transformHtmlContent(agenda.detail),
          en_detail: transformHtmlContent(agenda.en_detail),
          start_time: formattedStart,
          end_time: formattedEnd,
        };
      });
      const newThumbnail = fileList[0].url.replace(
        /^https?:\/\/[^\\/]+\/files\//,
        "",
      );
      const formatTimePayload = "YYYY-MM-DDTHH:mm";
      const updatedValues = {
        ...values,
        start_at: dayjs(values.start_at).format(formatTimePayload),
        end_at: dayjs(values.end_at).format(formatTimePayload),
        agendas: updatedAgendas,
        id: defaultValues?.id,
        created_by: defaultValues?.created_by ?? 0,
        thumbnail: newThumbnail,
        content: transformHtmlContent(values.content),
        en_content: transformHtmlContent(values.en_content),
        contact: transformHtmlContent(values.contact),
        location: transformHtmlContent(values.location),
      };
      if (menuKeys === "title") {
        if (values.status) {
          confirm({
            title: t("menu.events.cancelEventTitle"),
            icon: <ExclamationCircleOutlined />,
            content: t("menu.events.cancelEventDesc"),
            onOk() {
              submitAction.onSubmit({
                ...updatedValues,
                status: "CANCELLED",
              });
              setEditMode(false);
              return;
            },
            onCancel() {
              return;
            },
          });
        } else {
          submitAction.onSubmit({
            ...updatedValues,
            status: "ACTIVE",
          });
        }
        if (hasDefaultValue) {
          setEditMode(false);
        }
      } else if (menuKeys === "request") {
        const { template = "", layout = "" } =
          updatedValues.service as unknown as {
            template?: string;
            layout?: string;
          };

        const eventService = {
          ...updatedValues.service,
          template,
          layout,
        };
        const facilities = formData.getFieldValue(["service", "facilities"]) as
          | { _facility?: number; facility?: number; quantity: number }[]
          | undefined;
        const mutateFacilities:
          | {
              facility?: number;
              quantity: number;
            }[]
          | undefined = [];
        facilities?.forEach((item, index) => {
          if (item._facility && !item.facility) {
            formData.setFieldValue(
              ["service", "facilities", index, "facility"],
              item._facility,
            );
          }
          mutateFacilities[index] = {
            ...item,
            facility: Number(item.facility ?? item._facility),
          };
        });
        const mutateData = {
          ...eventService,
          facilities: mutateFacilities,
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          setup_at: dayjs(eventService?.setup_at).format(formatTimePayload),
          event: updatedValues.id ?? 0,
          cleanup_at: null as unknown as string,
        };
        if (isEventHasService) {
          updateEventService(
            {
              requestId: eventDetail.service?.id as unknown as number,
              ...mutateData,
            },
            {
              onSuccess: () => {
                notification.success({
                  message: t("menu.success"),
                  description: t("menu.update.success", {
                    what: t("menu.facilities.requestFacility"),
                  }),
                });
                void queryClient.invalidateQueries({
                  queryKey: ["event-detail", +(eventDetail.id ?? 0)],
                });
                setEditMode(false);
              },
              onError: () => {
                notification.error({
                  message: t("menu.error"),
                  description: t("menu.update.error", {
                    what: t("menu.facilities.requestFacility"),
                  }),
                });
              },
            },
          );
        } else {
          createEventService(mutateData, {
            onSuccess: () => {
              notification.success({
                message: t("menu.success"),
                description: t("menu.create.success", {
                  what: t("menu.facilities.requestFacility"),
                }),
              });
              void queryClient.invalidateQueries({
                queryKey: ["event-detail", +(eventDetail?.id ?? 0)],
              });
              setEditMode(false);
            },
            onError: () => {
              notification.error({
                message: t("menu.error"),
                description: t("menu.create.error", {
                  what: t("menu.facilities.requestFacility"),
                }),
              });
            },
          });
        }
      } else {
        createEventServiceRating(
          {
            event_service: defaultValues?.service?.id,
            ...updatedValues.service?.rate,
          } as WebServiceRatingPayload,
          {
            onSuccess: () => {
              notification.success({
                message: t("menu.success"),
                description: t("menu.create.success", {
                  what: t(
                    "menu.facilities.requestFacilityRating",
                  ).toLowerCase(),
                }),
              });
            },
            onError: () => {
              notification.error({
                message: t("menu.error"),
                description: t("menu.create.error", {
                  what: t(
                    "menu.facilities.requestFacilityRating",
                  ).toLowerCase(),
                }),
              });
            },
          },
        );
        if (hasDefaultValue) {
          setEditMode(false);
        }
      }
    } else {
      setWarningThumnbnail(true);
    }
  };
  const [formData] = Form.useForm();

  const validateEndDate = (_: RuleObject, value: Dayjs | undefined) => {
    const startDate = formData.getFieldValue("start_at")
      ? dayjs(formData.getFieldValue("start_at") as string)
      : undefined;
    if (value && startDate && dayjs(value).isBefore(startDate)) {
      return Promise.reject(new Error(t("menu.events.endDateAfterStartDate")));
    }
    return Promise.resolve();
  };

  const hasDefaultValue = type !== "create";

  const [viTitle, setViTitle] = useState(
    hasDefaultValue && defaultValues?.title ? defaultValues.title : undefined,
  );
  const [enTitle, setEnTitle] = useState(
    hasDefaultValue && defaultValues?.en_title
      ? defaultValues.en_title
      : undefined,
  );

  const [isWarningTitle, setIsWarmingTitle] = useState(false);

  const onChangeViTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setViTitle(e.target.value);
  };
  const onChangeEnTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEnTitle(e.target.value);
  };

  const isRate =
    !!eventDetail?.service?.rate ||
    (eventDetail?.service?.rate?.rating ?? 0) >= 1;

  const isRequestNew =
    !eventDetail?.service || eventDetail.service.status === "NEW";

  const shouldShowSaveButton =
    isEditMode &&
    (menuKeys !== "rating" || !isRate) &&
    (menuKeys !== "request" || isRequestNew);

  const hasPermission =
    isAdmin ||
    (isSAM &&
      (
        eventDetail?.created_by as
          | (UserBasicInfo & { role: string })
          | undefined
      )?.role !== "ADMIN");

  const shouldShowEditButton =
    hasPermission &&
    !isEditMode &&
    (menuKeys !== "request" || shouldShowFacilityRequestForm);

  const titleComp = (
    <Title
      level={4}
      className={cn("px-2", menuKeys === "title" ? "!text-brand-primary" : "")}
    >
      {title}
    </Title>
  );

  const isEventCancelled =
    eventDetail?.status === "CANCELLED" || eventDetail?.status === "COMPLETED";

  const menuItems: ItemType[] = [
    {
      label: titleComp,
      key: "title",
    },
  ];

  if (hasDefaultValue) {
    menuItems.push({
      label: (
        <Title
          level={4}
          className={cn(
            "px-2",
            menuKeys === "request" ? "!text-brand-primary" : "",
          )}
        >
          {t("menu.facilities.requestFacilities")}
        </Title>
      ),
      key: "request",
    });
  }

  const shouldShowRatingMenuItem =
    isEventCancelled &&
    shouldShowFacilityRequestForm &&
    !!eventDetail.service?.requester &&
    eventDetail.service.status === "APPROVED";

  if (shouldShowRatingMenuItem) {
    menuItems.push({
      label: (
        <Title
          level={4}
          className={cn(
            "px-2",
            menuKeys === "rating" ? "!text-brand-primary" : "",
          )}
        >
          {t("menu.facilities.rating")}
        </Title>
      ),
      key: "rating",
    });
  }

  const headerComp =
    isSAM || isAdmin ? (
      <Menu
        mode="horizontal"
        className="border-b-0"
        selectedKeys={[menuKeys]}
        onSelect={(value) => {
          setMenuKeys(value.key as typeof menuKeys);
        }}
        items={menuItems}
      />
    ) : (
      titleComp
    );

  return (
    <>
      <style>
        {`
      .ant-picker-input input[disabled] {
        color: black !important;
      }
      .ant-checkbox-disabled + span {
      color: black !important;  
      font-weight: 600;
      }
      .ant-select-disabled.ant-select-multiple .ant-select-selection-item{
      color: black !important;
      }
      .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected:after,
      .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover:after
      {
        border-bottom: 2px solid var(--color-brand-primary)   
      }
    `}
      </style>
      <div className="bg-white">
        <div className="flex justify-between px-6 pb-0 pt-4 ">
          <div className="w-full">{headerComp}</div>
          {shouldShowEditButton && (
            <Button
              type="link"
              className="text-brand"
              icon={<EditOutlined />}
              onClick={() => {
                setEditMode(true);
              }}
            >
              {t("menu.edit")}
            </Button>
          )}
        </div>
        <Divider className="!m-0" />
        <div className="p-6">
          <Form
            form={formData}
            layout="vertical"
            name="normal_login"
            className="grid grid-cols-2 gap-x-4"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <div
              className={cn(
                "grid-cols-2 gap-x-4 flex-1 col-span-2",
                menuKeys === "title" ? "grid" : "hidden",
              )}
            >
              <Form.Item
                name="en_title"
                label={t("menu.enTitle")}
                rules={
                  enTitle
                    ? [
                        {
                          required: true,
                          message: t("menu.validation.required", {
                            what: t("menu.enTitle"),
                          }),
                        },
                      ]
                    : undefined
                }
                initialValue={
                  hasDefaultValue && defaultValues?.en_title
                    ? defaultValues.en_title
                    : undefined
                }
              >
                <Input
                  value={enTitle}
                  onChange={onChangeEnTitle}
                  className={hasDefaultValue ? "!text-gray-800" : ""}
                  disabled={!isEditMode}
                  placeholder={t("menu.editHere")}
                />
              </Form.Item>
              <Form.Item
                name="title"
                label={t("menu.vnTitle")}
                initialValue={
                  hasDefaultValue && defaultValues?.title
                    ? defaultValues.title
                    : undefined
                }
                rules={
                  viTitle
                    ? [
                        {
                          required: true,
                          message: t("menu.validation.required", {
                            what: t("menu.vnTitle"),
                          }),
                        },
                      ]
                    : undefined
                }
              >
                <Input
                  className="!text-gray-800"
                  value={viTitle}
                  onChange={onChangeViTitle}
                  disabled={!isEditMode}
                  placeholder={t("menu.editHere")}
                />
              </Form.Item>
              <div className="col-span-2 -mt-2 mb-4">
                {!viTitle && !enTitle && isWarningTitle && (
                  <span className="text-red-600">
                    {t("menu.events.requireBothTitle")}
                  </span>
                )}
              </div>
              <Form.Item
                label={t("menu.startDate")}
                name="start_at"
                rules={[
                  {
                    required: true,
                    message: t("menu.validation.required", {
                      what: t("menu.startDate"),
                    }),
                  },
                ]}
                initialValue={
                  hasDefaultValue && defaultValues?.start_at
                    ? dayjs(defaultValues.start_at)
                    : undefined
                }
              >
                <DatePicker
                  className="w-full"
                  disabled={!isEditMode}
                  showTime={{
                    showNow: false,
                    defaultValue: dayjs().add(1, "hour"),
                  }}
                  showSecond={false}
                  format={DATE_HOUR_MINUTES}
                  disabledDate={(current) =>
                    current.isBefore(dayjs().endOf("day"))
                  }
                  onChange={(value) => {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    const endAt = formData.getFieldValue("end_at");
                    if (!endAt) {
                      formData.setFieldsValue({ end_at: value }); // Reset end_at when start_at changes
                    }
                  }}
                />
              </Form.Item>

              <Form.Item
                label={t("menu.endDate")}
                name="end_at"
                dependencies={["start_at"]}
                rules={[
                  { validator: validateEndDate },
                  {
                    required: true,
                    message: t("menu.validation.required", {
                      what: t("menu.endDate"),
                    }),
                  },
                ]}
                initialValue={
                  hasDefaultValue && defaultValues?.end_at
                    ? dayjs(defaultValues.end_at)
                    : undefined
                }
              >
                <DatePicker
                  className="w-full"
                  disabled={!isEditMode}
                  showTime
                  disabledDate={(current: Dayjs | null) => {
                    const startAt = formData.getFieldValue("start_at") as
                      | Dayjs
                      | undefined;
                    return !!(
                      current &&
                      startAt &&
                      current.isBefore(dayjs(startAt).startOf("day"))
                    );
                  }}
                  showSecond={false}
                  format={DATE_HOUR_MINUTES}
                  disabledTime={(current: Dayjs | null) => {
                    const startAt = formData.getFieldValue("start_at") as
                      | Dayjs
                      | undefined;
                    if (startAt && current?.isSame(startAt, "day")) {
                      return {
                        disabledHours: () => [
                          ...Array(startAt.hour() + 1).keys(),
                        ],
                        disabledMinutes: () => [
                          ...Array(startAt.minute()).keys(),
                        ],
                      };
                    }
                    return {};
                  }}
                />
              </Form.Item>

              {defaultValues?.external_id && (
                <>
                  <Form.Item
                    name="en_url"
                    label={t("menu.news.enUrl")}
                    initialValue={
                      hasDefaultValue && defaultValues.en_url
                        ? defaultValues.en_url
                        : undefined
                    }
                  >
                    <TextArea
                      className="!text-gray-800"
                      rows={4}
                      disabled={!isEditMode}
                    />
                  </Form.Item>
                  <Form.Item
                    name="url"
                    label={t("menu.news.url")}
                    rules={[
                      {
                        required: true,
                        message: t("menu.validation.required", {
                          what: t("menu.news.url"),
                        }),
                      },
                    ]}
                    initialValue={
                      hasDefaultValue && defaultValues.url
                        ? defaultValues.url
                        : undefined
                    }
                  >
                    <TextArea
                      rows={4}
                      className="!text-gray-800"
                      disabled={!isEditMode}
                    />
                  </Form.Item>
                </>
              )}

              {!defaultValues?.external_id && (
                <>
                  <Form.Item
                    className="col-span-2"
                    name="speakers"
                    label={t("menu.speaker.speaker")}
                    initialValue={
                      hasDefaultValue && defaultValues?.speakers
                        ? defaultValues.speakers
                        : undefined
                    }
                  >
                    <Select
                      mode="multiple"
                      disabled={!isEditMode}
                      placeholder={t("menu.select", {
                        what: t("menu.speaker.speaker").toLowerCase(),
                      })}
                      options={optionsSpeakers}
                      filterOption={filterSelectOptions}
                    />
                  </Form.Item>

                  <Form.Item
                    label={t("menu.enDescription")}
                    name="en_content"
                    rules={
                      enTitle
                        ? [
                            {
                              required: true,
                              message: t("menu.validation.required", {
                                what: t("menu.enDescription"),
                              }),
                            },
                          ]
                        : undefined
                    }
                    initialValue={
                      hasDefaultValue && defaultValues?.en_content
                        ? defaultValues.en_content
                        : undefined
                    }
                  >
                    <Editor
                      initialData={
                        hasDefaultValue && defaultValues?.en_content
                          ? defaultValues.en_content
                          : undefined
                      }
                      onChange={handleEnEditorChange}
                      data={enDescription}
                      disabled={!isEditMode}
                    ></Editor>
                  </Form.Item>
                  <Form.Item
                    label={t("menu.vnDescription")}
                    name="content"
                    rules={
                      viTitle
                        ? [
                            {
                              required: true,
                              message: t("menu.validation.required", {
                                what: t("menu.vnDescription"),
                              }),
                            },
                          ]
                        : undefined
                    }
                    initialValue={
                      hasDefaultValue && defaultValues?.content
                        ? defaultValues.content
                        : undefined
                    }
                  >
                    <Editor
                      initialData={
                        hasDefaultValue && defaultValues?.content
                          ? defaultValues.content
                          : undefined
                      }
                      data={viDescription}
                      onChange={handleViEditorChange}
                      disabled={!isEditMode}
                    ></Editor>
                  </Form.Item>
                  <div className="col-span-2">
                    <Form.Item
                      name="location"
                      label={t("menu.OASIS.location")}
                      rules={[
                        {
                          required: true,
                          message: t("menu.validation.required", {
                            what: t("menu.OASIS.location"),
                          }),
                        },
                      ]}
                      initialValue={
                        hasDefaultValue && defaultValues?.location
                          ? defaultValues.location
                          : undefined
                      }
                    >
                      <TextArea
                        className="!text-gray-800"
                        rows={3}
                        disabled={!isEditMode}
                      />
                      {/*
                    <Editor
                      initialData={
                        hasDefaultValue && defaultValues?.location
                          ? defaultValues.location
                          : undefined
                      }
                      data={locationContent}
                      onChange={handleLocationEditorChange}
                      disabled={!isEditMode}
                    />
                    */}
                    </Form.Item>
                  </div>
                </>
              )}

              {hasDefaultValue && !isEditMode && (
                <div className="col-span-2 flex gap-x-4">
                  <Form.Item
                    name="event_source"
                    label={t("menu.events.source")}
                    initialValue={
                      defaultValues?.external_id
                        ? t("menu.external")
                        : t("menu.internal")
                    }
                    className="flex-1"
                  >
                    <Input className="!text-gray-800" disabled={true} />
                  </Form.Item>
                </div>
              )}

              <Form.Item
                name="event_types"
                label={t("menu.events.eventType")}
                initialValue={
                  hasDefaultValue && defaultValues?.event_types
                    ? defaultValues.event_types
                    : undefined
                }
              >
                <Select
                  mode="multiple"
                  className={hasDefaultValue ? "!text-gray-800" : ""}
                  placeholder={t("menu.select", {
                    what: t("menu.events.eventType").toLowerCase(),
                  })}
                  disabled={!isEditMode}
                  options={optionsEventTypes}
                  filterOption={filterSelectOptions}
                />
              </Form.Item>

              <Form.Item
                name="colleges"
                label={t("menu.college")}
                className="flex-1"
                initialValue={
                  (
                    defaultValues as
                      | (Event & {
                          colleges?: TargetCustomerColleges[];
                        })
                      | undefined
                  )?.colleges
                }
              >
                <Select
                  mode="multiple"
                  placeholder={t("menu.select", {
                    what: t("menu.college").toLowerCase(),
                  })}
                  disabled={!isEditMode}
                  options={colleges.map((item) => ({
                    label: item.label,
                    value: item.label,
                  }))}
                  filterOption={filterSelectOptions}
                />
              </Form.Item>

              <div className="col-span-2 flex gap-x-20">
                <Form.Item
                  name="published"
                  valuePropName="checked"
                  initialValue={defaultValues?.published}
                >
                  <Checkbox className="!text-gray-800" disabled={!isEditMode}>
                    {t("menu.published")}
                  </Checkbox>
                </Form.Item>
                {!defaultValues?.external_id && (
                  <Form.Item
                    name="read_only"
                    valuePropName="checked"
                    initialValue={defaultValues?.read_only}
                  >
                    <Checkbox
                      className={hasDefaultValue ? "!text-gray-800" : ""}
                      disabled={!isEditMode}
                    >
                      {t("menu.events.readOnly")}
                    </Checkbox>
                  </Form.Item>
                )}
                <Form.Item
                  name="is_highlight"
                  valuePropName="checked"
                  initialValue={defaultValues?.is_highlight}
                >
                  <Checkbox
                    className={hasDefaultValue ? "!text-gray-800" : ""}
                    disabled={!isEditMode}
                  >
                    {t("menu.highlight")}
                  </Checkbox>
                </Form.Item>
                {hasDefaultValue && (
                  <Form.Item
                    name="status"
                    valuePropName="checked"
                    initialValue={defaultValues?.status === "CANCELLED"}
                  >
                    <Checkbox className="!text-gray-800" disabled={!isEditMode}>
                      {t("menu.cancel")}
                    </Checkbox>
                  </Form.Item>
                )}
              </div>
              {!defaultValues?.external_id && (
                <Form.Item
                  name="agendas"
                  initialValue={
                    hasDefaultValue ? transformedAgendas : undefined
                  }
                  className="col-span-2 mb-4"
                >
                  <Form.List name="agendas">
                    {(fields, { add, remove }) => (
                      <>
                        <Card
                          title={t("menu.events.withInDayAgendas")}
                          size="small"
                          extra={
                            isEditMode ? (
                              <Button
                                className="border-brand bg-brand"
                                type="primary"
                                onClick={() => {
                                  add();
                                }}
                                icon={<PlusOutlined />}
                              >
                                {t(
                                  "menu.config.mobile.emergencyContact.addField",
                                )}
                              </Button>
                            ) : undefined
                          }
                        >
                          {fields.length < 1 && (
                            <div className="flex justify-center">
                              <img src="/NoData.svg" alt="No Data" />
                            </div>
                          )}
                          {fields.map(({ key, name, ...restField }) => (
                            <>
                              <div
                                key={key}
                                className="grid grid-cols-2 gap-x-6"
                              >
                                <Form.Item
                                  {...restField}
                                  label={t("menu.events.startTime")}
                                  className="w-full"
                                  name={[name, "start_time"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: t("menu.validation.missing", {
                                        what: t("menu.events.startTime"),
                                      }),
                                    },
                                  ]}
                                >
                                  <TimePicker
                                    showNow={false}
                                    disabled={!isEditMode}
                                    className="w-full"
                                  />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  label={t("menu.events.endTime")}
                                  className="w-full"
                                  rules={[
                                    {
                                      required: true,
                                      message: t("menu.validation.missing", {
                                        what: t("menu.events.endTime"),
                                      }),
                                    },
                                  ]}
                                  name={[name, "end_time"]}
                                >
                                  <TimePicker
                                    showNow={false}
                                    disabled={!isEditMode}
                                    className="w-full"
                                  />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  label={t("menu.events.vnSubject")}
                                  className="w-full"
                                  name={[name, "activity"]}
                                  rules={
                                    viTitle
                                      ? [
                                          {
                                            required: true,
                                            message: t(
                                              "menu.validation.missing",
                                              {
                                                what: t(
                                                  "menu.events.vnSubject",
                                                ),
                                              },
                                            ),
                                          },
                                        ]
                                      : undefined
                                  }
                                >
                                  <Input
                                    className={
                                      hasDefaultValue ? "!text-gray-800" : ""
                                    }
                                    disabled={!isEditMode}
                                    placeholder={t("menu.editHere")}
                                  />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  label={t("menu.events.enSubject")}
                                  className="w-full"
                                  name={[name, "en_activity"]}
                                  rules={
                                    enTitle
                                      ? [
                                          {
                                            required: true,
                                            message: t(
                                              "menu.validation.missing",
                                              {
                                                what: t(
                                                  "menu.events.enSubject",
                                                ),
                                              },
                                            ),
                                          },
                                        ]
                                      : undefined
                                  }
                                >
                                  <Input
                                    className={
                                      hasDefaultValue ? "!text-gray-800" : ""
                                    }
                                    disabled={!isEditMode}
                                    placeholder={t("menu.editHere")}
                                  />
                                </Form.Item>

                                <Form.Item
                                  {...restField}
                                  label={t("menu.vnDescription")}
                                  className="w-full"
                                  name={[name, "detail"]}
                                >
                                  <Editor
                                    initialData={
                                      hasDefaultValue
                                        ? transformedAgendas[key]?.detail
                                        : undefined
                                    }
                                    disabled={!isEditMode}
                                  ></Editor>
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  label={t("menu.enDescription")}
                                  className="w-full"
                                  name={[name, "en_detail"]}
                                >
                                  <Editor
                                    initialData={
                                      hasDefaultValue
                                        ? transformedAgendas[key]?.en_detail
                                        : undefined
                                    }
                                    disabled={!isEditMode}
                                  ></Editor>
                                </Form.Item>
                                {isEditMode && (
                                  <div className="col-span-2">
                                    <Button
                                      className="float-right"
                                      icon={<DeleteOutlined />}
                                      onClick={() => {
                                        remove(name);
                                      }}
                                      type="primary"
                                      danger
                                    >
                                      {t("menu.button.delete")}
                                    </Button>
                                  </div>
                                )}
                              </div>
                              {fields.length > 1 && (
                                <Divider className="mb-4" dashed />
                              )}
                            </>
                          ))}
                        </Card>
                      </>
                    )}
                  </Form.List>
                </Form.Item>
              )}

              <div className="col-span-2 w-fit">
                <Form.Item label={t("menu.thumbnail")} required>
                  <Upload
                    listType="picture-card"
                    fileList={fileList}
                    disabled={!isEditMode || !!defaultValues?.external_id}
                    beforeUpload={beforeUpload}
                    onPreview={handlePreview}
                    accept={SUPPORTED_IMAGE_TYPES.join(",")}
                    onChange={handleChange}
                    onRemove={() => {
                      setFileList([]);
                    }}
                  >
                    {isEditMode && !defaultValues?.external_id && (
                      <div>
                        {fileList.length > 0 ? (
                          <>
                            <EditOutlined />
                            <div>{t("menu.edit")}</div>
                          </>
                        ) : (
                          <>
                            <PlusOutlined />
                            <div>{t("menu.upload")}</div>
                          </>
                        )}
                      </div>
                    )}
                  </Upload>
                </Form.Item>
                {fileList.length === 0 && (
                  <div className="-mt-4 mb-4">
                    {!isEditMode ? (
                      <img className="-mt-8" src="/NoThumbnail.svg" alt="" />
                    ) : (
                      <div>
                        {warningThumnbnail && (
                          <span className="text-red-600">
                            {t("menu.validation.required", {
                              what: t("menu.thumbnail"),
                            })}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                )}

                <Modal
                  open={previewOpen}
                  title={t("menu.events.previewThumbnail")}
                  footer={null}
                  onCancel={handleCancel}
                >
                  <img
                    alt="previewImage"
                    style={{ width: "100%" }}
                    src={previewImage}
                  />
                </Modal>
              </div>
              {!defaultValues?.external_id && (
                <div className="col-span-2">
                  <Form.Item
                    name="contact"
                    label={t("menu.events.contact")}
                    rules={[
                      {
                        required: true,
                        message: t("menu.validation.required", {
                          what: t("menu.events.contact"),
                        }),
                      },
                    ]}
                    initialValue={
                      hasDefaultValue && defaultValues?.contact
                        ? defaultValues.contact
                        : undefined
                    }
                  >
                    <Editor
                      initialData={
                        hasDefaultValue && defaultValues?.contact
                          ? defaultValues.contact
                          : undefined
                      }
                      onChange={handleContactEditorChange}
                      data={contactContent}
                      disabled={!isEditMode}
                    ></Editor>
                  </Form.Item>
                </div>
              )}
            </div>
            {menuKeys === "request" && shouldShowFacilityRequestForm && (
              <RequestFacilitiesForm
                phoneNumber={
                  eventDetail?.service?.requester.phone_number ??
                  user.data?.user.phone_number ??
                  ""
                }
                eventDetail={eventDetail}
                hasDefaultValue={hasDefaultValue}
                isEditMode={isEditMode}
                requestFacility={
                  {
                    ...eventDetail?.service,
                    event_start_at:
                      eventDetail?.service?.event_start_at ??
                      eventDetail?.start_at,
                    event_end_at:
                      eventDetail?.service?.event_end_at ?? eventDetail?.end_at,
                    event_en_title:
                      eventDetail?.service?.event_en_title ??
                      eventDetail?.en_title,
                    event_title:
                      eventDetail?.service?.event_title ?? eventDetail?.title,
                    facilities: eventDetail?.service?.service_facilities,
                  } as WebEventServicePayload & WebEventService
                }
                username={username}
                facilities={facilities ?? []}
                formData={formData}
                className={cn("grid-cols-2 gap-x-4 flex-1 col-span-2 grid")}
                email={user.data?.user.email ?? ""}
              />
            )}
            {menuKeys === "request" && !shouldShowFacilityRequestForm && (
              <div className="col-span-2 flex-1 mb-5">
                <Typography className="font-medium text-md">
                  {t("menu.facilities.placeholderFacilityRequestForm", {
                    hours:
                      hours > 1
                        ? t("menu.facilities.hours", { number: hours })
                        : t("menu.facilities.hour", { number: hours }),
                  })}
                </Typography>
              </div>
            )}
            {menuKeys === "rating" && (
              <RatingForm
                className={cn("grid-cols-2 gap-x-4 flex-1 col-span-2 grid")}
                hasDefaultValue={isRate}
                isEditMode={isEditMode}
                formData={formData}
                rating={defaultValues?.service?.rate}
              />
            )}
            <div className="col-span-2">
              <Form.Item className="float-end mb-0">
                <Button
                  onClick={() => {
                    void navigate({ to: "/events" });
                  }}
                  className={cn(!isEditMode ? "bg-brand" : "", "w-[114px]")}
                  type={isEditMode ? "default" : "primary"}
                  size="large"
                >
                  {t("menu.close")}
                </Button>
                {shouldShowSaveButton && (
                  <Button
                    className="ml-5 border-brand bg-brand w-[114px]"
                    type="primary"
                    size="large"
                    htmlType="submit"
                  >
                    {t("menu.save")}
                  </Button>
                )}
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default EventForm;

/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { Form, FormInstance, Input, Typography } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { DATE_HOUR_MINUTES } from "@/lib/constants";
import { cn, getMinSetupTimeBeforeEvent } from "@/lib/utils";
import { Event, WebEventServicePayload, WebFacility } from "@/types/Api";

import { DatePicker } from "../DatePicker";
import CateringDuringEvent from "./CateringDuringEvent";
import EventInfo from "./EventInfo";
import FacilitiesTable from "./FacilitiesTable";
import FileTemplate from "./FileTemplate";
import ImageLayout from "./ImageLayout";
import ServiceNote from "./ServiceNote";

interface Props {
  hasDefaultValue: boolean;
  isEditMode: boolean;
  formData: FormInstance;
  email?: string;
  requestFacility?: WebEventServicePayload & Event["service"];
  username: string;
  facilities?: WebFacility[];
  className?: string;
  type?: "requester" | "approver";
  eventDetail?: Event;
  phoneNumber?: string;
}

const RequestFacilitiesForm = ({
  isEditMode,
  hasDefaultValue,
  requestFacility,
  username,
  facilities,
  formData,
  className,
  email,
  eventDetail,
  phoneNumber,
}: Props) => {
  const { t } = useTranslation("dashboard");
  const isRequestNotNew =
    eventDetail?.service && eventDetail.service.status !== "NEW";

  const facilitiesMap = useMemo(() => {
    return new Map(
      facilities?.map((item) => {
        return [item.id, item];
      }),
    );
  }, [facilities]);

  useEffect(() => {
    if (requestFacility?.facilities) {
      formData.setFieldValue(
        ["service", "facilities"],
        requestFacility.facilities,
      );
    }
  }, [formData, requestFacility?.facilities]);

  useEffect(() => {
    if (eventDetail?.service) {
      const newService = eventDetail.service;
      formData.setFieldValue("service", {
        ...eventDetail.service,
        facilities: newService.service_facilities?.map((item) => ({
          ...item,
          name: facilitiesMap.get(item.facility ?? -1)?.name,
        })),
        event_end_at: dayjs(newService.event_end_at),
        event_start_at: dayjs(newService.event_start_at),
        setup_at: dayjs(newService.setup_at),
        cleanup_at: dayjs(newService.cleanup_at),
      });
    }
  }, [eventDetail?.service, facilitiesMap, formData]);
  const hours = getMinSetupTimeBeforeEvent(eventDetail);

  return (
    <div className={className}>
      {isRequestNotNew && (
        <div className="mb-2 col-span-2">
          <Typography className="-mt-2 font-medium col-span-2 text-brand-secondary">
            {t("menu.facilities.requestStatus.approvedOrRejected")}
          </Typography>
          {requestFacility?.reason && (
            <Typography className="font-medium">
              {t("menu.facilities.reason")}: {requestFacility.reason}
            </Typography>
          )}
        </div>
      )}
      <EventInfo
        hasDefaultValue={hasDefaultValue}
        isEditMode={isEditMode}
        requestFacility={requestFacility}
        email={email}
        username={username}
        phoneNumber={phoneNumber}
      />
      <div className="col-span-2">
        <Typography className="font-medium">
          {t("menu.facilities.guestsOrParticipants")}
        </Typography>
        <div className="flex gap-4 mt-2 p-4 px-6 border border-solid border-[#D9D9D9]">
          <Form.Item
            name={["service", "total_participants"]}
            label={
              <Typography className="font-medium">
                {t("menu.facilities.totalParticipants")}
              </Typography>
            }
            initialValue={
              hasDefaultValue && requestFacility?.total_participants
                ? requestFacility.total_participants
                : undefined
            }
            className="flex-1"
          >
            <Input
              className={hasDefaultValue ? "!text-gray-800" : ""}
              disabled={!isEditMode}
              placeholder={t("menu.editHere")}
              type="number"
              readOnly={isRequestNotNew}
            />
          </Form.Item>
          <Form.Item
            name={["service", "total_outside_guests"]}
            label={
              <Typography className="font-medium">
                {t("menu.facilities.guestsOutsideVinUni")}
              </Typography>
            }
            initialValue={
              hasDefaultValue && requestFacility?.total_outside_guests
                ? requestFacility.total_outside_guests
                : undefined
            }
            className="flex-1"
          >
            <Input
              className={hasDefaultValue ? "!text-gray-800" : ""}
              disabled={!isEditMode}
              placeholder={t("menu.editHere")}
              type="number"
              readOnly={isRequestNotNew}
            />
          </Form.Item>

          <Form.Item
            name={["service", "total_vip_guests"]}
            label={
              <Typography className="font-medium">
                {t("menu.facilities.totalVIPGuests")}
              </Typography>
            }
            initialValue={
              hasDefaultValue && requestFacility?.total_vip_guests
                ? requestFacility.total_vip_guests
                : undefined
            }
            className="flex-1"
          >
            <Input
              className={hasDefaultValue ? "!text-gray-800" : ""}
              disabled={!isEditMode}
              placeholder={t("menu.editHere")}
              type="number"
              readOnly={isRequestNotNew}
            />
          </Form.Item>
        </div>
      </div>

      <CateringDuringEvent
        hasDefaultValue={hasDefaultValue}
        isEditMode={isEditMode}
        requestFacility={requestFacility}
      />

      <div className="col-span-2 mt-4">
        <div className="col-span-2 mt-4 flex gap-4">
          <Form.Item
            label={
              <Typography className="font-medium">
                {t("menu.facilities.startSetupTime")}
              </Typography>
            }
            help={
              <Typography className="text-sm text-[#00000073]">
                {t("menu.facilities.selectSetupTime", {
                  hours:
                    hours > 1
                      ? t("menu.facilities.hours", { number: hours })
                      : t("menu.facilities.hour", { number: hours }),
                })}
              </Typography>
            }
            name={["service", "setup_at"]}
            className={cn(
              "flex-1 cursor-text",
              !isEditMode ? "cursor-not-allowed" : "",
            )}
            initialValue={
              hasDefaultValue && requestFacility?.setup_at
                ? dayjs(requestFacility.setup_at)
                : undefined
            }
            required
            rules={[{ required: true }]}
          >
            <DatePicker
              disabledDate={(current) => {
                const startDate = formData.getFieldValue("start_at") as Dayjs;
                return (
                  current.isSame(dayjs(startDate), "day") ||
                  current.isAfter(dayjs(startDate).subtract(hours, "hours")) ||
                  current.isBefore(dayjs().startOf("day"))
                );
              }}
              disabledTime={(current) => {
                return {
                  disabledHours: () =>
                    current?.isSame(dayjs(), "day")
                      ? [...Array(dayjs().hour() + 1).keys()]
                      : [],
                };
              }}
              showSecond={false}
              showTime={{
                showNow: false,
                defaultValue: dayjs().add(1, "hour"),
              }}
              format={DATE_HOUR_MINUTES}
              className={cn(
                "w-full",
                isRequestNotNew ? "pointer-events-none" : "",
              )}
              disabled={!isEditMode}
              inputReadOnly={isRequestNotNew}
              placeholder={t("menu.select", {
                what: t("menu.facilities.startSetupTime").toLowerCase(),
              })}
            />
          </Form.Item>
        </div>
      </div>
      <FacilitiesTable
        hasDefaultValue={hasDefaultValue}
        isEditMode={isEditMode}
        isRequestNotNew={isRequestNotNew}
        facilities={facilities}
        facilitiesMap={facilitiesMap}
        formData={formData}
      />

      <ServiceNote
        hasDefaultValue={hasDefaultValue}
        isRequestNotNew={isRequestNotNew}
        isEditMode={isEditMode}
        note={requestFacility?.note}
        requestFacility={requestFacility}
      />
      <ImageLayout
        form={formData}
        isEdit={isEditMode}
        initialValue={
          (eventDetail?.service as { layout?: string } | undefined)?.layout ||
          (requestFacility as { layout?: string } | undefined)?.layout
        }
      />
      <FileTemplate
        defaultValue={
          (eventDetail?.service as { template?: string } | undefined)
            ?.template ||
          (requestFacility as { template?: string } | undefined)?.template
        }
        formData={formData}
        isEditMode={isEditMode}
      />
    </div>
  );
};

export default RequestFacilitiesForm;

import { FunnelPlotOutlined } from "@ant-design/icons";
import { Button, Dropdown, Select, Typography } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ListHeader, Stack } from "@/components";
import {
  EventServiceRequestsFilterState,
  useEventServiceRequestsFilter,
} from "@/jotai-atoms/event-service";
import { cn, filterSelectOptions } from "@/lib/utils";
import { fetchApiClient } from "@/lib/utils/fetchUtils";

interface Props {
  onSearchChange: (value: string) => void;
}

const OPTIONS = {
  New: "NEW",
  Approved: "APPROVED",
  Rejected: "REJECTED",
};

const EventServiceRequestsHeader = ({ onSearchChange }: Props) => {
  const { t } = useTranslation("dashboard");
  const { filter, setFilter } = useEventServiceRequestsFilter();
  const [eventServiceRequestsFilter, setEventServiceRequestFilter] =
    useState<EventServiceRequestsFilterState>();

  const [open, setOpen] = useState(false);
  const shouldDisableResetBtn = !filter?.status || filter.status.length === 0;

  const handleResetFilter = () => {
    setFilter((prev) => ({ ...prev, page: 1, status: [] }));
    setEventServiceRequestFilter({ status: undefined });
    setOpen(false);
  };
  const handleApplyFilter = () => {
    setFilter((prev) => ({
      ...prev,
      page: 1,
      status: eventServiceRequestsFilter?.status,
    }));
    setOpen(false);
  };

  const handleExport = async () => {
    try {
      const file = await fetchApiClient.eventServices.exportEventServices({
        format: "blob",
      });
      const url = window.URL.createObjectURL(file);
      const a = document.createElement("a");
      a.href = url;
      a.download = "exported-file.csv";
      document.body.appendChild(a);
      a.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  return (
    <ListHeader
      search={filter?.search}
      onChange={onSearchChange}
      title={t("menu.list")}
      onExport={handleExport as () => void}
      filter={
        <Dropdown
          open={open}
          onOpenChange={setOpen}
          trigger={["click"]}
          menu={{
            className: "w-[272px]",
            items: [
              {
                label: (
                  <Stack className="justify-between gap-6">
                    <Button
                      size="small"
                      type="text"
                      onClick={handleResetFilter}
                      disabled={shouldDisableResetBtn}
                      className={cn(
                        !shouldDisableResetBtn &&
                          "text-[var(--ant-primary-color)]",
                      )}
                    >
                      {t("menu.button.reset")}
                    </Button>
                    <Button
                      size="small"
                      type="text"
                      className="bg-brand-primary text-white"
                      onClick={handleApplyFilter}
                    >
                      {t("menu.button.ok")}
                    </Button>
                  </Stack>
                ),
                className: cn("hover:bg-transparent cursor-default px-2"),
                key: "action",
              },
              { type: "divider" },
              {
                label: (
                  <div>
                    <Typography.Text className="font-medium">
                      {t("menu.status")}
                    </Typography.Text>
                    <Select
                      showArrow
                      allowClear
                      maxTagTextLength={24}
                      placeholder={t("menu.select", {
                        what: t("menu.status").toLowerCase(),
                      })}
                      className="w-full mt-1"
                      options={Object.entries(OPTIONS).map(([key, value]) => ({
                        label: key,
                        value,
                      }))}
                      onChange={(status) => {
                        setEventServiceRequestFilter((prev) => {
                          return {
                            ...prev,
                            status,
                          };
                        });
                      }}
                      value={eventServiceRequestsFilter?.status}
                      filterOption={filterSelectOptions}
                    />
                  </div>
                ),
                className: "hover:bg-transparent bg-transparent",
                key: "status",
              },
            ],
          }}
        >
          <Stack
            className="cursor-pointer gap-1"
            onClick={() => {
              setOpen(!open);
            }}
          >
            <span>{t("menu.filter")}</span>
            <FunnelPlotOutlined className="text-[#8C8C8C]" />
          </Stack>
        </Dropdown>
      }
    />
  );
};

export default EventServiceRequestsHeader;

import { createLoader } from "@/lib/utils";
import { LoaderType } from "@/main";

import { benefitsAtoms, benefitsQueries } from "./store";

export const benefitsListLoader = createLoader((props) => [
  benefitsQueries.allTypes(),
  benefitsQueries.list(
    props.context.globalStore.get(benefitsAtoms.filter.currentValueAtom),
  ),
]);

export const benefitsDetailLoader = createLoader<{ id: string }>((props) => [
  benefitsQueries.details(props.params.id),
  benefitsQueries.allTypes(),
]);

export const benefitsTypesLoader = createLoader((props) => [
  benefitsQueries.types(
    props.context.globalStore.get(benefitsAtoms.filterTypes.currentValueAtom),
  ),
]);

export const benefitTypesDetailsLoader = ({
  context: { queryClient },
  params: { id },
}: LoaderType<{ id: string }>) => {
  void queryClient.ensureQueryData(benefitsQueries.detailsType(+id));
};

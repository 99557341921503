import { PlusOutlined } from "@ant-design/icons";
import { Form, Input, Select, Typography, UploadFile } from "antd";
import { useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  LayoutDetailsForm,
  LayoutDetailsFormProps,
  UploadImage,
} from "@/components";
import { useImage } from "@/hooks";
import { SUPPORTED_IMAGE_TYPES } from "@/lib/constants";
import { filterSelectOptions } from "@/lib/utils";
import { WebFacilityPayload } from "@/types/Api";

import { useAllFacilityTypes } from "../../-data/hooks";

interface Props extends LayoutDetailsFormProps<WebFacilityPayload> {}

const FacilityDetailForm = (props: Props) => {
  const { form } = props;
  const formThumbnail = form.getValues("image");
  const { t } = useTranslation("dashboard");
  const [fileList, setFiledList] = useState<UploadFile[]>(
    formThumbnail
      ? [
          {
            uid: crypto.randomUUID(),
            name: formThumbnail,
            status: "done",
            url: formThumbnail,
            thumbUrl: formThumbnail,
          },
        ]
      : [],
  );
  const { uploadImage } = useImage();

  const { data } = useAllFacilityTypes();
  const facilities = data?.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  return (
    <LayoutDetailsForm {...props}>
      <Controller
        control={form.control}
        name="name"
        render={({ field, fieldState: { error } }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.name")}
                </Typography.Text>
              }
              className="col-span-2"
              validateStatus={error ? "error" : ""}
              help={error?.message}
              required
            >
              <Input
                {...field}
                disabled={!props.isEdit}
                placeholder={t("menu.editHere")}
                value={field.value}
              />
            </Form.Item>
          );
        }}
      />
      <Controller
        control={form.control}
        name="description"
        render={({ field, fieldState: { error } }) => {
          return (
            <Form.Item
              className="col-span-2"
              label={
                <Typography.Text className="font-medium">
                  {t("menu.serviceRequest.description")}
                </Typography.Text>
              }
              validateStatus={error ? "error" : ""}
              help={error?.message}
            >
              <Input.TextArea
                {...field}
                rows={3}
                value={field.value ?? ""}
                disabled={!props.isEdit}
                placeholder={t("menu.editHere")}
              />
            </Form.Item>
          );
        }}
      />
      <Controller
        control={form.control}
        name="type"
        render={({ field }) => {
          return (
            <Form.Item
              className="col-span-1"
              label={
                <Typography.Text className="font-medium">
                  {t("menu.facilities.type")}
                </Typography.Text>
              }
            >
              <Select
                showArrow
                options={facilities}
                placeholder={t("menu.select", {
                  what: t("menu.facilities.type").toLowerCase(),
                })}
                filterOption={filterSelectOptions}
                {...field}
                disabled={!props.isEdit}
                value={field.value}
              />
            </Form.Item>
          );
        }}
      />
      <Controller
        control={form.control}
        name="quantity"
        render={({ field, fieldState: { error } }) => {
          return (
            <Form.Item
              className="col-span-1"
              label={
                <Typography.Text className="font-medium">
                  {t("menu.facilities.quantity")}
                </Typography.Text>
              }
              validateStatus={error ? "error" : ""}
              help={error?.message}
              required
            >
              <Input
                {...field}
                value={field.value}
                disabled={!props.isEdit}
                type="number"
                placeholder={t("menu.editHere")}
              />
            </Form.Item>
          );
        }}
      />
      <Controller
        control={form.control}
        name="image"
        render={({ fieldState: { error } }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.image.image")}
                </Typography.Text>
              }
              className="col-span-2"
              validateStatus={error ? "error" : ""}
              help={error?.message}
              required
            >
              <UploadImage
                showUploadList={{
                  showDownloadIcon: false,
                  showPreviewIcon: true,
                  showRemoveIcon: props.isEdit,
                }}
                disabled={!props.isEdit}
                accept={SUPPORTED_IMAGE_TYPES.join(",")}
                onRemove={(file) => {
                  setFiledList((prev) => {
                    const result = prev.filter((item) => item.uid !== file.uid);
                    return [...result];
                  });
                  form.setValue("image", undefined);
                }}
                customRequest={(options) => {
                  const file = options.file as File;
                  const filename = file.name;

                  void uploadImage(
                    { file, filename },
                    {
                      onSuccess: (url) => {
                        form.setValue("image", url);
                        setFiledList([
                          {
                            name: url,
                            url: url,
                            thumbUrl: url,
                            status: "done",
                            uid: crypto.randomUUID(),
                          },
                        ]);
                      },
                    },
                  );
                }}
                fileList={fileList}
                emptyComponent={props.isEdit ? null : undefined}
                className="w-full"
              >
                {props.isEdit && fileList.length === 0 && (
                  <div className="flex w-full flex-col items-center gap-1">
                    <PlusOutlined className="" />
                    <span>{t("menu.upload")}</span>
                  </div>
                )}
              </UploadImage>
            </Form.Item>
          );
        }}
      />
    </LayoutDetailsForm>
  );
};

export default FacilityDetailForm;

import {
  PlusOutlined,
  SearchOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { ReactNode } from "@tanstack/react-router";
import { Button, ButtonProps, Input, Typography, Upload } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Stack } from "@/components/Stack";

interface Props {
  search?: string;
  onChange?: (value: string) => void;
  onSearch?: (value: string) => void;
  isLoading?: boolean;
  title?: string | React.ReactNode;
  onAddNewClick?: () => void;
  placeholder?: string;
  disableSearch?: boolean;
  filter?: ReactNode;
  upload?: {
    isLoading: boolean;
    onUpload: (file: File) => void;
  };
  onExport?: () => void;
}

const ActionsButton = (props: ButtonProps) => {
  return (
    <Button
      className="bg-brand-secondary border-brand-secondary 
                focus:bg-brand-secondary focus:border-brand-secondary
                hover:bg-brand-secondary hover:border-brand-secondary
                "
      {...props}
    />
  );
};

export const ListHeader = ({
  search,
  onSearch,
  isLoading,
  onChange,
  title,
  onAddNewClick,
  placeholder,
  filter,
  upload,
  onExport,
}: Props) => {
  const { t } = useTranslation("dashboard");
  const [isOpenSearch, setIsOpenSearch] = useState(false);

  return (
    <Stack className="mb-4 justify-between">
      {typeof title === "string" ? (
        <Typography.Title className="!mb-0" level={4}>
          {title}
        </Typography.Title>
      ) : (
        title
      )}
      <div className="flex flex-1 items-center justify-end gap-4">
        {!isOpenSearch ? (
          onChange && (
            <Stack
              className="cursor-pointer gap-1"
              onClick={() => {
                setIsOpenSearch(true);
              }}
            >
              <span>{t("menu.search.search")}</span>
              <SearchOutlined className="text-[#8C8C8C]" />
            </Stack>
          )
        ) : (
          <Input.Search
            autoFocus
            value={search}
            // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
            placeholder={placeholder || t("menu.search.search")}
            className="w-1/3"
            onSearch={onSearch ?? onChange}
            loading={isLoading}
            onChange={(e) => {
              onChange?.(e.target.value);
            }}
            onBlur={() => {
              setIsOpenSearch(false);
            }}
          />
        )}
        {filter}
        {onAddNewClick && (
          <Button
            className="border-brand bg-brand-primary"
            type="primary"
            icon={<PlusOutlined />}
            onClick={onAddNewClick}
          >
            {t("menu.addNew")}
          </Button>
        )}
        {upload && (
          <Upload
            accept=".xlsx,.xls"
            showUploadList={false}
            beforeUpload={upload.onUpload}
          >
            <ActionsButton
              type="primary"
              loading={upload.isLoading}
              icon={<UploadOutlined />}
            >
              {t("menu.upload")}
            </ActionsButton>
          </Upload>
        )}
        {onExport && (
          <ActionsButton type="primary" onClick={onExport}>
            {t("menu.export")}
          </ActionsButton>
        )}
      </div>
    </Stack>
  );
};

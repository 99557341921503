import { Dropdown } from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { useTranslation } from "react-i18next";

import { i18nLocalStorageKey } from "@/lib/config/i18n";

const langs = ["vietnam", "usa"];

const ChangeLanguage = () => {
  const { i18n } = useTranslation();
  const isVi = i18n.language === "vi";
  const getSrc = (arr: typeof langs) => {
    return `/${isVi ? arr[0] : arr[1]}.png`;
  };

  const dropdownItems: ItemType[] = [
    {
      label: <img src={getSrc([...langs].reverse())} width={30} />,
      key: "ln",
      onClick: () => {
        const chgLang = isVi ? "en" : "vi";
        void i18n.changeLanguage(chgLang);
        localStorage.setItem(i18nLocalStorageKey, chgLang);
      },
    },
  ];

  return (
    <Dropdown
      menu={{
        items: dropdownItems,
      }}
      className="ml-auto mr-6"
    >
      <img src={getSrc(langs)} width={32} />
    </Dropdown>
  );
};

export default ChangeLanguage;

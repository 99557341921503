import { FunnelPlotOutlined } from "@ant-design/icons";
import { useNavigate } from "@tanstack/react-router";
import { Button, Checkbox, Dropdown, Select, Typography } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Stack, ListHeader } from "@/components";
import { cn, filterSelectOptions } from "@/lib/utils";

import { useAllBenefitTypes, useBenefitListFilter } from "../-data/hooks";
import { BenefitsListFilterType } from "../-data/store";

const BenefitsListHeader = () => {
  const { t } = useTranslation("dashboard");
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState<BenefitsListFilterType>();
  const { setBenefitsFilter } = useBenefitListFilter();
  const { data: benefitTypes } = useAllBenefitTypes();

  const publishSet = new Set(filter?.published ?? []);
  const shouldDisableResetBtn = !filter?.type && !filter?.published;

  const handleChange = (search: string) => {
    setBenefitsFilter((prev) => {
      return {
        ...prev,
        search,
      };
    });
  };
  const handleAddNewClick = () => {
    void navigate({ to: "/benefits/add" });
  };
  const handleCloseDropdown = () => {
    setOpen(false);
  };
  const handleResetFilter = () => {
    const resetFilter = {
      type: undefined,
      published: undefined,
    };
    setFilter(resetFilter);
    setBenefitsFilter((prev) => {
      return {
        ...prev,
        ...resetFilter,
      };
    });
    handleCloseDropdown();
  };
  const handleApplyFilter = () => {
    setBenefitsFilter({
      published: filter?.published,
      type: filter?.type,
    });
    handleCloseDropdown();
  };
  const setPublished = (published: boolean) => {
    setFilter((prev) => {
      publishSet.has(published)
        ? publishSet.delete(published)
        : publishSet.add(published);
      return {
        ...prev,
        published: structuredClone(Array.from(publishSet)),
      };
    });
  };

  return (
    <ListHeader
      title={t("menu.list", { ns: "dashboard" })}
      onChange={handleChange}
      filter={
        <Dropdown
          open={open}
          onOpenChange={setOpen}
          trigger={["click"]}
          menu={{
            className: "w-[272px]",
            items: [
              {
                label: (
                  <Stack className="justify-between gap-6">
                    <Button
                      size="small"
                      type="text"
                      onClick={handleResetFilter}
                      disabled={shouldDisableResetBtn}
                      className={cn(
                        !shouldDisableResetBtn &&
                          "text-[var(--ant-primary-color)]",
                      )}
                    >
                      {t("menu.button.reset")}
                    </Button>
                    <Button
                      size="small"
                      type="text"
                      className="bg-brand-primary text-white"
                      onClick={handleApplyFilter}
                      loading={false}
                    >
                      {t("menu.button.ok")}
                    </Button>
                  </Stack>
                ),
                className: cn("hover:bg-transparent cursor-default px-2"),
                key: "action",
              },
              { type: "divider" },
              {
                label: (
                  <div className="flex flex-col gap-2">
                    <Typography.Text className="font-medium">
                      {t("menu.benefits.type.types")}
                    </Typography.Text>
                    <Select
                      showArrow
                      allowClear
                      mode="multiple"
                      maxTagTextLength={24}
                      placeholder={t("menu.select", {
                        what: "type",
                      })}
                      className="w-full"
                      options={benefitTypes?.map((item) => {
                        return {
                          label: item.name,
                          value: item.id,
                        };
                      })}
                      onChange={(type) => {
                        setFilter((prev) => {
                          return {
                            ...prev,
                            type,
                          };
                        });
                      }}
                      value={filter?.type}
                      filterOption={filterSelectOptions}
                    />
                  </div>
                ),
                className: "hover:bg-transparent bg-transparent",
                key: "type",
              },
              {
                label: (
                  <Typography.Text className="font-medium">
                    {t("menu.published")}
                  </Typography.Text>
                ),
                type: "group",
                key: "published",
                className: "mx-0 ",
                children: [
                  {
                    key: "published-true",
                    label: (
                      <Checkbox
                        onChange={() => {
                          setPublished(true);
                        }}
                        checked={publishSet.has(true)}
                      >
                        {t("menu.published")}
                      </Checkbox>
                    ),
                  },
                  {
                    key: "published-false",
                    label: (
                      <Checkbox
                        onChange={() => {
                          setPublished(false);
                        }}
                        checked={publishSet.has(false)}
                      >
                        {t("menu.unpublished")}
                      </Checkbox>
                    ),
                  },
                ],
              },
            ],
          }}
        >
          <Stack
            className="cursor-pointer gap-1"
            onClick={() => {
              setOpen(!open);
            }}
          >
            <span>{t("menu.filter")}</span>
            <FunnelPlotOutlined className="text-[#8C8C8C]" />
          </Stack>
        </Dropdown>
      }
      onAddNewClick={handleAddNewClick}
    />
  );
};

export default BenefitsListHeader;

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AdvisingGroup {
  academic_advisor?: Advisor;
  date?: string;
  end_time?: string;
  generic_advisor?: Advisor;
  id?: number;
  location?: string | null;
  members?: AdvisingMemberProfile[];
  peer_advisor?: Advisor;
  requirements?: string;
  session_name?: string;
  start_time?: string;
}

export interface AdvisingMemberProfile {
  id?: number;
  name?: string;
  photo?: string | null;
  university_email?: string;
}

export interface Advisor {
  email?: string;
  id?: number;
  name?: string;
}

export interface AppConfig {
  /**
   * Api timeout
   * @min 0
   * @max 2147483647
   */
  api_timeout?: number;
  /** @format url */
  campus_guideline_handbook_url?: string;
  /** @format url */
  campus_map_url?: string;
  /**
   * CDN URI
   * @format url
   */
  cdn?: string;
  /**
   * Code
   * @minLength 1
   * @maxLength 255
   */
  code: string;
  /** @format url */
  code_of_conduct_url?: string;
  /** @format url */
  course_registration_url?: string;
  /**
   * Default category icon
   * @format uri
   */
  default_category_icon?: string | null;
  /**
   * Default new thumbnail
   * @format uri
   */
  default_new_thumbnail?: string | null;
  /** Emergency contact */
  emergency_contact?:
    | {
        name: string;
        phone: string;
      }[]
    | null;
  /** @format url */
  facility_request_template?: string;
  /**
   * Faculty policy url
   * @format uri
   * @maxLength 2000
   */
  faculty_policy_url?: string | null;
  group_event_rating_after_days?: number;
  /**
   * Home header image
   * @format uri
   */
  home_header_image?: string | null;
  /** ID */
  id?: number;
  /** @format url */
  internation_student_handbook_url?: string;
  /**
   * Library service url
   * @format uri
   * @maxLength 2000
   */
  library_service_url?: string | null;
  limit_rating_event_requests?: number;
  min_setup_time_before_event?: number;
  payment_policy?: {
    /** HTML content for payment policy */
    content?: string;
  };
  /**
   * Delay time between ratings
   * @format int32
   * @min 0
   * @max 2147483647
   * @default 3600
   */
  rating_delay_seconds?: number;
  /**
   * Student policy url
   * @format uri
   * @maxLength 2000
   */
  student_policy_url?: string | null;
  /** @format url */
  student_success_url?: string;
  /**
   * Updated at
   * @format date-time
   */
  updated_at?: string;
  /** @format url */
  wow_handbook_url?: string;
}

export interface Benefit {
  /**
   * Content
   * @maxLength 50000
   */
  content?: string | null;
  /**
   * Created at
   * @format date-time
   */
  created_at?: string;
  created_by?: UserBasicInfo;
  /**
   * Eligibility
   * @maxLength 2000
   */
  eligibility?: string | null;
  /**
   * En content
   * @maxLength 50000
   */
  en_content?: string | null;
  /**
   * Eligibility
   * @maxLength 2000
   */
  en_eligibility?: string | null;
  /**
   * How to redeem
   * @maxLength 2000
   */
  en_how_to_redeem?: string | null;
  /**
   * En summary
   * @maxLength 500
   */
  en_summary?: string | null;
  /**
   * En title
   * @maxLength 500
   */
  en_title?: string | null;
  /**
   * End date
   * @format date
   */
  end_date: string;
  /**
   * How to redeem
   * @maxLength 2000
   */
  how_to_redeem?: string | null;
  /** ID */
  id?: number;
  /** Published */
  published?: boolean;
  /**
   * Start date
   * @format date
   */
  start_date: string;
  /**
   * Summary
   * @maxLength 500
   */
  summary?: string | null;
  /**
   * Thumbnail
   * @format uri
   */
  thumbnail?: string | null;
  /**
   * Title
   * @minLength 1
   * @maxLength 500
   */
  title: string;
  type?: BenefitType;
  /**
   * Updated at
   * @format date-time
   */
  updated_at?: string;
}

export interface BenefitList {
  /** Content */
  content?: string | null;
  /**
   * Created at
   * @format date-time
   */
  created_at?: string;
  created_by?: UserBasicInfo;
  /**
   * Eligibility
   * @maxLength 2000
   */
  eligibility?: string | null;
  /** Content */
  en_content?: string | null;
  /**
   * Eligibility
   * @maxLength 2000
   */
  en_eligibility?: string | null;
  /**
   * How to redeem
   * @maxLength 2000
   */
  en_how_to_redeem?: string | null;
  /**
   * En summary
   * @maxLength 500
   */
  en_summary?: string | null;
  /**
   * En title
   * @maxLength 500
   */
  en_title?: string | null;
  /**
   * End date
   * @format date
   */
  end_date: string;
  /**
   * How to redeem
   * @maxLength 2000
   */
  how_to_redeem?: string | null;
  /** ID */
  id?: number;
  /** Published */
  published?: boolean;
  /**
   * Start date
   * @format date
   */
  start_date: string;
  /**
   * Summary
   * @maxLength 500
   */
  summary?: string | null;
  /**
   * Thumbnail
   * @format uri
   */
  thumbnail?: string | null;
  /**
   * Title
   * @minLength 1
   * @maxLength 500
   */
  title: string;
  type?: BenefitType;
  /**
   * Updated at
   * @format date-time
   */
  updated_at?: string;
}

export interface BenefitType {
  /**
   * En name
   * @maxLength 50
   */
  en_name?: string | null;
  /** ID */
  id?: number;
  /**
   * Name
   * @minLength 1
   * @maxLength 50
   */
  name: string;
  /**
   * Order
   * @min -2147483648
   * @max 2147483647
   */
  order?: number | null;
}

export interface CalendarRoom {
  capacity?: number;
  /**
   * @format email
   * @example "sparrow@zen8labs.com"
   */
  email?: string;
  /** @example "Sparrow" */
  name?: string;
  /** @format url */
  photo?: string;
  status?: "FREE" | "BUSY" | "UNKNOWN";
}

export interface ClassInstructor {
  code?: string;
  display_order?: number;
  id: number;
  instructor_attributes?: {
    code?: string;
    id?: number;
    name?: string | null;
  }[];
  name?: string | null;
  position_name?: string | null;
}

export interface CodeOfConduct {
  /**
   * Date of violation
   * @format date
   */
  date_of_violation?: string | null;
  /** Description */
  description?: string | null;
  /** ID */
  id?: string;
  /**
   * Level of violation
   * @maxLength 255
   */
  level_of_violation?: string | null;
  /**
   * Type of violation
   * @maxLength 255
   */
  type_of_violation?: string | null;
}

export interface CreateGroupBody {
  /**
   * Name
   * @minLength 1
   */
  name: string;
}

export interface CreateNotificationTemplateRequest {
  /**
   * Action
   * @maxLength 255
   */
  action?: string | null;
  /** Action type */
  action_type?: "Internal Link" | "External Link" | "Feature" | null;
  /** Content */
  content: string;
  /** Data */
  data?: object | null;
  /**
   * En action
   * @maxLength 255
   */
  en_action?: string | null;
  /** En content */
  en_content: string;
  /**
   * En title
   * @maxLength 255
   */
  en_title: string;
  /**
   * Name
   * @minLength 1
   * @maxLength 100
   */
  name?: string | null;
  /** Related event */
  related_event?: number | null;
  /**
   * Start time
   * @format date-time
   */
  start_time?: string | null;
  /**
   * Status
   * @default "Created"
   */
  status?: "Created" | "Started" | "Processing" | "Partial Failure" | "Completed" | "Failed";
  /** Target customer id */
  target_customer?: number | null;
  /**
   * Title
   * @maxLength 255
   */
  title: string;
  /** To all user */
  to_all_user?: boolean;
  /** Type id */
  type: number;
}

export interface CreateTargetCustomerRequest {
  /**
   * Cohort
   * @minLength 1
   * @maxLength 255
   */
  cohort?: string;
  /** Colleges */
  colleges?: string[];
  /**
   * Name
   * @minLength 1
   * @maxLength 100
   */
  name: string;
  /** Roles */
  roles?: ("Staff" | "Student")[];
  /** @uniqueItems true */
  users?: number[] | null;
}

export interface DebitTransaction {
  /** Currency */
  currency?: string;
  /** Description */
  description?: string;
  /** ID */
  id: string;
  /**
   * Unpaid amount
   * @format float
   */
  unpaid_amount: number;
}

export type DislikeEventRequest = object;

export interface DislikeEventResponse {
  /** Ok */
  ok: boolean;
}

export interface Event {
  agendas?: EventAgenda[];
  /** Contact */
  contact?: string | null;
  /**
   * Content
   * @maxLength 10000
   */
  content?: string | null;
  /**
   * Created at
   * @format date-time
   */
  created_at?: string;
  created_by?: UserBasicInfo;
  /**
   * En content
   * @maxLength 50000
   */
  en_content?: string | null;
  /**
   * En external id
   * @min -2147483648
   * @max 2147483647
   */
  en_external_id?: number | null;
  /**
   * En summary
   * @maxLength 500
   */
  en_summary?: string | null;
  /**
   * En title
   * @maxLength 200
   */
  en_title?: string | null;
  /**
   * En url
   * @format uri
   * @maxLength 2000
   */
  en_url?: string | null;
  /**
   * End at
   * @format date-time
   */
  end_at?: string;
  event_types?: EventType[];
  /**
   * External id
   * @min -2147483648
   * @max 2147483647
   */
  external_id?: number | null;
  /**
   * External thumbnail url
   * @format uri
   * @maxLength 2000
   */
  external_thumbnail_url?: string | null;
  /** Host type */
  host_type?: "OFFLINE" | "ONLINE";
  /** ID */
  id?: number;
  /** Is highlight */
  is_highlight?: boolean;
  /** Is liked */
  is_liked?: boolean;
  /** Is registered */
  is_registered?: boolean;
  /** Lat */
  lat?: number | null;
  /** @uniqueItems true */
  likes?: number[];
  /** Location */
  location?: string | null;
  /** Long */
  long?: number | null;
  /** Min setup time before event in minutes */
  min_setup_time_before_event?: number;
  /**
   * N likes
   * @min 0
   * @max 2147483647
   */
  n_likes?: number;
  /** Published */
  published?: boolean;
  service?: WebEventService;
  speakers?: Speaker[];
  /**
   * Start at
   * @format date-time
   */
  start_at: string;
  /** Status */
  status?: "UPCOMING" | "ONGOING" | "COMPLETED" | "CANCELLED";
  /**
   * Summary
   * @minLength 1
   * @maxLength 200
   */
  summary: string;
  /**
   * Thumbnail
   * @format uri
   */
  thumbnail?: string | null;
  /**
   * Title
   * @minLength 1
   * @maxLength 200
   */
  title: string;
  /**
   * Updated at
   * @format date-time
   */
  updated_at?: string;
  /**
   * Url
   * @format uri
   * @maxLength 2000
   */
  url?: string | null;
}

export interface EventAdd {
  /** Agendas */
  agendas?: EventAgenda[];
  /** Contact */
  contact?: string | null;
  /**
   * Content
   * @maxLength 10000
   */
  content?: string | null;
  /** Created by */
  created_by: number;
  /**
   * En content
   * @maxLength 50000
   */
  en_content?: string | null;
  /**
   * En external id
   * @min -2147483648
   * @max 2147483647
   */
  en_external_id?: number | null;
  /**
   * En summary
   * @maxLength 500
   */
  en_summary?: string | null;
  /**
   * En title
   * @maxLength 200
   */
  en_title?: string | null;
  /**
   * En url
   * @format uri
   * @maxLength 2000
   */
  en_url?: string | null;
  /**
   * End at
   * @format date-time
   */
  end_at?: string;
  /** @uniqueItems true */
  event_types?: number[];
  /**
   * External id
   * @min -2147483648
   * @max 2147483647
   */
  external_id?: number | null;
  /**
   * External thumbnail url
   * @format uri
   * @maxLength 2000
   */
  external_thumbnail_url?: string | null;
  /** Host type */
  host_type?: "OFFLINE" | "ONLINE";
  /** ID */
  id?: number;
  /** Is highlight */
  is_highlight?: boolean;
  /** Lat */
  lat?: number | null;
  /** Location */
  location?: string | null;
  /** Long */
  long?: number | null;
  /** Published */
  published?: boolean;
  /** Read only */
  read_only?: boolean;
  /** @uniqueItems true */
  speakers?: number[];
  /**
   * Start at
   * @format date-time
   */
  start_at: string;
  /** Status */
  status?: "ACTIVE" | "CANCELLED";
  /**
   * Summary
   * @minLength 1
   * @maxLength 200
   */
  summary?: string;
  /**
   * Thumbnail
   * @format uri
   */
  thumbnail?: string;
  /**
   * Title
   * @minLength 1
   * @maxLength 200
   */
  title: string;
  /**
   * Url
   * @format uri
   * @maxLength 2000
   */
  url?: string | null;
}

export interface EventAgenda {
  /**
   * Activity
   * @minLength 1
   */
  activity: string;
  /**
   * Detail
   * @minLength 1
   */
  detail?: string;
  /**
   * En activity
   * @minLength 1
   */
  en_activity?: string;
  /**
   * En detail
   * @minLength 1
   */
  en_detail?: string;
  /** End time */
  end_time: string;
  /** Start time */
  start_time: string;
}

export interface EventCheckInBody {
  /** Group event id */
  group_event_id?: number;
  /** User id */
  user_id: number;
}

export interface EventList {
  agendas?: EventAgenda[];
  /** Contact */
  contact?: string | null;
  /**
   * Created at
   * @format date-time
   */
  created_at?: string;
  created_by?: UserBasicInfo;
  /**
   * En content
   * @maxLength 50000
   */
  en_content?: string | null;
  /**
   * En summary
   * @maxLength 500
   */
  en_summary?: string | null;
  /**
   * En title
   * @maxLength 200
   */
  en_title?: string | null;
  /**
   * En url
   * @format uri
   * @maxLength 2000
   */
  en_url?: string | null;
  event_types?: EventType[];
  /**
   * External id
   * @min -2147483648
   * @max 2147483647
   */
  external_id?: number | null;
  has_facility?: boolean;
  /** Host type */
  host_type?: "OFFLINE" | "ONLINE";
  /** ID */
  id?: number;
  /** Is highlight */
  is_highlight?: boolean;
  /** Is registered */
  is_registered?: boolean;
  /** Lat */
  lat?: number | null;
  /** @uniqueItems true */
  likes?: number[];
  /** Location */
  location?: string | null;
  /** Long */
  long?: number | null;
  /**
   * N likes
   * @min 0
   * @max 2147483647
   */
  n_likes?: number;
  /** Published */
  published?: boolean;
  speakers?: Speaker[];
  /**
   * Start at
   * @format date-time
   */
  start_at: string;
  /** Status */
  status?: "UPCOMING" | "ONGOING" | "COMPLETED" | "CANCELLED";
  /**
   * Summary
   * @minLength 1
   * @maxLength 200
   */
  summary: string;
  /**
   * Thumbnail
   * @format uri
   */
  thumbnail?: string | null;
  /**
   * Title
   * @minLength 1
   * @maxLength 200
   */
  title: string;
  /**
   * Updated at
   * @format date-time
   */
  updated_at?: string;
  /**
   * Url
   * @format uri
   * @maxLength 2000
   */
  url?: string | null;
}

export interface EventRating {
  /**
   * Comment
   * @maxLength 2000
   */
  comment?: string | null;
  /**
   * Created at
   * @format date-time
   */
  created_at?: string;
  /** Event */
  event: number;
  /** ID */
  id?: number;
  /**
   * Rating
   * @min 0
   * @max 2147483647
   */
  rating: number;
  /**
   * Updated at
   * @format date-time
   */
  updated_at?: string;
  /** User */
  user: number;
}

export interface EventRatingBody {
  /**
   * Comment
   * @minLength 1
   */
  comment?: string;
  /** Rating */
  rating: number;
}

export interface EventReadInfo {
  /**
   * En title
   * @maxLength 200
   */
  en_title?: string | null;
  /** ID */
  id?: number;
  /**
   * Title
   * @minLength 1
   * @maxLength 200
   */
  title: string;
}

export interface EventRegistration {
  /** Checked in */
  checked_in?: boolean;
  /**
   * Created at
   * @format date-time
   */
  created_at?: string;
  /** Event */
  event: number;
  /** ID */
  id?: number;
  /** Rating */
  rating?: number;
  /** Status */
  status?: "REGISTERED" | "CANCELLED";
  /**
   * Updated at
   * @format date-time
   */
  updated_at?: string;
  user: UserBasicInfo;
}

export type EventRegistrationBody = object;

export interface EventRegistrationResponse {
  /** Checked in */
  checked_in?: boolean;
  /**
   * Created at
   * @format date-time
   */
  created_at?: string;
  /** Event */
  event: number;
  /** ID */
  id?: number;
  /** Rating */
  rating?: number;
  /** Status */
  status?: "REGISTERED" | "CANCELLED" | "CHECKED_IN";
  /**
   * Updated at
   * @format date-time
   */
  updated_at?: string;
  user: UserBasicInfo;
}

export interface EventType {
  /**
   * En name
   * @maxLength 50
   */
  en_name?: string | null;
  /** ID */
  id?: number;
  /**
   * Name
   * @minLength 1
   * @maxLength 50
   */
  name: string;
  /**
   * Order
   * @min -2147483648
   * @max 2147483647
   */
  order?: number | null;
}

export interface EventUpdate {
  /** Agendas */
  agendas?: EventAgenda[];
  /** Contact */
  contact?: string | null;
  /**
   * Content
   * @maxLength 10000
   */
  content?: string | null;
  /** Created by */
  created_by: number;
  /**
   * En content
   * @maxLength 50000
   */
  en_content?: string | null;
  /**
   * En external id
   * @min -2147483648
   * @max 2147483647
   */
  en_external_id?: number | null;
  /**
   * En summary
   * @maxLength 500
   */
  en_summary?: string | null;
  /**
   * En title
   * @maxLength 200
   */
  en_title?: string | null;
  /**
   * En url
   * @format uri
   * @maxLength 2000
   */
  en_url?: string | null;
  /**
   * End at
   * @format date-time
   */
  end_at?: string;
  /** @uniqueItems true */
  event_types?: number[];
  /**
   * External id
   * @min -2147483648
   * @max 2147483647
   */
  external_id?: number | null;
  /**
   * External thumbnail url
   * @format uri
   * @maxLength 2000
   */
  external_thumbnail_url?: string | null;
  /** Host type */
  host_type?: "OFFLINE" | "ONLINE";
  /** ID */
  id?: number;
  /** Is highlight */
  is_highlight?: boolean;
  /** Lat */
  lat?: number | null;
  /** Location */
  location?: string | null;
  /** Long */
  long?: number | null;
  /** Published */
  published?: boolean;
  /** Read only */
  read_only?: boolean;
  /** @uniqueItems true */
  speakers?: number[];
  /**
   * Start at
   * @format date-time
   */
  start_at: string;
  /** Status */
  status?: "ACTIVE" | "CANCELLED";
  /**
   * Summary
   * @minLength 1
   * @maxLength 200
   */
  summary?: string;
  /**
   * Thumbnail
   * @format uri
   */
  thumbnail?: string;
  /**
   * Title
   * @minLength 1
   * @maxLength 200
   */
  title: string;
  /**
   * Url
   * @format uri
   * @maxLength 2000
   */
  url?: string | null;
}

export interface ExtraUserAttendee {
  available_status?: "FREE" | "BUSY" | "UNKNOWN";
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email?: string;
  /** Full name */
  name?: string;
  /** @format url */
  photo?: string;
}

export interface Feedback {
  attachments?: string[] | null;
  /**
   * Created at
   * @format date-time
   */
  created_at?: string;
  /**
   * Description
   * @minLength 1
   * @maxLength 256
   */
  description: string;
  /** ID */
  id?: number;
  /** Is anonymous */
  is_anonymous?: boolean;
  /**
   * Rating
   * @min -2147483648
   * @max 2147483647
   */
  rating?: number | null;
  /** Status */
  status?: "OPEN" | "IN_PROGRESS" | "RESOLVED" | "CLOSED";
  /** Subject */
  subject:
    | "ADMINISTRATIVE_MANAGEMENT"
    | "FACILITIES"
    | "STUDENT_SERVICES"
    | "EXTRACURRICULAR_ACTIVITIES"
    | "CURRICULUM"
    | "FACULTY"
    | "CONSTRUCTION_AND_RENOVATION"
    | "HEALTHCARE"
    | "OTHER";
  /**
   * Updated at
   * @format date-time
   */
  updated_at?: string;
  /** User */
  user?: number | null;
}

export interface FeedbackBody {
  attachments?: string[] | null;
  /**
   * Description
   * @minLength 1
   * @maxLength 256
   */
  description: string;
  /**
   * Rating
   * @min -2147483648
   * @max 2147483647
   */
  rating?: number | null;
  /** Subject */
  subject:
    | "ADMINISTRATIVE_MANAGEMENT"
    | "FACILITIES"
    | "STUDENT_SERVICES"
    | "EXTRACURRICULAR_ACTIVITIES"
    | "CURRICULUM"
    | "FACULTY"
    | "CONSTRUCTION_AND_RENOVATION"
    | "HEALTHCARE"
    | "OTHER";
}

export interface FinanceWebhookInvoiceIssued {
  invoice: {
    /** @format float */
    balance?: number;
    currency_iso_code?: string;
    id?: string;
    name?: string;
  };
  notification_type: 1;
  student_number: string;
}

export interface FinanceWebhookPaymentTransaction {
  currency_iso_code: string;
  invoice_id: string;
  invoice_name: string;
  notification_type: 2;
  /** @format float */
  payment_amount: number;
  student_number: string;
  /** @format float */
  total_payment_amount: number;
  /** @format float */
  transaction_fee_amount: number;
  transaction_id: string;
}

export interface Group {
  /** ID */
  id?: number;
  /**
   * Name
   * @minLength 1
   * @maxLength 150
   */
  name: string;
  /** @uniqueItems true */
  permissions?: number[];
}

export interface GroupEvent {
  /**
   * Created at
   * @format date-time
   */
  created_at?: string;
  /** Created by */
  created_by?: number | null;
  /**
   * Description
   * @maxLength 5000
   */
  description: string;
  /**
   * En description
   * @maxLength 5000
   */
  en_description: string;
  /**
   * En sub title
   * @maxLength 200
   */
  en_subtitle: string;
  /**
   * En title
   * @maxLength 200
   */
  en_title: string;
  /**
   * End at
   * @format date-time
   */
  end_at?: string;
  events: SingleEvent[];
  /** ID */
  id?: number;
  /** Published */
  published?: boolean;
  /** Ratings */
  ratings?: {
    /** Five star */
    five_star?: number;
    /** Four star */
    four_star?: number;
    /** One star */
    one_star?: number;
    /** Three star */
    three_star?: number;
    /** Two star */
    two_star?: number;
  }[];
  /**
   * Start at
   * @format date-time
   */
  start_at?: string;
  /** Status */
  status?: "UPCOMING" | "ONGOING" | "COMPLETED";
  /**
   * Sub Title
   * @maxLength 200
   */
  subtitle: string;
  target_customers?: ReadTargetCustomer[];
  /**
   * Thumbnail
   * @format uri
   */
  thumbnail?: string | null;
  /**
   * Title
   * @maxLength 200
   */
  title: string;
  /** Type */
  type: "wow" | null;
  /**
   * Updated at
   * @format date-time
   */
  updated_at?: string;
}

export interface GroupEventAdd {
  /**
   * Description
   * @maxLength 5000
   */
  description: string;
  /**
   * En description
   * @maxLength 5000
   */
  en_description: string;
  /**
   * En sub title
   * @maxLength 200
   */
  en_subtitle: string;
  /**
   * En title
   * @maxLength 200
   */
  en_title: string;
  events: number[];
  /** Published */
  published?: boolean;
  /**
   * Sub Title
   * @maxLength 200
   */
  subtitle: string;
  target_customers?: number[];
  /**
   * Thumbnail
   * @format uri
   */
  thumbnail: string | null;
  /**
   * Title
   * @maxLength 200
   */
  title: string;
  /** Type */
  type?: "wow";
}

export interface GroupEventMedia {
  /**
   * Created at
   * @format date-time
   */
  created_at?: string;
  /** Group event */
  group_event: number;
  /** ID */
  id?: number;
  /**
   * Thumbnail
   * @format uri
   */
  thumbnail: string | null;
  /**
   * Title
   * @maxLength 200
   */
  title?: string;
  /** Total */
  total?: number;
  /**
   * Updated at
   * @format date-time
   */
  updated_at?: string;
}

export interface GroupEventMediaAdd {
  /**
   * En title
   * @maxLength 200
   */
  en_title: string;
  /** Group event */
  group_event: number;
  images: string[];
  /**
   * Thumbnail
   * @format uri
   */
  thumbnail: string;
  /**
   * Title
   * @maxLength 200
   */
  title: string;
}

export interface GroupEventRating {
  /**
   * Comment
   * @maxLength 2000
   */
  comment?: string | null;
  /**
   * Created at
   * @format date-time
   */
  created_at?: string;
  /** Group event */
  group_event: number;
  /** ID */
  id?: number;
  /**
   * Rating
   * @min 0
   * @max 2147483647
   */
  rating: number;
  /**
   * Updated at
   * @format date-time
   */
  updated_at?: string;
  /** User */
  user: number;
}

export interface GroupEventRatingBody {
  /**
   * Comment
   * @minLength 1
   */
  comment?: string;
  /** Rating */
  rating: number;
}

export interface Honor {
  /**
   * Award type
   * @maxLength 255
   */
  award_type?: string | null;
  /**
   * College
   * @maxLength 255
   */
  college?: string | null;
  /** ID */
  id?: number;
  /**
   * Program
   * @maxLength 255
   */
  program?: string | null;
  /**
   * Term
   * @maxLength 255
   */
  term?: string | null;
}

export interface ImportTargetCustomerResponse {
  /**
   * File url
   * @minLength 1
   */
  file_url: string | null;
  /** Target customer id */
  target_customer_id: number | null;
}

export interface Invoice {
  /**
   * Balance
   * @format float
   */
  balance?: number;
  /**
   * Created date
   * @format date-time
   */
  created_date?: string;
  /** Currency */
  currency?: string;
  /**
   * Deduction amount
   * @format float
   */
  deduction_amount?: number;
  /**
   * Due date
   * @format date-time
   */
  due_date?: string;
  /** ID */
  id: string;
  /** Is tuition */
  is_tuition?: boolean;
  /** Name */
  name?: string;
  /**
   * Paid amount
   * @format float
   */
  paid_amount?: number;
  /** Payable */
  payable?: boolean;
  /** Status */
  status: "Paid" | "Unpaid";
  /** Term */
  term?: string;
  /**
   * Total amount
   * @format float
   */
  total_amount?: number;
}

export interface InvoiceDetail {
  /** Admission application id */
  app_id?: string;
  /** Admission application name */
  app_name?: string;
  /**
   * Balance
   * @format float
   */
  balance?: number;
  /**
   * Created date
   * @format date-time
   */
  created_date?: string;
  /** Currency */
  currency?: string;
  /**
   * Deduction amount
   * @format float
   */
  deduction_amount?: number;
  /**
   * Due date
   * @format date-time
   */
  due_date?: string;
  /** ID */
  id: string;
  installments?: InvoiceInstallment[];
  /** Is tuition */
  is_tuition?: boolean;
  /** Name */
  name?: string;
  /**
   * Paid amount
   * @format float
   */
  paid_amount?: number;
  /** Payable */
  payable?: boolean;
  /** Status */
  status: "Paid" | "Unpaid";
  /** Term */
  term?: string;
  /**
   * Total amount
   * @format float
   */
  total_amount?: number;
  transaction?: {
    items?: TransactionItem[];
    payments?: {
      /** Currency */
      currency?: string;
      items?: PaymentTransaction[];
      /**
       * Total payment
       * @format float
       */
      total_payment?: number;
    };
  };
}

export interface InvoiceInstallment {
  /**
   * Amount
   * @format float
   */
  amount?: number;
  /**
   * Due date
   * @format date-time
   */
  due_date?: string;
  /** Status */
  status?: boolean;
}

export type LikeEventRequest = object;

export interface LikeEventResponse {
  /** Ok */
  ok: boolean;
}

export interface MySurveyAnswer {
  /** @format datetime */
  created_at: string;
  /** Answer ID */
  id: number;
  survey_data: {
    description?: string;
    /** Survey ID */
    id?: number;
    questions?: {
      /** Indicates if the question type is choices */
      allow_custom?: boolean;
      answer?: string[];
      /** Indicates if the question type is text */
      format?: "EMAIL" | "URL" | null;
      /** Question ID */
      id?: number;
      /** Indicates if the question type is choices */
      max_choices?: number | null;
      /** List of options for the question (if applicable) */
      options?: {
        /** label of the option */
        label?: string;
        order?: number;
        /** value of the option */
        value?: string;
      }[];
      order?: number;
      /** Type of the question (date, datetime, choices, number) */
      question_type?: "DATE" | "DATETIME" | "CHOICES" | "RATE" | "NUMBER";
      /** Indicates if the question is required */
      required?: boolean;
      /** Text of the question */
      title?: string;
    }[];
    /** Title of the survey */
    title?: string;
  };
  /** @format datetime */
  updated_at: string;
}

export interface MySurveyAnswerList {
  /** @format datetime */
  created_at: string;
  /** Answer ID */
  id: number;
  survey: {
    /** @format datetime */
    created_at?: string;
    description?: string;
    /** Survey ID */
    id?: number;
    /** Title of the survey */
    title?: string;
    /** @format datetime */
    updated_at?: string;
  };
  /** @format datetime */
  updated_at: string;
}

export interface NewCategory {
  /**
   * Created at
   * @format date-time
   */
  created_at?: string;
  /**
   * En name
   * @maxLength 255
   */
  en_name?: string | null;
  /**
   * External id
   * @min -2147483648
   * @max 2147483647
   */
  external_id?: number | null;
  /**
   * Icon
   * @format uri
   */
  icon?: string | null;
  /** ID */
  id?: number;
  /**
   * Name
   * @minLength 1
   * @maxLength 255
   */
  name: string;
  /** Parent */
  parent?: number | null;
  /**
   * Updated at
   * @format date-time
   */
  updated_at?: string;
}

export interface News {
  categories?: NewCategory[];
  /**
   * Content
   * @maxLength 500000
   */
  content?: string | null;
  /**
   * Created at
   * @format date-time
   */
  created_at?: string;
  created_by?: UserBasicInfo;
  /**
   * En content
   * @maxLength 500000
   */
  en_content?: string | null;
  /**
   * En External id
   * @min -2147483648
   * @max 2147483647
   */
  en_external_id?: number | null;
  /**
   * En title
   * @maxLength 500
   */
  en_title?: string | null;
  /**
   * En url
   * @format uri
   * @maxLength 2000
   */
  en_url?: string | null;
  /**
   * External id
   * @min -2147483648
   * @max 2147483647
   */
  external_id?: number | null;
  /**
   * External thumbnail url
   * @format uri
   * @maxLength 2000
   */
  external_thumbnail_url?: string | null;
  /** ID */
  id?: number;
  /** Published */
  published?: boolean;
  /**
   * Thumbnail
   * @format uri
   */
  thumbnail?: string | null;
  /**
   * Title
   * @maxLength 500
   */
  title?: string | null;
  /**
   * Updated at
   * @format date-time
   */
  updated_at?: string;
  /**
   * Url
   * @format uri
   * @maxLength 2000
   */
  url?: string | null;
}

export interface NewsCreate {
  /**
   * Category IDs
   * @uniqueItems true
   */
  categories?: number[];
  /**
   * Content
   * @maxLength 500000
   */
  content?: string | null;
  /**
   * Created at
   * @format date-time
   */
  created_at?: string;
  /**
   * En content
   * @maxLength 500000
   */
  en_content?: string | null;
  /**
   * En title
   * @maxLength 500
   */
  en_title?: string | null;
  /**
   * En url
   * @format uri
   * @maxLength 2000
   */
  en_url?: string | null;
  /**
   * External id
   * @min -2147483648
   * @max 2147483647
   */
  external_id?: number | null;
  /**
   * External thumbnail url
   * @format uri
   * @maxLength 2000
   */
  external_thumbnail_url?: string | null;
  /** ID */
  id?: number;
  /** Published */
  published?: boolean;
  /**
   * Thumbnail
   * @format uri
   * @maxLength 2000
   */
  thumbnail?: string;
  /**
   * Title
   * @maxLength 500
   */
  title?: string | null;
  /**
   * Updated at
   * @format date-time
   */
  updated_at?: string;
  /**
   * Url
   * @format uri
   * @maxLength 2000
   */
  url?: string | null;
}

export interface NewsList {
  categories?: NewCategory[];
  /**
   * Created at
   * @format date-time
   */
  created_at?: string;
  created_by?: UserBasicInfo;
  /**
   * En External id
   * @min -2147483648
   * @max 2147483647
   */
  en_external_id?: number | null;
  /**
   * En title
   * @maxLength 500
   */
  en_title?: string | null;
  /**
   * En url
   * @format uri
   * @maxLength 2000
   */
  en_url?: string | null;
  /**
   * External id
   * @min -2147483648
   * @max 2147483647
   */
  external_id?: number | null;
  /**
   * External thumbnail url
   * @format uri
   * @maxLength 2000
   */
  external_thumbnail_url?: string | null;
  /** ID */
  id?: number;
  /** Published */
  published?: boolean;
  /**
   * Thumbnail
   * @format uri
   */
  thumbnail?: string | null;
  /**
   * Title
   * @maxLength 500
   */
  title?: string | null;
  /**
   * Updated at
   * @format date-time
   */
  updated_at?: string;
  /**
   * Url
   * @format uri
   * @maxLength 2000
   */
  url?: string | null;
}

export interface Notification {
  /**
   * Action
   * @maxLength 255
   */
  action?: string | null;
  /** Action type */
  action_type?: "Internal Link" | "External Link" | "Feature" | null;
  /** Content */
  content?: string | null;
  /**
   * Created at
   * @format date-time
   */
  created_at?: string | null;
  /** Created by */
  created_by?: number | null;
  /** Data */
  data?: object | null;
  /**
   * En action
   * @maxLength 255
   */
  en_action?: string | null;
  /** En content */
  en_content?: string | null;
  /**
   * En title
   * @maxLength 255
   */
  en_title?: string | null;
  /** ID */
  id?: number;
  /** Is read */
  is_read?: boolean;
  /**
   * Name
   * @minLength 1
   * @maxLength 100
   */
  name: string;
  /**
   * Title
   * @maxLength 255
   */
  title?: string | null;
  type?: NotificationType;
  /**
   * Updated at
   * @format date-time
   */
  updated_at?: string | null;
  user?: UserNotification;
}

export interface NotificationTemplate {
  /**
   * Action
   * @maxLength 255
   */
  action?: string | null;
  /** Action type */
  action_type?: "Internal Link" | "External Link" | "Feature" | null;
  /** Content */
  content: string;
  /**
   * Created at
   * @format date-time
   */
  created_at?: string | null;
  created_by?: UserBasicInfo;
  /** Data */
  data?: object | null;
  day_of_month?: number | null;
  /**
   * En action
   * @maxLength 255
   */
  en_action?: string | null;
  /** En content */
  en_content: string;
  /**
   * En title
   * @maxLength 255
   */
  en_title: string;
  /**
   * End date
   * @format date
   */
  end_date?: string | null;
  /** ID */
  id?: number;
  /** Active */
  is_active?: boolean;
  month?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | null;
  month_week?: "FIRST" | "SECOND" | "THIRD" | "FOURTH" | "LAST" | null;
  /**
   * Name
   * @minLength 1
   * @maxLength 100
   */
  name?: string;
  /** Recurrence */
  recurrence?: "NEVER" | "DAILY" | "WEEKLY" | "MONTHLY" | "YEARLY";
  related_event?: EventReadInfo;
  repeat_by?: "SPECIFIC_DAY" | "RELATIVE_DAY" | null;
  /** Repeat every */
  repeat_every?: number;
  /**
   * Start date
   * @format date
   */
  start_date?: string;
  /** Status */
  status?: "Created" | "Started" | "Processing" | "Partial Failure" | "Completed" | "Failed";
  target_customer?: ReadTargetCustomer;
  /**
   * Time
   * @format time
   */
  time?: string;
  /**
   * Title
   * @maxLength 255
   */
  title: string;
  /** To all user */
  to_all_user?: boolean;
  type: NotificationType;
  /**
   * Updated at
   * @format date-time
   */
  updated_at?: string | null;
  weekday?: "MONDAY" | "TUESDAY" | "WEDNESDAY" | "THURSDAY" | "FRIDAY" | "SATURDAY" | "SUNDAY" | null;
  weekdays?: ("MONDAY" | "TUESDAY" | "WEDNESDAY" | "THURSDAY" | "FRIDAY" | "SATURDAY" | "SUNDAY")[] | null;
}

export interface NotificationType {
  /**
   * Created at
   * @format date-time
   */
  created_at?: string | null;
  /** Created by */
  created_by?: number | null;
  /**
   * En name
   * @minLength 1
   * @maxLength 50
   */
  en_name: string;
  /**
   * Icon
   * @format uri
   */
  icon?: string | null;
  /** ID */
  id?: number;
  /**
   * Name
   * @minLength 1
   * @maxLength 50
   */
  name: string;
  /**
   * Updated at
   * @format date-time
   */
  updated_at?: string | null;
}

export interface NotificationTypeList {
  /**
   * En name
   * @minLength 1
   * @maxLength 50
   */
  en_name: string;
  /** ID */
  id?: number;
  /**
   * Name
   * @minLength 1
   * @maxLength 50
   */
  name: string;
}

export interface OasisProgram {
  academic_integrity_test_completed?: boolean;
  career_skill_completed: boolean;
  code_of_conduct_test_completed?: boolean;
  college_skill_completed: boolean;
  community_service?: boolean;
  id: number;
  idp_completed: boolean;
  pre_graduation_cv?: string | null;
  resume_submitted: boolean;
  student_club?: boolean;
  university_award?: boolean;
}

export interface OasisProgramProfile {
  cohort?: string;
  id: number;
  name?: string;
  oasis_program?: OasisProgram;
  student_id?: string;
  student_number?: string;
}

export interface OrientationTest {
  id: number;
  orientation_tests?: {
    grade?: string;
    id?: number;
    passed?: boolean;
    point?: number;
    type?: "ACADEMIC_INTEGRITY_TEST" | "CODE_OF_CONDUCT_TEST";
  }[];
  personal_profile: {
    cohort?: string;
    id: number;
    name?: string;
    student_id?: string;
    student_number?: string;
  };
}

export interface OutlookEvent {
  counselling_id?: string | null;
  description?: string;
  /**
   * End time of the event.
   * @format date-time
   */
  end?: string;
  /** Unique identifier for the event. */
  id?: string;
  /** Whether the event lasts for the entire day. */
  is_all_day?: boolean;
  is_repeat?: boolean;
  location?: {
    /**
     * Online meeting url
     * @format url
     */
    link?: string;
    /** Display name for the event location. */
    name?: string;
  };
  participants?: {
    /** @format email */
    email?: string;
    /** is organizer of this event */
    is_organizer?: boolean;
    /** Name of attendee */
    name?: string;
    /** @format url */
    photo?: string;
    status?: "ACCEPTED" | "DECLINED" | "TENTATIVE" | "NOT_RESPONDED";
  }[];
  room_status?: "ACCEPTED" | "DECLINED" | "TENTATIVE" | "NOT_RESPONDED";
  /**
   * Start time of the event.
   * @format date-time
   */
  start?: string;
  status?: "ACCEPTED" | "DECLINED" | "TENTATIVE" | "NOT_RESPONDED";
  /** Subject or title of the event. */
  title?: string;
  type?: "NORMAL" | "COUNSELLING" | "FITNESS";
}

export interface OutlookEventBooking {
  attendees?: string[];
  description?: string;
  /**
   * End time of the event
   * @format date-time
   */
  end: string;
  /**
   * End date of the repeat pattern.
   * @format date
   */
  end_repeat?: string;
  /**
   * Whether the event lasts for the entire day.
   * @default false
   */
  is_all_day: boolean;
  location?: {
    /**
     * Email of room
     * @format email
     */
    email?: string;
    /** Whether the meeting is an online meeting */
    is_online?: boolean;
    /** Display name for the event location. */
    name?: string;
  };
  repeat_type?: "NONE" | "DAILY" | "WEEKDAY" | "WEEKLY" | "MONTHLY" | "YEARLY";
  /**
   * Start time of the event.
   * @format date-time
   */
  start: string;
  /** Subject or title of the event. */
  title: string;
  type?: "NORMAL" | "COUNSELLING" | "FITNESS";
}

export interface OutlookEventCancel {
  /** update this event, update following event, update all series. */
  action_type: "SINGLE" | "ALL_SERIES";
  /** Cancel messages */
  comment?: string;
}

export interface OutlookEventResponse {
  /** Cancel messages */
  comment?: string;
  /** Is send notification to organizer */
  send_response?: boolean;
}

export interface OutlookEventUpdate {
  /** update this event, update following event, update all series. */
  action_type: "SINGLE" | "ALL_SERIES";
  attendees?: string[];
  description?: string;
  /**
   * End time of the event
   * @format date-time
   */
  end: string;
  /**
   * Whether the event lasts for the entire day.
   * @default false
   */
  is_all_day: boolean;
  location?: {
    /**
     * Email of room
     * @format email
     */
    email?: string;
    /** Whether the meeting is an online meeting */
    is_online?: boolean;
    /** Display name for the event location. */
    name?: string;
  };
  /**
   * Start time of the event.
   * @format date-time
   */
  start: string;
  /** Subject or title of the event. */
  title: string;
}

export interface PasswordLoginBody {
  /**
   * Email
   * @format email
   * @minLength 1
   */
  email: string;
  /**
   * Password
   * @minLength 8
   */
  password: string;
}

export interface PasswordLoginResponse {
  /**
   * Access token
   * @minLength 1
   */
  access_token: string;
  /**
   * Refresh token
   * @minLength 1
   */
  refresh_token: string;
}

export interface PaymentMethod {
  /** Card list */
  card_list?: string;
  /** Currency iso code */
  currency_iso_code?: string;
  /**
   * Display order
   * @format float
   */
  display_order?: number;
  /** ID */
  id: string;
  /** Is international */
  is_international?: boolean;
  /**
   * Logo
   * @format base64
   */
  logo?: string | null;
  /** Message */
  message?: string;
  /** Name */
  name?: string;
  /**
   * Tran fee rate
   * @format float
   */
  tran_fee_rate?: number;
}

export interface PaymentRequestUrlRequest {
  app_id: string;
  currency: string;
  debit_transactions: {
    amount?: number;
    debit_tran_id?: string;
  }[];
  /** @format float */
  final_payment_amount: number;
  invoice_id: string;
  payment_method?: {
    card_list?: string;
    id?: string;
    is_international?: boolean;
    message?: string;
    name?: string;
    /** @format float */
    tran_fee_rate?: number;
  };
  /** @format float */
  total_payment_amount: number;
  /** @format float */
  tran_fee_amount: number;
}

export interface PaymentReturnUrl {
  /** @format float */
  amount: number;
  currency: string;
  /** @format float */
  invoice_amount?: number;
  /** @format date-time */
  invoice_date?: string;
  invoice_name: string;
  /** @format float */
  paid_amount?: number;
  reference_id: string;
  status: boolean;
  student_number: string;
  /** @format float */
  unpaid_amount?: number;
}

export interface PaymentTransaction {
  /** Currency */
  currency?: string;
  /** Description */
  description?: string;
  /**
   * Fee amount
   * @format float
   */
  fee_amount?: number;
  /** ID */
  id?: string;
  /** Invoice name */
  invoice_name?: string;
  /** Name */
  name?: string;
  /**
   * Paid amount
   * @format float
   */
  paid_amount?: number;
  /**
   * Payment date
   * @format date-time
   */
  payment_date?: string;
  /** Payment method */
  payment_method?:
    | "Visa, Master, JCB Cards (issued in Vietnam)"
    | "Visa, Master, JCB Cards (International)"
    | "Domestic ATM Cards"
    | "Mobile Applications (Mobile Banking, Shopee Pay, Zalo Pay and others)"
    | "MoMo E-Wallet";
  /** Payment mode */
  payment_mode?: "Online" | "Offline";
  /** Reference id */
  reference_id?: string;
  /** Term */
  term?: string;
}

export interface PersonalProfile {
  /**
   * Address
   * @maxLength 255
   */
  address?: string | null;
  /**
   * First name
   * @maxLength 255
   */
  first_name?: string | null;
  /** ID */
  id?: number;
  /**
   * Id card number
   * @maxLength 255
   */
  id_card_number?: string | null;
  /**
   * Last name
   * @maxLength 255
   */
  last_name?: string | null;
  /** @uniqueItems true */
  managers?: number[];
  /**
   * Nationality
   * @maxLength 255
   */
  nationality?: string | null;
  /**
   * Personal email
   * @format email
   * @maxLength 255
   */
  personal_email?: string | null;
  /**
   * Phone number
   * @maxLength 255
   */
  phone_number?: string | null;
  /**
   * Photo
   * @format base64
   * @maxLength 2000
   */
  photo?: string | null;
  /**
   * Student number
   * @maxLength 255
   */
  student_number?: string | null;
  /**
   * University email
   * @format email
   * @maxLength 255
   */
  university_email?: string | null;
  /** User */
  user: number;
}

export interface ReadTargetCustomer {
  /** ID */
  id?: number;
  /**
   * Name
   * @minLength 1
   * @maxLength 100
   */
  name: string;
}

export interface RefreshTokenBody {
  /**
   * Refresh token
   * @minLength 1
   */
  refresh_token: string;
}

export interface RefreshTokenResponse {
  /**
   * Access token
   * @minLength 1
   */
  access_token: string;
  /**
   * Refresh token
   * @minLength 1
   */
  refresh_token: string;
}

export interface RegisterBody {
  /**
   * Email
   * @format email
   * @minLength 1
   */
  email: string;
  /**
   * First name
   * @minLength 1
   */
  first_name?: string;
  /**
   * Last name
   * @minLength 1
   */
  last_name?: string;
  /**
   * Password
   * @minLength 8
   */
  password: string;
}

export interface SelfAssignments {
  /** Assessment type */
  assessment_type: "IDP" | "Self-Nomination";
  /**
   * Detail link
   * @format uri
   * @maxLength 255
   */
  detail_link?: string | null;
  /** ID */
  id?: string;
  /**
   * Idp id
   * @maxLength 255
   */
  idp_id?: string | null;
  /**
   * Name of award
   * @maxLength 255
   */
  name?: string | null;
  /**
   * Status
   * @maxLength 255
   */
  status?: string | null;
  term?: string;
  /**
   * Verification status
   * @maxLength 255
   */
  verification_status?: string | null;
}

export interface Semester {
  /** Fall credit earned */
  fall_credit_earned?: number | null;
  /** Fall cumulative gpa */
  fall_cumulative_gpa?: number | null;
  /** Fall curriculum gpa */
  fall_curriculum_gpa?: number | null;
  /**
   * Fall sap status
   * @minLength 1
   */
  fall_sap_status?: string | null;
  /**
   * Fall semester name
   * @minLength 1
   */
  fall_semester_name?: string | null;
  /** Spring credit earned */
  spring_credit_earned?: number | null;
  /** Spring cumulative gpa */
  spring_cumulative_gpa?: number | null;
  /** Spring curriculum gpa */
  spring_curriculum_gpa?: number | null;
  /**
   * Spring sap status
   * @minLength 1
   */
  spring_sap_status?: string | null;
  /**
   * Spring semester name
   * @minLength 1
   */
  spring_semester_name?: string | null;
  /** Spring credit earned */
  summer_credit_earned?: number | null;
  /** Spring cumulative gpa */
  summer_cumulative_gpa?: number | null;
  /** Spring curriculum gpa */
  summer_curriculum_gpa?: number | null;
  /**
   * Spring sap status
   * @minLength 1
   */
  summer_sap_status?: string | null;
  /**
   * Spring semester name
   * @minLength 1
   */
  summer_semester_name?: string | null;
}

export interface SingleEvent {
  /**
   * Label
   * @minLength 1
   * @maxLength 200
   */
  label: string;
  /** Value */
  value: number;
}

export interface Skill {
  cohort: number;
  /** @format datetime */
  created_at?: string;
  description?: string;
  en_name?: string | null;
  id: number;
  name?: string | null;
  /** @format datetime */
  updated_at?: string;
}

export interface SkillAdd {
  cohort: number;
  description?: string;
  en_name?: string | null;
  name?: string | null;
}

export interface SkillReadiness {
  completed?: boolean;
  workshops?: {
    date?: string;
    id?: number;
    name?: string;
    type?: "COLLEGE_READINESS" | "CAREER_READINESS";
  }[];
}

export interface Speaker {
  /**
   * Bio
   * @maxLength 2000
   */
  bio?: string | null;
  /**
   * Company
   * @maxLength 200
   */
  company?: string | null;
  /**
   * Email
   * @format email
   * @maxLength 254
   */
  email?: string | null;
  /**
   * Job title
   * @maxLength 200
   */
  en_job_title?: string | null;
  /** ID */
  id?: number;
  /**
   * Job title
   * @maxLength 200
   */
  job_title?: string | null;
  /**
   * Name
   * @minLength 1
   * @maxLength 200
   */
  name: string;
  /**
   * Phone number
   * @maxLength 15
   */
  phone_number?: string | null;
  /**
   * Photo
   * @format uri
   */
  photo?: string | null;
}

export interface SpeakerList {
  /**
   * Company
   * @maxLength 200
   */
  company?: string | null;
  /**
   * Email
   * @format email
   * @maxLength 254
   */
  email?: string | null;
  /**
   * Job title
   * @maxLength 200
   */
  en_job_title?: string | null;
  /** ID */
  id?: number;
  /**
   * Job title
   * @maxLength 200
   */
  job_title?: string | null;
  /**
   * Name
   * @minLength 1
   * @maxLength 200
   */
  name: string;
  /**
   * Phone number
   * @maxLength 15
   */
  phone_number?: string | null;
  /**
   * Photo
   * @format uri
   */
  photo?: string | null;
}

export interface StudentProject {
  /**
   * Award
   * @maxLength 255
   */
  award?: string | null;
  /** Csl hour completed */
  csl_hour_completed?: number | null;
  /** Csl hour required */
  csl_hour_required?: number | null;
  /**
   * End date
   * @format date
   */
  end_date?: string | null;
  /**
   * Endorsed user
   * @maxLength 255
   */
  endorsed_user?: string | null;
  /**
   * Enrolled program
   * @maxLength 255
   */
  enrolled_program?: string | null;
  /** Excel score */
  excel_score?:
    | "Empathy"
    | "Exceptional Capability"
    | "Creativity"
    | "Entrepreneurial Mindset"
    | "Leadership Spirit"
    | null;
  /** Highlighted achivements */
  highlighted_achivements?: string | null;
  /**
   * Host country
   * @maxLength 255
   */
  host_country?: string | null;
  /**
   * Host university
   * @maxLength 255
   */
  host_university?: string | null;
  /** ID */
  id?: string;
  /**
   * Industry name
   * @maxLength 255
   */
  industry_name?: string | null;
  /**
   * Name
   * @maxLength 255
   */
  name?: string | null;
  /**
   * Position
   * @maxLength 255
   */
  position?: string | null;
  /** Research questions */
  research_questions?: string | null;
  /** Self reflection */
  self_reflection?: string | null;
  /**
   * Semester
   * @maxLength 255
   */
  semester?: string | null;
  /**
   * Start date
   * @format date
   */
  start_date?: string | null;
  /**
   * Status
   * @maxLength 255
   */
  status?: string | null;
  /** Summary */
  summary?: string | null;
  /** Supervisor comment */
  supervisor_comment?: string | null;
  /**
   * Supervisor email
   * @format email
   * @maxLength 255
   */
  supervisor_email?: string | null;
  /**
   * Supervisor mobile
   * @maxLength 255
   */
  supervisor_mobile?: string | null;
  /**
   * Supervisor name
   * @maxLength 255
   */
  supervisor_name?: string | null;
  /**
   * Supervisor position
   * @maxLength 255
   */
  supervisor_position?: string | null;
  /** Total funding */
  total_funding?: number | null;
  /** Project type */
  type:
    | "Community Service"
    | "Internship/Work Experience"
    | "Research Experience"
    | "Entrepreneurial Experience"
    | "Global Experience"
    | "Voluntary Work"
    | "Club and Association";
  /**
   * Verification status
   * @maxLength 255
   */
  verification_status?: string | null;
  /**
   * Work experience attachments
   * @format uri
   */
  work_experience_attachments?: string | null;
}

export interface StudentService {
  /**
   * Created at
   * @format date-time
   */
  created_at?: string;
  /**
   * Description
   * @maxLength 5000
   */
  description?: string;
  /**
   * Email
   * @format email
   * @maxLength 254
   */
  email?: string | null;
  /** ID */
  id?: number;
  /** Lat */
  lat?: number | null;
  /** Location */
  location?: string | null;
  /** Long */
  long?: number | null;
  /**
   * Phone number
   * @maxLength 50
   */
  phone_number?: string | null;
  /**
   * Social link
   * @maxLength 200
   */
  social_link?: string | null;
  /**
   * Social name
   * @maxLength 200
   */
  social_name?: string | null;
  /**
   * Thumbnail
   * @format uri
   */
  thumbnail: string | null;
  /**
   * Title
   * @maxLength 200
   */
  title?: string;
  /**
   * Updated at
   * @format date-time
   */
  updated_at?: string;
}

export interface SupportRequest {
  attachments?: string[];
  case_owner_name?: string;
  case_type?: string;
  /**
   * Created at
   * @format date-time
   */
  created_at?: string;
  created_by?: UserBasicInfo;
  /**
   * Description
   * @minLength 1
   */
  description: string;
  /** ID */
  id?: number;
  record_type_name?: string;
  record_type_object?: {
    case_type?: {
      name?: string;
      value?: string;
    }[];
    id?: number;
    name?: string;
    value?: string;
  };
  status?: "New" | "In-Progress" | "Resolved" | "Closed";
  /**
   * Title
   * @minLength 1
   * @maxLength 255
   */
  subject: string;
  /**
   * Updated at
   * @format date-time
   */
  updated_at?: string;
}

export interface SupportRequestAdd {
  /** @default [] */
  attachments?: string[];
  case_owner_name: string;
  case_type?: string;
  description: string;
  /** @default "Low" */
  priority?: string;
  record_type_name: string;
  request_type: "Support" | "Complaint";
  subject: string;
}

export interface SurveyAnswer {
  /** @format datetime */
  created_at: string;
  /** Answer ID */
  id: number;
  survey_data: {
    /** @format datetime */
    created_at?: string;
    description?: string;
    /** En-description of the survey */
    en_description?: string;
    /** En title of the survey */
    en_title?: string;
    /** Survey ID */
    id?: number;
    published?: boolean;
    questions?: {
      /** Indicates if the question type is choices */
      allow_custom?: boolean;
      answer?: string[];
      /** En-title of the question */
      en_title?: string;
      /** Indicates if the question type is text */
      format?: "EMAIL" | "URL" | null;
      /** Question ID */
      id?: number;
      /** Indicates if the question type is choices */
      max_choices?: number | null;
      /** List of options for the question (if applicable) */
      options?: {
        /** en-label of the option */
        en_label?: string;
        /** label of the option */
        label?: string;
        order?: number;
        /** value of the option */
        value?: string;
      }[];
      order?: number;
      /** Type of the question (date, datetime, choices, number) */
      question_type?: "DATE" | "DATETIME" | "CHOICES" | "RATE" | "NUMBER";
      /** Indicates if the question is required */
      required?: boolean;
      /** Text of the question */
      title?: string;
    }[];
    service_type?: "COUNSELLING" | null;
    /** Title of the survey */
    title?: string;
    /** @format datetime */
    updated_at?: string;
  };
  /** @format datetime */
  updated_at: string;
  user: UserBasicInfo;
}

export interface SurveyAnswerList {
  /** @format datetime */
  created_at: string;
  /** Answer ID */
  id: number;
  survey: {
    /** @format datetime */
    created_at?: string;
    description?: string;
    /** Survey ID */
    id?: number;
    /** Title of the survey */
    title?: string;
    /** @format datetime */
    updated_at?: string;
  };
  /** @format datetime */
  updated_at: string;
  user: UserBasicInfo;
}

export interface SurveyForm {
  description?: string;
  /** Survey ID */
  id: number;
  questions?: {
    /** Indicates if the question type is choices */
    allow_custom?: boolean;
    /** Indicates if the question type is text */
    format?: "EMAIL" | "URL" | null;
    /** Question ID */
    id?: number;
    /** Indicates if the question type is choices */
    max_choices?: number | null;
    /** List of options for the question (if applicable) */
    options?: {
      /** label of the option */
      label?: string;
      order?: number;
      /** value of the option */
      value?: string;
    }[];
    order?: number;
    /** Type of the question (date, datetime, text, choices, number) */
    question_type?: "DATE" | "DATETIME" | "TEXT" | "CHOICES" | "RATE" | "NUMBER";
    /** Indicates if the question is required */
    required?: boolean;
    /** Text of the question */
    title?: string;
  }[];
  /** Title of the survey */
  title?: string;
}

export interface SurveyFormAdmin {
  /** @format datetime */
  created_at?: string;
  description?: string;
  en_description?: string;
  /** En-Title of the survey */
  en_title?: string;
  /** Survey ID */
  id: number;
  published?: boolean;
  questions?: {
    /** Indicates if the question type is choices */
    allow_custom?: boolean;
    /** En-title of the question */
    en_title?: string;
    /** Indicates if the question type is text */
    format?: "EMAIL" | "URL" | null;
    /** Question ID */
    id?: number;
    /** Indicates if the question type is choices */
    max_choices?: number | null;
    /** List of options for the question (if applicable) */
    options?: {
      /** en-label of the option */
      en_label?: string;
      /** label of the option */
      label?: string;
      order?: number;
      /** value of the option */
      value?: string;
    }[];
    order?: number;
    /** Type of the question (date, datetime, text, choices, number) */
    question_type?: "DATE" | "DATETIME" | "TEXT" | "CHOICES" | "RATE" | "NUMBER";
    /** Indicates if the question is required */
    required?: boolean;
    /** Title of the question */
    title?: string;
  }[];
  service_type?: "COUNSELLING" | null;
  /** Title of the survey */
  title?: string;
  /** @format datetime */
  updated_at?: string;
}

export interface SurveyFormAdminList {
  /** @format datetime */
  created_at?: string;
  description?: string;
  en_description?: string;
  /** En-Title of the survey */
  en_title?: string;
  /** Survey ID */
  id: number;
  published?: boolean;
  service_type?: "COUNSELLING" | null;
  /** Title of the survey */
  title?: string;
  /** @format datetime */
  updated_at?: string;
}

export interface SurveyFormCreate {
  description?: string;
  en_description?: string;
  /** En-Title of the survey */
  en_title: string;
  published?: boolean;
  questions?: {
    /** Indicates if the question type is choices */
    allow_custom?: boolean;
    /** En-title of the question */
    en_title: string;
    /** Indicates if the question type is text */
    format?: "EMAIL" | "URL" | null;
    /** Indicates if the question type is choices */
    max_choices?: number | null;
    /** List of options for the question (if applicable) */
    options?: {
      /** en-label of the option */
      en_label: string;
      /** label of the option */
      label: string;
      order?: number;
      /** value of the option */
      value: string;
    }[];
    order?: number;
    /** Type of the question (date, datetime, text, choices, number) */
    question_type: "DATE" | "DATETIME" | "TEXT" | "CHOICES" | "RATE" | "NUMBER";
    /** Indicates if the question is required */
    required?: boolean;
    /** Title of the question */
    title: string;
  }[];
  service_type?: "COUNSELLING" | null;
  /** Title of the survey */
  title: string;
}

export interface SurveyFormUpdate {
  description?: string;
  en_description?: string;
  /** En-Title of the survey */
  en_title?: string;
  published?: boolean;
  questions?: {
    /** Indicates if the question type is choices */
    allow_custom?: boolean;
    /** En-title of the question */
    en_title?: string;
    /** Indicates if the question type is text */
    format?: "EMAIL" | "URL" | null;
    /** question id if update or null if create */
    id?: number;
    /** Indicates if the question type is choices */
    max_choices?: number | null;
    /** List of options for the question (if applicable) */
    options?: {
      /** en-label of the option */
      en_label: string;
      /** label of the option */
      label: string;
      order?: number;
      /** value of the option */
      value: string;
    }[];
    order?: number;
    /** Type of the question (date, datetime, text, choices, number) */
    question_type?: "DATE" | "DATETIME" | "TEXT" | "CHOICES" | "RATE" | "NUMBER";
    /** Indicates if the question is required */
    required?: boolean;
    /** Title of the question */
    title?: string;
  }[];
  service_type?: "COUNSELLING" | null;
  /** Title of the survey */
  title?: string;
}

export interface TargetCustomer {
  /**
   * Cohort
   * @minLength 1
   * @maxLength 255
   */
  cohort?: string;
  /** Colleges */
  colleges?: string[];
  /**
   * Created at
   * @format date-time
   */
  created_at?: string | null;
  created_by?: UserBasicInfo;
  /** ID */
  id?: number;
  /**
   * Name
   * @minLength 1
   * @maxLength 100
   */
  name: string;
  /** Roles */
  roles?: ("Staff" | "Student")[];
  /**
   * Updated at
   * @format date-time
   */
  updated_at?: string | null;
  /** @uniqueItems true */
  users?: UserBasicInfo[];
}

export interface TargetCustomerCohorts {
  /**
   * Label
   * @minLength 1
   * @maxLength 255
   */
  label: string;
}

export interface TargetCustomerColleges {
  /**
   * Label
   * @minLength 1
   * @maxLength 255
   */
  label: string;
}

export interface TransactionItem {
  /** Currency */
  currency?: string;
  /** Description */
  description?: string;
  /** ID */
  id?: string;
  /** Is debit */
  is_debit?: boolean;
  /**
   * Paid amount
   * @format float
   */
  paid_amount?: number;
  /**
   * Total amount
   * @format float
   */
  total_amount?: number;
  /**
   * Unpaid amount
   * @format float
   */
  unpaid_amount?: number;
}

export interface UnreadNotificationResponse {
  /** Unread count */
  unread_count: number;
}

export interface UpdateNotificationTemplateRequest {
  /**
   * Action
   * @maxLength 255
   */
  action?: string | null;
  /** Action type */
  action_type?: "Internal Link" | "External Link" | "Feature" | null;
  /** Content */
  content: string;
  /** Data */
  data?: object | null;
  /**
   * En action
   * @maxLength 255
   */
  en_action?: string | null;
  /** En content */
  en_content: string;
  /**
   * En title
   * @maxLength 255
   */
  en_title: string;
  /**
   * Name
   * @minLength 1
   * @maxLength 100
   */
  name?: string | null;
  /** Related event */
  related_event?: number | null;
  /**
   * Start time
   * @format date-time
   */
  start_time?: string | null;
  /**
   * Status
   * @default "Created"
   */
  status?: "Created" | "Started" | "Processing" | "Partial Failure" | "Completed" | "Failed";
  /** Target customer id */
  target_customer?: number | null;
  /**
   * Title
   * @maxLength 255
   */
  title: string;
  /** To all user */
  to_all_user?: boolean;
  /** Type id */
  type: number;
}

export interface UpdateTargetCustomerRequest {
  /** Colleges */
  colleges?: string[];
  /**
   * Name
   * @minLength 1
   * @maxLength 100
   */
  name?: string;
  /**
   * New student
   * @default false
   */
  new_student?: boolean;
  /** Roles */
  roles?: ("Staff" | "Student")[];
  /** @uniqueItems true */
  users?: number[] | null;
}

export interface User {
  /**
   * Date joined
   * @format date-time
   */
  date_joined?: string;
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email?: string;
  /**
   * First name
   * @maxLength 150
   */
  first_name?: string;
  /**
   * The groups this user belongs to. A user will get all permissions granted to each of their groups.
   * @uniqueItems true
   */
  groups?: {
    id?: number;
    name?: string;
  }[];
  /** ID */
  id?: number;
  /**
   * Active
   * Designates whether this user should be treated as active. Unselect this instead of deleting accounts.
   */
  is_active?: boolean;
  /**
   * Staff status
   * Designates whether the user can log into this admin site.
   */
  is_staff?: boolean;
  /**
   * Superuser status
   * Designates that this user has all permissions without explicitly assigning them.
   */
  is_superuser?: boolean;
  /**
   * Last login
   * @format date-time
   */
  last_login?: string | null;
  /**
   * Last name
   * @maxLength 150
   */
  last_name?: string;
  /**
   * Specific permissions for this user.
   * @uniqueItems true
   */
  user_permissions?: number[];
  /**
   * Username
   * Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
   * @minLength 1
   * @maxLength 150
   * @pattern ^[\w.@+-]+$
   */
  username: string;
}

export interface UserAttendee {
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email?: string;
  /** Full name */
  name?: string;
  /** @format url */
  photo?: string;
}

export interface UserBasicInfo {
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email?: string;
  /**
   * First name
   * @maxLength 150
   */
  first_name?: string;
  /** ID */
  id?: number;
  /**
   * Last name
   * @maxLength 150
   */
  last_name?: string;
  /** Username */
  username?: string;
}

export interface UserDevice {
  /**
   * Created at
   * @format date-time
   */
  created_at?: string | null;
  /**
   * Device token
   * @minLength 1
   * @maxLength 256
   */
  device_token: string;
  /** ID */
  id?: number;
  /**
   * Updated at
   * @format date-time
   */
  updated_at?: string | null;
  /** User */
  user: number;
}

export interface UserInfoResponse {
  personal_profile: PersonalProfile;
  user: User & PersonalProfile;
}

export interface UserNotification {
  /** Full name */
  full_name?: string;
  /**
   * Student number
   * @maxLength 255
   */
  student_number?: string | null;
}

export interface UserSetting {
  /** ID */
  id?: number;
  /** Locale */
  locale?: "VIETNAMESE" | "ENGLISH";
  /**
   * Passcode
   * @maxLength 10
   */
  passcode?: string | null;
}

export interface WebAdvisingGroup {
  academic_advisor?: Advisor;
  /** @format datetime */
  created_at?: string;
  date?: string | null;
  en_session_name?: string | null;
  end_time?: string | null;
  generic_advisor: Advisor;
  id: number;
  location?: string | null;
  members?: AdvisingMemberProfile[];
  peer_advisor?: Advisor;
  requirements?: string | null;
  session_name?: string | null;
  start_time?: string | null;
  /** @format datetime */
  updated_at?: string;
}

export interface WebAdvisingGroupAdd {
  academic_advisor?: number | null;
  advising_members?: number[];
  date?: string | null;
  en_session_name?: string | null;
  end_time?: string | null;
  generic_advisor: number;
  location?: string | null;
  peer_advisor?: number | null;
  requirements?: string | null;
  session_name?: string | null;
  start_time?: string | null;
}

export interface WebAdvisingGroupList {
  date?: string | null;
  en_session_name?: string | null;
  end_time?: string | null;
  generic_advisor: string;
  id: number;
  number_of_members: number;
  session_name?: string | null;
  start_time?: string | null;
}

export interface WebAdvisor {
  /** @format datetime */
  created_at?: string;
  email: string;
  id: number;
  lecturer: boolean;
  name: string;
  /** @format datetime */
  updated_at?: string;
}

export interface WebAdvisorAdd {
  email: string;
  lecturer?: boolean;
  name: string;
}

export interface WebEventService {
  approver?: WebFacilityUser;
  av_service?: boolean;
  av_service_note?: string | null;
  catering_people_count?: number;
  catering_service_form?: string | null;
  catering_unit_price?: string | null;
  /** @format date-time */
  cleanup_at: string;
  /** @format date-time */
  created_at?: string;
  event: number;
  event_en_title?: string | null;
  /** @format date-time */
  event_end_at?: string | null;
  /** @format date-time */
  event_start_at?: string;
  event_title?: string | null;
  id: number;
  layout?: string | null;
  note?: string | null;
  rate?: {
    comment?: string | null;
    rating?: number;
    total_participants?: number;
  };
  reason?: string | null;
  requester: WebFacilityUser;
  security_service?: boolean;
  security_service_note?: string | null;
  service_facilities?: {
    describe?: string | null;
    facility?: number;
    quantity?: number;
  }[];
  /** @format date-time */
  setup_at: string;
  setup_service?: boolean;
  setup_service_note?: string | null;
  status: "NEW" | "APPROVED" | "REJECTED";
  technical_service?: boolean;
  technical_service_note?: string | null;
  template?: string | null;
  total_outside_guests?: number;
  total_participants?: number;
  total_vip_guests?: number;
  /** @format date-time */
  updated_at?: string;
}

export interface WebEventServiceList {
  event: number;
  event_en_title?: string;
  event_title?: string;
  event_types?: EventType[];
  id: number;
  rating?: number | null;
  requesting_department?: string;
  status: "NEW" | "APPROVED" | "REJECTED";
  status_display?: "New" | "Approved" | "Rejected";
  /** @format date-time */
  updated_at?: string;
}

export interface WebEventServicePayload {
  av_service?: boolean;
  av_service_note?: string | null;
  catering_people_count?: number;
  catering_service_form?: string | null;
  catering_unit_price?: string | null;
  event: number;
  facilities?: {
    describe?: string | null;
    facility?: number;
    quantity?: number;
  }[];
  layout?: string | null;
  note?: string | null;
  security_service?: boolean;
  security_service_note?: string | null;
  /** @format date-time */
  setup_at: string;
  setup_service?: boolean;
  setup_service_note?: string | null;
  technical_service?: boolean;
  technical_service_note?: string | null;
  template?: string | null;
  total_outside_guests?: number;
  total_participants?: number;
  total_vip_guests?: number;
}

export interface WebEventServiceUpdateStatus {
  facilities?: {
    describe?: string | null;
    facility?: number;
    quantity?: number;
  }[];
  /** Reason is required for rejecting the event service */
  reason?: string | null;
  status: "APPROVED" | "REJECTED";
}

export interface WebFacility {
  /** @format date-time */
  created_at?: string;
  description?: string | null;
  id: number;
  image?: string | null;
  name: string;
  type?: number | null;
  type_name?: string | null;
  /** @format date-time */
  updated_at?: string;
  quantity?: number
}

export interface WebFacilityPayload {
  description?: string | null;
  image?: string | null;
  name: string;
  type?: number | null;
  quantity: number
}

export interface WebFacilityType {
  id: number;
  name: string;
}

export interface WebFacilityUser {
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email?: string;
  /**
   * First name
   * @maxLength 150
   */
  first_name?: string;
  /** ID */
  id?: number;
  /**
   * Last name
   * @maxLength 150
   */
  last_name?: string;
  /** Phone number */
  phone_number?: string;
  /** Username */
  username?: string;
}

export interface WebGroupEventMedia {
  /**
   * Created at
   * @format date-time
   */
  created_at?: string;
  /**
   * En title
   * @maxLength 200
   */
  en_title: string;
  /** Group event */
  group_event: number;
  /** ID */
  id?: number;
  images: string[];
  /**
   * Thumbnail
   * @format uri
   */
  thumbnail: string;
  /**
   * Title
   * @maxLength 200
   */
  title: string;
  /** Total */
  total?: number;
  /**
   * Updated at
   * @format date-time
   */
  updated_at?: string;
}

export interface WebServiceRating {
  comment?: string | null;
  /** @format date-time */
  created_at?: string;
  event_service: number;
  id: number;
  rating: number;
  total_participants?: number;
  /** @format date-time */
  updated_at?: string;
}

export interface WebServiceRatingPayload {
  comment?: string | null;
  event_service: number;
  rating: number;
  total_participants?: number;
}

export interface Workshop {
  /** @format datetime */
  created_at?: string;
  date: string;
  description?: string;
  en_name: string;
  id: number;
  name: string;
  skill: number;
  type: "COLLEGE_READINESS" | "CAREER_READINESS";
  /** @format datetime */
  updated_at?: string;
}

export interface WorkshopAdd {
  date: string;
  description?: string;
  en_name: string;
  name: string;
  skill: number;
  type: "COLLEGE_READINESS" | "CAREER_READINESS";
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "https://localhost:8000/api/v1";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];

        if (Array.isArray(property)) {
          property.forEach((value, index) => {
            formData.append(key, value);
          });
        } else {
          formData.append(
            key,
            property instanceof Blob
              ? property
              : typeof property === "object" && property !== null
                ? JSON.stringify(property)
                : `${property}`,
          );
        }

        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<T> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data.data;
    });
  };
}

/**
 * @title My VinUni API
 * @version 5b4bb48db6dd0cd46f20b87c2f86d43abda93851
 * @baseUrl https://localhost:8000/api/v1
 *
 * **Release Date:** Fri Jan  3 11:12:15 UTC 2025
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  academic = {
    /**
     * No description
     *
     * @tags Academic
     * @name GetListClassesInTheCalendar
     * @summary Get list classes in the calendar
     * @request GET:/academic/calendar/classes/
     * @secure
     */
    getListClassesInTheCalendar: (
      query: {
        /** @format date */
        start_date: string;
        /** @format date */
        end_date: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            classes?: {
              course_code?: string;
              course_credits?: number;
              /** @format date */
              course_end_date?: string;
              course_id?: number;
              course_name?: string;
              /** @format date */
              course_start_date?: string;
              course_term?: string;
              /** @format date-time */
              end_time?: string;
              instructor?: string;
              instructors?: ClassInstructor[];
              location?: string;
              section?: string;
              /** @format date-time */
              start_time?: string;
            }[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/academic/calendar/classes/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Academic
     * @name GetAllEnrollments
     * @summary Get all enrollments
     * @request GET:/academic/enrollments/
     * @secure
     */
    getAllEnrollments: (params: RequestParams = {}) =>
      this.request<
        {
          data?: {
            enrollments?: {
              /** CampusId */
              campus_id?: number;
              /** Id */
              id?: number;
              /** ProgramVersionName */
              name?: string;
            }[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/academic/enrollments/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Academic
     * @name GetAllAuditsOfAnEnrollment
     * @summary Get all audits of an enrollment
     * @request GET:/academic/enrollments/{id}/audits/
     * @secure
     */
    getAllAuditsOfAnEnrollment: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: {
            course_category_detail_list?: {
              course_detail_list?: {
                code?: string;
                credits?: number;
                earned_credits?: number;
                grade?: string;
                id?: number;
                not_apply?: boolean;
                status?: "Fulfilled" | "Unfulfilled" | "Scheduled" | "Future";
                /** Term code */
                term?: string;
              }[];
              earned_credits?: number;
              /** courseCategoryDescription */
              name?: string;
              required_credits?: number;
            }[];
            courses_not_applied?: {
              code?: string;
              credits?: number;
              earned_credits?: number;
              grade?: string;
              id?: number;
              not_apply?: boolean;
              status?: "Fulfilled" | "Unfulfilled" | "Scheduled" | "Future";
              /** Term code */
              term?: string;
            }[];
            fulfilled_credits?: number;
            required_credits?: number;
            unfulfilled_credits?: number;
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/academic/enrollments/${id}/audits/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Academic
     * @name GetDetailCourse
     * @summary Get detail course
     * @request GET:/academic/enrollments/{id}/courses/{course_code}/
     * @secure
     */
    getDetailCourse: (id: number, courseCode: string, params: RequestParams = {}) =>
      this.request<
        {
          data?: {
            code?: string;
            credits?: number;
            /**
             * ExpectedEndDate
             * @format date
             */
            expected_end_date?: string;
            /** Id */
            id?: number;
            instructor?: string;
            instructors?: ClassInstructor[];
            /** LetterGrade */
            letter_grade?: string;
            /** Name */
            name?: string;
            /** @format float */
            points?: number;
            /** PublishedCode */
            published_code?: string;
            /** SectionCode */
            section?: string;
            /**
             * StartDate
             * @format date
             */
            start_date?: string;
            /** StatusName */
            status?: string;
            /** TermName */
            term?: string;
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/academic/enrollments/${id}/courses/${courseCode}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Academic
     * @name GetAllSchedulesOfACourse
     * @summary Get all schedules of a course
     * @request GET:/academic/enrollments/{id}/courses/{course_code}/schedules/
     * @secure
     */
    getAllSchedulesOfACourse: (
      id: number,
      courseCode: string,
      query: {
        /** @format date */
        start_date: string;
        /** @format date */
        end_date: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            /**
             * SectionEndDate
             * @format date
             */
            end_date?: string;
            /** BuildingDetails.BuildingName + RoomNumber */
            location?: string | null;
            schedules?: {
              /** @format date-time */
              date?: string;
              id?: string;
              instructor?: string;
            }[];
            /**
             * SectionStartDate
             * @format date
             */
            start_date?: string;
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/academic/enrollments/${id}/courses/${courseCode}/schedules/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Academic
     * @name GetAcademicProgress
     * @summary Get academic progress
     * @request GET:/academic/enrollments/{id}/progress/
     * @secure
     */
    getAcademicProgress: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: {
            code_of_conducts?: CodeOfConduct[];
            /**
             * Cohort
             * @maxLength 255
             */
            cohort?: string | null;
            /**
             * College
             * @maxLength 255
             */
            college?: string | null;
            /** Cummulative gpa */
            cummulative_gpa?: number | null;
            honors?: Honor[];
            id: number;
            /** @maxLength 255 */
            program_name: string | null;
            projects?: StudentProject[];
            self_assignments?: SelfAssignments[];
            /** Ordered term data for the student */
            terms?: Semester[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/academic/enrollments/${id}/progress/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Academic
     * @name GetAllCoursesOfAnEnrollment
     * @summary Get all courses of an enrollment
     * @request GET:/academic/enrollments/{id}/terms/
     * @secure
     */
    getAllCoursesOfAnEnrollment: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: {
            terms?: {
              courses?: {
                code?: string;
                credits?: number;
                /**
                 * ExpectedEndDate
                 * @format date
                 */
                expected_end_date?: string;
                /** Id */
                id?: number;
                /** InstructorCode */
                instructor?: string;
                /** LetterGrade */
                letter_grade?: string;
                /** Name */
                name?: string;
                /** @format float */
                points?: number;
                /** PublishedCode */
                published_code?: string;
                /** SectionCode */
                section?: string;
                /**
                 * StartDate
                 * @format date
                 */
                start_date?: string;
                /** StatusName */
                status?: string;
                /** TermName */
                term?: string;
              }[];
              name?: string;
            }[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/academic/enrollments/${id}/terms/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  accounts = {
    /**
     * No description
     *
     * @tags Group
     * @name GetAllGroups
     * @summary Get all groups
     * @request GET:/accounts/groups/
     */
    getAllGroups: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: Group[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/accounts/groups/`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Group
     * @name CreateAGroup
     * @summary Create a group
     * @request POST:/accounts/groups/
     * @secure
     */
    createAGroup: (data: CreateGroupBody, params: RequestParams = {}) =>
      this.request<
        {
          data?: Group;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/accounts/groups/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Get all personal profiles.
     *
     * @tags Account, CMS
     * @name GetAllPersonalProfiles
     * @summary Get all personal profiles
     * @request GET:/accounts/personal-profiles/
     * @secure
     */
    getAllPersonalProfiles: (params: RequestParams = {}) =>
      this.request<
        {
          data?: AdvisingMemberProfile[];
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/accounts/personal-profiles/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account
     * @name Register
     * @summary Register
     * @request POST:/accounts/register/
     */
    register: (data: RegisterBody, params: RequestParams = {}) =>
      this.request<
        {
          data?: User;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/accounts/register/`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User setting
     * @name RetrieveUserSetting
     * @summary Retrieve user setting
     * @request GET:/accounts/settings/me/
     * @secure
     */
    retrieveUserSetting: (params: RequestParams = {}) =>
      this.request<
        {
          data?: UserSetting;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/accounts/settings/me/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User setting
     * @name UpdateUserSetting
     * @summary Update user setting
     * @request PUT:/accounts/settings/me/
     * @secure
     */
    updateUserSetting: (data: UserSetting, params: RequestParams = {}) =>
      this.request<
        {
          data?: UserSetting;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/accounts/settings/me/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account, CMS
     * @name GetAllAcademicCohorts
     * @summary Get all academic cohorts
     * @request GET:/accounts/students/cohorts/
     * @secure
     */
    getAllAcademicCohorts: (params: RequestParams = {}) =>
      this.request<
        {
          data?: TargetCustomerCohorts[];
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/accounts/students/cohorts/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account, CMS
     * @name GetAllAcademicColleges
     * @summary Get all academic colleges
     * @request GET:/accounts/students/colleges/
     * @secure
     */
    getAllAcademicColleges: (params: RequestParams = {}) =>
      this.request<
        {
          data?: TargetCustomerColleges[];
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/accounts/students/colleges/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account
     * @name LoginWithPassword
     * @summary Login with password
     * @request POST:/accounts/token/
     */
    loginWithPassword: (data: PasswordLoginBody, params: RequestParams = {}) =>
      this.request<
        {
          data?: PasswordLoginResponse;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/accounts/token/`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account
     * @name RenewAccessTokenAnRefreshToken
     * @summary Renew access token an refresh token
     * @request POST:/accounts/token/refresh/
     */
    renewAccessTokenAnRefreshToken: (data: RefreshTokenBody, params: RequestParams = {}) =>
      this.request<
        {
          data?: RefreshTokenResponse;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/accounts/token/refresh/`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Retrieves the user's profile information.
     *
     * @tags Account, CMS
     * @name RetrieveUsersProfile
     * @summary Retrieve user's profile
     * @request GET:/accounts/userinfo/
     * @secure
     */
    retrieveUsersProfile: (params: RequestParams = {}) =>
      this.request<
        {
          data?: UserInfoResponse;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/accounts/userinfo/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Get all users.
     *
     * @tags Account, CMS
     * @name GetAllUsers
     * @summary Get all users
     * @request GET:/accounts/users/
     * @secure
     */
    getAllUsers: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        /** Page size within the paginated result set. */
        page_size?: number;
        /** Search by email, username, first name, last name */
        search?: string;
        roles?: ("Staff" | "Student")[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: UserBasicInfo[];
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/accounts/users/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Get all user attendees.
     *
     * @tags Account
     * @name GetAllUserAttendees
     * @summary Get all user attendees
     * @request GET:/accounts/users/attendees/
     * @secure
     */
    getAllUserAttendees: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        /** Page size within the paginated result set. */
        page_size?: number;
        /** Search by email, username, first name, last name */
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: UserAttendee[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/accounts/users/attendees/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Get all user attendees with available status.
     *
     * @tags Account
     * @name GetAllUserAttendeesWithAvailableStatus
     * @summary Get all user attendees with available status
     * @request POST:/accounts/users/attendees/available-status/
     * @secure
     */
    getAllUserAttendeesWithAvailableStatus: (
      data: {
        emails?: string[];
        /**
         * @format date-time
         * @example "2024-09-12T06:31:00.000Z"
         */
        end?: string;
        /**
         * @format date-time
         * @example "2024-09-12T06:31:00.000Z"
         */
        start?: string;
      },
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        /** Page size within the paginated result set. */
        page_size?: number;
        /** Search by email, username, first name, last name */
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: ExtraUserAttendee[];
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/accounts/users/attendees/available-status/`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  auth = {
    /**
     * @description Authenticates the user and returns JWT tokens (refresh and access) upon successful login.
     *
     * @tags Basic Auth
     * @name BasicAuthLogin
     * @summary Basic Auth Login
     * @request POST:/auth/basic-auth/login/
     */
    basicAuthLogin: (
      data: {
        /**
         * Captcha code for additional security verification.
         * @example "123"
         */
        captcha: string;
        /**
         * The password of the user.
         * @example "123456a@"
         */
        password: string;
        /**
         * The username or email of the user.
         * @example "ngoclinh8123fake@gmail.com"
         */
        username: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            /** JWT access token. */
            access_token?: string;
            /** JWT refresh token. */
            refresh_token?: string;
          };
          /** @example null */
          error?: object | null;
        },
        {
          data?: object | null;
          error?: string;
        }
      >({
        path: `/auth/basic-auth/login/`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Send the OTP code to the user's phone number or email.
     *
     * @tags Basic Auth
     * @name SendVerificationOtp
     * @summary Send verification OTP
     * @request POST:/auth/basic-auth/otp/
     */
    sendVerificationOtp: (
      data: {
        /** The purpose of sending the OTP, such as "registration", "password reset", etc. */
        purpose: "REGISTRATION" | "PASSWORD_RESET";
        /** The user's phone number or email. */
        username: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            /** @example "OTP resent successfully to the provided phone number." */
            detail?: string;
          };
          /** @example null */
          error?: object | null;
        },
        {
          data?: object | null;
          /** @example "Invalid phone number." */
          error?: string;
        }
      >({
        path: `/auth/basic-auth/otp/`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Registers a new user with basic authentication details (name, phone number, password).
     *
     * @tags Basic Auth
     * @name RegisterUser
     * @summary Register user
     * @request POST:/auth/basic-auth/register/
     */
    registerUser: (
      data: {
        /** Full name of the user. */
        name: string;
        /** One-time otp sent to the user. */
        otp: string;
        /**
         * Password for the user account, min length is 6 letters.
         * @example "123456a@"
         */
        password: string;
        /** User's phone number of email. */
        username: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            /** JWT access token. */
            access_token?: string;
            /** JWT refresh token. */
            refresh_token?: string;
          };
          /** @example null */
          error?: object | null;
        },
        {
          data?: object | null;
          error?: string;
        }
      >({
        path: `/auth/basic-auth/register/`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Basic Auth
     * @name ResetPassword
     * @summary Reset password
     * @request POST:/auth/basic-auth/reset-password/
     * @secure
     */
    resetPassword: (
      data: {
        /**
         * Password for the user account, min length is 6 letters.
         * @example "123456a@"
         */
        new_password: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            /** @example "Password reset successfully." */
            detail?: string;
          };
          /** @example null */
          error?: object | null;
        },
        {
          data?: object | null;
          error?: string;
        }
      >({
        path: `/auth/basic-auth/reset-password/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Verify reset password OTP
     *
     * @tags Basic Auth
     * @name VerifyResetPasswordOtp
     * @summary Verify reset password OTP
     * @request POST:/auth/basic-auth/reset-password/verify/
     */
    verifyResetPasswordOtp: (
      data: {
        /** One-time otp sent to the user. */
        otp: string;
        /** The user's phone number or email. */
        username: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            /** JWT access token shorten lifetime for reset password */
            access_token?: string;
          };
          /** @example null */
          error?: object | null;
        },
        {
          data?: object | null;
          error?: string;
        }
      >({
        path: `/auth/basic-auth/reset-password/verify/`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Redirects to Google login page to authenticate user.
     *
     * @tags Auth
     * @name GoogleLogin
     * @summary Google Login
     * @request GET:/auth/google/login/
     */
    googleLogin: (params: RequestParams = {}) =>
      this.request<
        any,
        | void
        | {
            /** @example "Invalid request parameters." */
            error?: string;
          }
        | {
            /** @example "Internal server error occurred." */
            error?: string;
          }
      >({
        path: `/auth/google/login/`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Only refresh token will be invalid after calling this API and access token still valid until the end of the day
     *
     * @tags Auth
     * @name Logout
     * @summary Logout
     * @request POST:/auth/logout/
     * @secure
     */
    logout: (
      data: {
        refresh: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            ok?: boolean;
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/auth/logout/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Follow OAuth2 flow to login with Microsoft account - Step 0: The user does login and mobile open this URL in a webview - Step 1: Redirect user to Microsoft login page - Step 2: User login with Microsoft account - Step 3: Microsoft redirect user back to the callback URL to get `code` - Step 4: The backend uses `code` to get `access` and `refresh` token - Step 5: The backend redirects user to login success page with `access token` and `refresh token` in the querystring - Step 6: In the success page, JS code gets the access token and refresh token from the querystring and passes them to mobile via webview communication by the following code: ```javascript const queryString = window.location.search; const urlParams = new URLSearchParams(queryString); const payload = { access_token: urlParams.get('access'), refresh_token: urlParams.get('refresh'), } console.log("Login succeded with payload: ", payload); if (window.flutter_inappwebview) { window.flutter_inappwebview.callHandler("onLoginSucceded", payload); } ``` - Step 7: When mobile receives the payload, it saves the access token and refresh token to the local storage and close the webview Ref: [Webview communication](https://inappwebview.dev/docs/webview/javascript/communication/) **Notes** - Life time of `refresh token` is 7 days - Life time of `access token` is 1 day
     *
     * @tags Auth
     * @name LoginWithMicrosoft
     * @summary Login with Microsoft
     * @request GET:/auth/ms/login/
     */
    loginWithMicrosoft: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/auth/ms/login/`,
        method: "GET",
        ...params,
      }),

    /**
     * @description The old refresh token always invalid after calling this API
     *
     * @tags Auth
     * @name RefreshToken
     * @summary Refresh token
     * @request POST:/auth/refresh/
     */
    refreshToken: (
      data: {
        refresh: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            access?: string;
            refresh?: string;
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/auth/refresh/`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  azureBlobUpload = {
    /**
     * No description
     *
     * @tags Azure blob upload
     * @name UploadAttachments
     * @summary Upload attachments
     * @request POST:/azure-blob-upload/upload-attachments/
     * @secure
     */
    uploadAttachments: (
      data: {
        files: File[];
        thumbnail?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** Error information if the request was unsuccessful */
          error?: object;
          urls?: string[];
        },
        any
      >({
        path: `/azure-blob-upload/upload-attachments/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),
  };
  benefits = {
    /**
     * No description
     *
     * @tags Benefit, CMS
     * @name GetListBenefits
     * @summary Get list benefits
     * @request GET:/benefits/
     * @secure
     */
    getListBenefits: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        /** @minLength 1 */
        search?: string;
        type?: number[];
        published?: boolean[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: BenefitList[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/benefits/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Benefit
     * @name GetAllBenefitTypesWithPagination
     * @summary Get all benefit types with pagination
     * @request GET:/benefits/types/
     * @secure
     */
    getAllBenefitTypesWithPagination: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        /** @minLength 1 */
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: BenefitType[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/benefits/types/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Benefit, CMS
     * @name GetAllBenefitTypes
     * @summary Get all benefit types
     * @request GET:/benefits/types/all/
     * @secure
     */
    getAllBenefitTypes: (params: RequestParams = {}) =>
      this.request<
        {
          data?: BenefitType[];
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/benefits/types/all/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Benefit, CMS
     * @name GetDetailBenefit
     * @summary Get detail benefit
     * @request GET:/benefits/{id}/
     * @secure
     */
    getDetailBenefit: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          data?: Benefit;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/benefits/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  calendar = {
    /**
     * No description
     *
     * @tags Calendar
     * @name GetListEventsFromOutlookCalendar
     * @summary Get list events from outlook calendar
     * @request GET:/calendar/events/
     * @secure
     */
    getListEventsFromOutlookCalendar: (
      query: {
        /**
         * from date
         * @format date
         * @example "2024/05/14"
         */
        start_date: string;
        /**
         * to date
         * @format date
         * @example "2024/05/14"
         */
        end_date: string;
        /**
         * Time zone
         * @example "Asia/Ho_Chi_Minh"
         */
        tz?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: OutlookEvent[];
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/calendar/events/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Calendar
     * @name BookingAnEvent
     * @summary Booking an event
     * @request POST:/calendar/events/
     * @secure
     */
    bookingAnEvent: (data: OutlookEventBooking, params: RequestParams = {}) =>
      this.request<
        {
          data?: OutlookEvent;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/calendar/events/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Calendar
     * @name GetListAvailableRoomsToBooking
     * @summary Get list available rooms to booking
     * @request GET:/calendar/events/rooms/
     * @secure
     */
    getListAvailableRoomsToBooking: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        /** Page size within the paginated result set. */
        page_size?: number;
        /**
         * from
         * @format date-time
         * @example "2024-09-12T06:31:00.000Z"
         */
        start_time?: string;
        /**
         * to date
         * @format date-time
         * @example "2024-09-12T06:31:00.000Z"
         */
        end_time?: string;
        /**
         * Timezone
         * @example "Asia/Ho_Chi_Minh"
         */
        tz?: string;
        /** Search room by name */
        search?: string;
        /** Get fitness room */
        is_fitness?: boolean;
        /** Get counselling rooms */
        is_counselling?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: CalendarRoom[];
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/calendar/events/rooms/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Calendar
     * @name DetailABookingEvent
     * @summary Detail a booking event
     * @request GET:/calendar/events/{id}/
     * @secure
     */
    detailABookingEvent: (
      id: string,
      query?: {
        /** Time zone */
        tz?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: OutlookEvent;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/calendar/events/${id}/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Calendar
     * @name UpdateAnEvent
     * @summary Update an event
     * @request PUT:/calendar/events/{id}/
     * @secure
     */
    updateAnEvent: (id: string, data: OutlookEventUpdate, params: RequestParams = {}) =>
      this.request<DislikeEventRequest, any>({
        path: `/calendar/events/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Calendar
     * @name AcceptAnEvent
     * @summary Accept an event
     * @request POST:/calendar/events/{id}/accept/
     * @secure
     */
    acceptAnEvent: (id: string, data: OutlookEventResponse, params: RequestParams = {}) =>
      this.request<DislikeEventRequest, any>({
        path: `/calendar/events/${id}/accept/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Calendar
     * @name CancelAnEvent
     * @summary Cancel an event
     * @request POST:/calendar/events/{id}/cancel/
     * @secure
     */
    cancelAnEvent: (id: string, data: OutlookEventCancel, params: RequestParams = {}) =>
      this.request<DislikeEventRequest, any>({
        path: `/calendar/events/${id}/cancel/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Calendar
     * @name DeclineAnEvent
     * @summary Decline an event
     * @request POST:/calendar/events/{id}/decline/
     * @secure
     */
    declineAnEvent: (id: string, data: OutlookEventResponse, params: RequestParams = {}) =>
      this.request<DislikeEventRequest, any>({
        path: `/calendar/events/${id}/decline/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Calendar
     * @name TentativeAnEvent
     * @summary Tentative an event
     * @request POST:/calendar/events/{id}/tentative/
     * @secure
     */
    tentativeAnEvent: (id: string, data: OutlookEventResponse, params: RequestParams = {}) =>
      this.request<DislikeEventRequest, any>({
        path: `/calendar/events/${id}/tentative/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  config = {
    /**
     * No description
     *
     * @tags Internal, CMS
     * @name GetAnAppConfigWithCode
     * @summary Get an app config with code
     * @request GET:/config/{code}/
     * @secure
     */
    getAnAppConfigWithCode: (code: string, params: RequestParams = {}) =>
      this.request<
        {
          data?: AppConfig;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/config/${code}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Internal, CMS
     * @name UpdateAnAppConfigWithCode
     * @summary Update an app config with code
     * @request PUT:/config/{code}/
     * @secure
     */
    updateAnAppConfigWithCode: (
      code: string,
      data: {
        /** @format url */
        campus_guideline_handbook_url?: string;
        /** @format url */
        campus_map_url?: string;
        /** @format url */
        code_of_conduct_url?: string;
        emergency_contact?: {
          en_name?: string;
          name?: string;
          phone?: string;
        }[];
        /** @format url */
        faculty_policy_url?: string;
        /** @format url */
        internation_student_handbook_url?: string;
        /** @format url */
        library_service_url?: string;
        /** @format url */
        student_policy_url?: string;
        /** @format url */
        student_success_url?: string;
        /** @format url */
        wow_handbook_url?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: AppConfig;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/config/${code}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  counselling = {
    /**
     * No description
     *
     * @tags Counselling
     * @name GetAllCases
     * @summary Get all cases
     * @request GET:/counselling/cases
     * @secure
     */
    getAllCases: (params: RequestParams = {}) =>
      this.request<
        {
          cases?: {
            case_number?: string;
            case_type?: string;
            comment?: string | null;
            counsellor_name?: string;
            description?: string | null;
            /** @format date-time */
            end_at?: string;
            id?: string;
            location_name?: string | null;
            /** HTML formatted */
            online_meeting_info?: string | null;
            priority?: string;
            record_type_name?: string | null;
            request_type?: string;
            /** @format date-time */
            start_at?: string;
            status?: "NEW" | "DUPLICATED" | "IN_PROGRESS" | "RESOLVED" | "CANCELED";
            subject?: string;
          }[];
        },
        {
          /** Error information if the request was unsuccessful */
          error?: object;
        }
      >({
        path: `/counselling/cases`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Counselling
     * @name CreateACase
     * @summary Create a case
     * @request POST:/counselling/cases
     * @secure
     */
    createACase: (
      data: {
        attachment_ids?: string[];
        availability_id: string;
        /** counsellor id */
        counsellor_email: string;
        description?: string;
        /** @format date-time */
        end_at: string;
        /**
         * - room email if is offline booking
         * - null if is online booking
         */
        location_email?: string | null;
        /**
         * - room name if is offline booking
         * - null if is online booking
         */
        location_name?: string | null;
        /** @format date-time */
        start_at: string;
        /** topic name */
        subject: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            counselling_id?: string;
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/counselling/cases`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Counselling
     * @name GetDetailCase
     * @summary Get detail case
     * @request GET:/counselling/cases/{case_id}/
     * @secure
     */
    getDetailCase: (caseId: string, params: RequestParams = {}) =>
      this.request<
        {
          case_number?: string;
          case_type?: string;
          comment?: string | null;
          counsellor_name?: string;
          description?: string | null;
          /** @format date-time */
          end_at?: string;
          id?: string;
          location_name?: string | null;
          /** HTML formatted */
          online_meeting_info?: string | null;
          priority?: string;
          record_type_name?: string | null;
          request_type?: string;
          /** @format date-time */
          start_at?: string;
          status?: "NEW" | "DUPLICATED" | "IN_PROGRESS" | "RESOLVED" | "CANCELED";
          subject?: string;
        },
        {
          /** Error information if the request was unsuccessful */
          error?: object;
        }
      >({
        path: `/counselling/cases/${caseId}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Counselling
     * @name CancelACase
     * @summary Cancel a case
     * @request PUT:/counselling/cases/{case_id}/cancel/
     * @secure
     */
    cancelACase: (caseId: string, params: RequestParams = {}) =>
      this.request<
        {
          data?: {
            ok?: boolean;
          };
        },
        {
          /** Error information if the request was unsuccessful */
          error?: object;
        }
      >({
        path: `/counselling/cases/${caseId}/cancel/`,
        method: "PUT",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Counselling
     * @name GetCounsellorAvailabilities
     * @summary Get counsellor availabilities
     * @request GET:/counselling/counsellors/{counsellor_id}/availabilities
     * @secure
     */
    getCounsellorAvailabilities: (counsellorId: string, params: RequestParams = {}) =>
      this.request<
        {
          data?: {
            availabilities?: {
              /** @format date-time */
              end_date_time?: string;
              id?: string;
              /** @format date-time */
              start_date_time?: string;
            }[];
          };
        },
        {
          /** Error information if the request was unsuccessful */
          error?: object;
        }
      >({
        path: `/counselling/counsellors/${counsellorId}/availabilities`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Counselling
     * @name GetAllTopics
     * @summary Get all topics
     * @request GET:/counselling/topics/
     * @secure
     */
    getAllTopics: (params: RequestParams = {}) =>
      this.request<
        {
          data?: {
            counsellors?: {
              email?: string;
              id?: string;
              name?: string;
            }[];
            id?: string;
            name?: string;
          }[];
        },
        {
          /** Error information if the request was unsuccessful */
          error?: object;
        }
      >({
        path: `/counselling/topics/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  eventServices = {
    /**
     * No description
     *
     * @tags Facility (CMS), CMS
     * @name GetListEventServicesWithPagination
     * @summary Get list event services with pagination
     * @request GET:/event-services/
     * @secure
     */
    getListEventServicesWithPagination: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        /** Search by name or description. */
        search?: string;
        status?: ("NEW" | "APPROVED" | "REJECTED")[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: WebEventServiceList[];
          };
        },
        {
          /** Error information if the request was unsuccessful */
          error?: object;
        }
      >({
        path: `/event-services/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Facility (CMS), CMS
     * @name CreateAnEventService
     * @summary Create an event service
     * @request POST:/event-services/
     * @secure
     */
    createAnEventService: (data: WebEventServicePayload, params: RequestParams = {}) =>
      this.request<
        {
          data?: WebEventService;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/event-services/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Facility (CMS), CMS
     * @name ExportEventServices
     * @summary Export event services
     * @request GET:/event-services/export/
     * @secure
     */
    exportEventServices: (params: RequestParams = {}) =>
      this.request<File, any>({
        path: `/event-services/export/`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Facility (CMS), CMS
     * @name GetAnEventService
     * @summary Get an event service
     * @request GET:/event-services/{id}/
     * @secure
     */
    getAnEventService: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: WebEventService;
        },
        {
          /** Error information if record not found. */
          error?: object;
        }
      >({
        path: `/event-services/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Facility (CMS), CMS
     * @name UpdateAnEventService
     * @summary Update an event service
     * @request PUT:/event-services/{id}/
     * @secure
     */
    updateAnEventService: (id: number, data: WebEventServicePayload, params: RequestParams = {}) =>
      this.request<
        {
          data?: WebEventService;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        {
          /** Error information if the request was unsuccessful */
          error?: object;
        }
      >({
        path: `/event-services/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Facility (CMS), CMS
     * @name UpdateAnEventServiceStatus
     * @summary Update an event service status
     * @request PUT:/event-services/{id}/update-status/
     * @secure
     */
    updateAnEventServiceStatus: (id: number, data: WebEventServiceUpdateStatus, params: RequestParams = {}) =>
      this.request<
        {
          data?: WebEventService;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        {
          /** Error information if the request was unsuccessful */
          error?: object;
        }
      >({
        path: `/event-services/${id}/update-status/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  events = {
    /**
     * No description
     *
     * @tags Event, CMS
     * @name GetListEventsWithPagination
     * @summary Get list events with pagination
     * @request GET:/events/
     */
    getListEventsWithPagination: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        /** @minLength 1 */
        search?: string;
        published?: boolean[];
        is_highlight?: boolean[];
        event_types?: number[];
        host_type?: ("ONLINE" | "OFFLINE")[];
        /** @format date-time */
        start_date?: string;
        /** @format date-time */
        end_date?: string;
        status?: ("UPCOMING" | "ONGOING" | "COMPLETED" | "CANCELLED")[];
        source?: ("INTERNAL" | "EXTERNAL")[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: EventList[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/events/`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event, CMS
     * @name CreateAEvent
     * @summary Create a event
     * @request POST:/events/
     * @secure
     */
    createAEvent: (data: EventAdd, params: RequestParams = {}) =>
      this.request<
        {
          data?: Event;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/events/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event, CMS
     * @name AllEventsMinimalDataForDropList
     * @summary All events (minimal data for drop list)
     * @request GET:/events/all/
     */
    allEventsMinimalDataForDropList: (
      query?: {
        /** @minLength 1 */
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: EventReadInfo[];
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/events/all/`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name GetAllEventTypes
     * @summary Get all event types
     * @request GET:/events/event-types/
     */
    getAllEventTypes: (params: RequestParams = {}) =>
      this.request<
        {
          data?: EventType[];
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/events/event-types/`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name GetListRegisteredEvents
     * @summary Get list registered events
     * @request GET:/events/registered/
     * @secure
     */
    getListRegisteredEvents: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        /** @format date */
        start_date?: string;
        /** @format date */
        end_date?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: Event;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/events/registered/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event, CMS
     * @name GetListEventTypesWithPagination
     * @summary Get list event types with pagination
     * @request GET:/events/types/
     */
    getListEventTypesWithPagination: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        /** Search name and en_name */
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: EventType[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/events/types/`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event, CMS
     * @name CreateAnEventType
     * @summary Create an event type
     * @request POST:/events/types/
     * @secure
     */
    createAnEventType: (data: EventType, params: RequestParams = {}) =>
      this.request<
        {
          data?: EventType;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/events/types/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event, CMS
     * @name GetDetailEventType
     * @summary Get detail event type
     * @request GET:/events/types/{id}/
     */
    getDetailEventType: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: EventType;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/events/types/${id}/`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event, CMS
     * @name UpdateAnEventType
     * @summary Update an event type
     * @request PUT:/events/types/{id}/
     * @secure
     */
    updateAnEventType: (id: number, data: EventType, params: RequestParams = {}) =>
      this.request<
        {
          data?: EventType;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/events/types/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name GetListUnratedEvents
     * @summary Get list unrated events
     * @request GET:/events/unrated/
     * @secure
     */
    getListUnratedEvents: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: Event;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/events/unrated/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event, CMS
     * @name ListEventRegistrationsWithPagination
     * @summary List event registrations with pagination
     * @request GET:/events/{event_id}/registrations/
     */
    listEventRegistrationsWithPagination: (
      eventId: number,
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        status?: ("REGISTERED" | "CHECKED_IN" | "CANCELLED")[];
        /** @minLength 1 */
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: EventRegistrationResponse[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/events/${eventId}/registrations/`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event, CMS
     * @name GetDetailEvent
     * @summary Get detail event
     * @request GET:/events/{id}/
     */
    getDetailEvent: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: Event;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/events/${id}/`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name UpdateAnOutlookEvent
     * @summary Update an outlook event
     * @request PUT:/events/{id}/
     * @secure
     */
    updateAnOutlookEvent: (id: number, data: EventUpdate, params: RequestParams = {}) =>
      this.request<
        {
          data?: Event;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/events/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name CheckInAnEvent
     * @summary Check in an event
     * @request POST:/events/{id}/check-in/
     * @secure
     */
    checkInAnEvent: (id: number, data: EventCheckInBody, params: RequestParams = {}) =>
      this.request<
        {
          data?: EventRegistration;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/events/${id}/check-in/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event, CMS
     * @name DislikeAnEvent
     * @summary Dislike an event
     * @request POST:/events/{id}/dislike/
     * @secure
     */
    dislikeAnEvent: (id: number, data: DislikeEventRequest, params: RequestParams = {}) =>
      this.request<
        {
          data?: DislikeEventResponse;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/events/${id}/dislike/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name LikeAnEvent
     * @summary Like an event
     * @request POST:/events/{id}/like/
     * @secure
     */
    likeAnEvent: (id: number, data: LikeEventRequest, params: RequestParams = {}) =>
      this.request<
        {
          data?: LikeEventResponse;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/events/${id}/like/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name RateAnEvent
     * @summary Rate an event
     * @request POST:/events/{id}/rate/
     * @secure
     */
    rateAnEvent: (id: number, data: EventRatingBody, params: RequestParams = {}) =>
      this.request<
        {
          data?: EventRating;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/events/${id}/rate/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name RegisterAnEvent
     * @summary Register an event
     * @request POST:/events/{id}/register/
     * @secure
     */
    registerAnEvent: (id: number, data: EventRegistrationBody, params: RequestParams = {}) =>
      this.request<
        {
          data?: EventRegistration;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/events/${id}/register/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  facilities = {
    /**
     * No description
     *
     * @tags Facility (CMS), CMS
     * @name GetListFacilitiesWithPagination
     * @summary Get list facilities with pagination
     * @request GET:/facilities/
     * @secure
     */
    getListFacilitiesWithPagination: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        /** Search by name or description. */
        search?: string;
        type?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: WebFacility[];
          };
        },
        {
          /** Error information if the request was unsuccessful */
          error?: object;
        }
      >({
        path: `/facilities/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Facility (CMS), CMS
     * @name CreateAFacility
     * @summary Create a facility
     * @request POST:/facilities/
     * @secure
     */
    createAFacility: (data: WebFacilityPayload, params: RequestParams = {}) =>
      this.request<
        {
          data?: WebFacility;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/facilities/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Facility (CMS), CMS
     * @name GetAllFacilities
     * @summary Get all facilities
     * @request GET:/facilities/all/
     * @secure
     */
    getAllFacilities: (params: RequestParams = {}) =>
      this.request<
        {
          data?: WebFacility[];
        },
        any
      >({
        path: `/facilities/all/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Facility (CMS), CMS
     * @name DeleteAFacility
     * @summary Delete a facility
     * @request DELETE:/facilities/{id}/
     * @secure
     */
    deleteAFacility: (id: string, params: RequestParams = {}) =>
      this.request<
        void,
        | {
            /** Error information if the request was unsuccessful */
            error?: object;
          }
        | DislikeEventRequest
      >({
        path: `/facilities/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Facility (CMS), CMS
     * @name GetAFacility
     * @summary Get a facility
     * @request GET:/facilities/{id}/
     * @secure
     */
    getAFacility: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: WebFacility;
        },
        {
          /** Error information if record not found. */
          error?: object;
        }
      >({
        path: `/facilities/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Facility (CMS), CMS
     * @name UpdateAFacility
     * @summary Update a facility
     * @request PUT:/facilities/{id}/
     * @secure
     */
    updateAFacility: (id: number, data: WebFacilityPayload, params: RequestParams = {}) =>
      this.request<
        {
          data?: WebFacility;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        {
          /** Error information if the request was unsuccessful */
          error?: object;
        }
      >({
        path: `/facilities/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  facilityTypes = {
    /**
     * No description
     *
     * @tags Facility (CMS), CMS
     * @name GetAllFacilityTypes
     * @summary Get all facility types
     * @request GET:/facility-types/all/
     * @secure
     */
    getAllFacilityTypes: (params: RequestParams = {}) =>
      this.request<
        {
          data?: WebFacilityType[];
        },
        any
      >({
        path: `/facility-types/all/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  feedbacks = {
    /**
     * No description
     *
     * @tags Feedback, CMS
     * @name GetListFeedbacks
     * @summary Get list feedbacks
     * @request GET:/feedbacks/
     * @secure
     */
    getListFeedbacks: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: Feedback[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/feedbacks/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Feedback
     * @name CreateAFeedback
     * @summary Create a feedback
     * @request POST:/feedbacks/
     * @secure
     */
    createAFeedback: (data: FeedbackBody, params: RequestParams = {}) =>
      this.request<
        {
          data?: Feedback;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/feedbacks/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Feedback, CMS
     * @name GetAFeedback
     * @summary Get a feedback
     * @request GET:/feedbacks/{id}/
     * @secure
     */
    getAFeedback: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: Feedback;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/feedbacks/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Feedback
     * @name UpdateAFeedback
     * @summary Update a feedback
     * @request PUT:/feedbacks/{id}/
     * @secure
     */
    updateAFeedback: (id: number, data: Feedback, params: RequestParams = {}) =>
      this.request<
        {
          data?: Feedback;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/feedbacks/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  finances = {
    /**
     * No description
     *
     * @tags Finance
     * @name GetFinance
     * @summary Get finance
     * @request GET:/finances/
     * @secure
     */
    getFinance: (params: RequestParams = {}) =>
      this.request<
        {
          data?: {
            /** Admission application id */
            app_id?: string;
            /** Admission application name */
            app_name?: string;
            invoice?: Invoice;
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/finances/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description This link will be added to the payload with the AgainLink key when sending payment request to OnePay.
     *
     * @tags Finance
     * @name PaymentAgainLink
     * @summary Payment again link
     * @request GET:/finances/invoices/payments/again-link/
     */
    paymentAgainLink: (params: RequestParams = {}) =>
      this.request<
        {
          data?: object;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/finances/invoices/payments/again-link/`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Finance
     * @name GetPaymentRequestUrl
     * @summary Get payment request url
     * @request POST:/finances/invoices/payments/request-url/
     * @secure
     */
    getPaymentRequestUrl: (data: PaymentRequestUrlRequest, params: RequestParams = {}) =>
      this.request<
        {
          data?: {
            payment_url?: string;
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/finances/invoices/payments/request-url/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description After user finish payment, OnePay will redirect user back to this endpoint with payment result.
     *
     * @tags Finance
     * @name GetPaymentReturnUrlOnePayHook
     * @summary Get payment return url (OnePay hook)
     * @request GET:/finances/invoices/payments/return-url/
     * @secure
     */
    getPaymentReturnUrlOnePayHook: (
      query?: {
        vpc_MerchTxnRef?: string;
        vpc_Amount?: string;
        vpc_AuthorizeId?: string;
        vpc_Card?: string;
        vpc_CardExp?: string;
        vpc_CardNum?: string;
        vpc_CardUid?: string;
        vpc_Command?: string;
        vpc_Merchant?: string;
        vpc_Message?: string;
        vpc_OrderInfo?: string;
        vpc_PayChannel?: string;
        vpc_TransactionNo?: string;
        vpc_TxnResponseCode?: string;
        vpc_Version?: string;
        vpc_SecureHash?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: PaymentReturnUrl;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/finances/invoices/payments/return-url/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Finance
     * @name GetInvoice
     * @summary Get invoice
     * @request GET:/finances/invoices/{id}/
     * @secure
     */
    getInvoice: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          data?: InvoiceDetail;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/finances/invoices/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Finance
     * @name GetInvoicePayment
     * @summary Get invoice payment
     * @request GET:/finances/invoices/{invoice_id}/payments/
     * @secure
     */
    getInvoicePayment: (invoiceId: string, params: RequestParams = {}) =>
      this.request<
        {
          data?: {
            app_id: string;
            debit_transactions: DebitTransaction[];
            payment_methods?: PaymentMethod[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/finances/invoices/${invoiceId}/payments/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Finance
     * @name GetTransaction
     * @summary Get transaction
     * @request GET:/finances/invoices/{invoice_id}/transactions/{transaction_id}
     * @secure
     */
    getTransaction: (invoiceId: string, transactionId: string, params: RequestParams = {}) =>
      this.request<
        {
          data?: PaymentTransaction;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/finances/invoices/${invoiceId}/transactions/${transactionId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Mulesoft will send webhook to this endpoint in some cases (eg. a new invoice issued, one payment transaction created).
     *
     * @tags Finance
     * @name FinanceWebhook
     * @summary Finance webhook
     * @request POST:/finances/webhook/
     * @secure
     */
    financeWebhook: (
      data: FinanceWebhookInvoiceIssued | FinanceWebhookPaymentTransaction,
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: object;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/finances/webhook/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  financialAids = {
    /**
     * @description Retrieve a list of financial aid request along with their associated results.
     *
     * @tags Financial Aid
     * @name GetAllFinancialAidRequests
     * @summary Get all financial aid requests
     * @request GET:/financial-aids/
     * @secure
     */
    getAllFinancialAidRequests: (params: RequestParams = {}) =>
      this.request<
        {
          data?: {
            fa_requests?: {
              /**
               * Date when the form was created.
               * @format date
               */
              created_date?: string;
              /** Unique identifier of the financial aid form. */
              id?: string;
              /** Name of the financial aid form. */
              name?: string;
              results?: {
                /** Financial aid amount. */
                amount?: string | null;
                /**
                 * Date the aid was granted.
                 * @format date
                 */
                granted_date?: string;
                /** Name of the result. */
                name?: string;
                /** Percentage of the aid granted. */
                percentage?: string | null;
                /** Identifier of the associated term. */
                term_id?: string | null;
                /** Type of financial aid (e.g., Scholarship, Grant). */
                type?: string | null;
              }[];
              /** Current status of the form. */
              status?: "Draft" | "Submitted" | "Amendment" | null;
              /** Identifier of the academic term. */
              term_id?: string | null;
              /** Name of the academic term associated with the form. */
              term_name?: string;
            }[];
          };
          error?: {
            /** Error code. */
            code?: string;
            /** Error message. */
            message?: string;
          };
        },
        any
      >({
        path: `/financial-aids/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description This webhook is used to update the status of financial aid requests. It requires a valid bearer token for authentication.
     *
     * @tags Financial Aid
     * @name WebhookToUpdateFinancialAidStatus
     * @summary Webhook to update financial aid status
     * @request POST:/financial-aids/webhook/
     * @secure
     */
    webhookToUpdateFinancialAidStatus: (
      data: {
        data?: {
          /** The unique identifier of the financial aid request. */
          id: string;
          /** The name or identifier of the financial aid request. */
          name: string;
          /** The updated status of the financial aid request. */
          status: string;
          /** The unique student number associated with the request. */
          student_number: string;
        };
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            /** Indicates if the operation was successful. */
            ok?: boolean;
          };
          /** Error information if the request was unsuccessful. */
          error?: {
            /** The error code identifying the type of error. */
            code?: string;
            /** A description of the error. */
            message?: string;
          } | null;
        },
        {
          error?: {
            /** The error code identifying the type of error. */
            code?: string;
            /** A description of the error. */
            message?: string;
          };
        }
      >({
        path: `/financial-aids/webhook/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  groupEventMedia = {
    /**
     * No description
     *
     * @tags Group event media, CMS
     * @name GetListGroupEventMediaWithPagination
     * @summary Get list group event media with pagination
     * @request GET:/group-event-media/
     * @secure
     */
    getListGroupEventMediaWithPagination: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        /** @minLength 1 */
        search?: string;
        group_event?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: WebGroupEventMedia[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/group-event-media/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Group event media, CMS
     * @name CreateAGroupEventMedia
     * @summary Create a group event media
     * @request POST:/group-event-media/
     * @secure
     */
    createAGroupEventMedia: (data: GroupEventMediaAdd, params: RequestParams = {}) =>
      this.request<
        {
          data?: WebGroupEventMedia;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/group-event-media/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Group event media, CMS
     * @name GetDetailGroupEventMedia
     * @summary Get detail group event media
     * @request GET:/group-event-media/{id}/
     * @secure
     */
    getDetailGroupEventMedia: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: WebGroupEventMedia;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/group-event-media/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Group event media, CMS
     * @name UpdateAGroupEventMedia
     * @summary Update a group event media
     * @request PUT:/group-event-media/{id}/
     * @secure
     */
    updateAGroupEventMedia: (id: number, data: GroupEventMediaAdd, params: RequestParams = {}) =>
      this.request<
        {
          data?: WebGroupEventMedia;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/group-event-media/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  groupEvents = {
    /**
     * No description
     *
     * @tags Group event, CMS
     * @name GetListGroupEventsWithPagination
     * @summary Get list group events with pagination
     * @request GET:/group-events/
     * @secure
     */
    getListGroupEventsWithPagination: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        /** @minLength 1 */
        search?: string;
        published?: boolean[];
        type?: "wow"[];
        /** @format date-time */
        start_date?: string;
        /** @format date-time */
        end_date?: string;
        status?: ("UPCOMING" | "ONGOING" | "COMPLETED")[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: GroupEvent[];
          };
        },
        {
          /** Error information if the request was unsuccessful */
          error?: object;
        }
      >({
        path: `/group-events/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Group event
     * @name CreateAGroupEvent
     * @summary Create a group event
     * @request POST:/group-events/
     * @secure
     */
    createAGroupEvent: (data: GroupEventAdd, params: RequestParams = {}) =>
      this.request<
        {
          data?: GroupEvent;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/group-events/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Group event, CMS
     * @name CheckEventConditions
     * @summary Check event conditions
     * @request POST:/group-events/event-conditions/
     * @secure
     */
    checkEventConditions: (
      data: {
        /**
         * Event ids
         * List id of event
         */
        event_ids: number[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            error?: string;
            status: boolean;
          }[];
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/group-events/event-conditions/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Group event
     * @name ExportGroupEventRating
     * @summary Export group event rating
     * @request GET:/group-events/export-rating/{id}/
     * @secure
     */
    exportGroupEventRating: (id: number, params: RequestParams = {}) =>
      this.request<File, any>({
        path: `/group-events/export-rating/${id}/`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Group event, CMS
     * @name PublishAGroupEvent
     * @summary Publish a group event
     * @request PUT:/group-events/publish/{id}
     * @secure
     */
    publishAGroupEvent: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: GroupEvent;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/group-events/publish/${id}`,
        method: "PUT",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Group event
     * @name GetListUnratedGroupEvents
     * @summary Get list unrated group events
     * @request GET:/group-events/unrated/
     * @secure
     */
    getListUnratedGroupEvents: (params: RequestParams = {}) =>
      this.request<
        {
          data?: GroupEvent;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/group-events/unrated/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Group event, CMS
     * @name GetValidEvents
     * @summary Get valid events
     * @request GET:/group-events/valid-events/
     * @secure
     */
    getValidEvents: (params: RequestParams = {}) =>
      this.request<
        {
          data?: SingleEvent[];
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/group-events/valid-events/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Group event
     * @name GetValidGroupEventTypes
     * @summary Get valid group event types
     * @request GET:/group-events/valid-types/
     * @secure
     */
    getValidGroupEventTypes: (params: RequestParams = {}) =>
      this.request<
        {
          data?: string[];
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/group-events/valid-types/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Group event
     * @name GetDetailGroupEvent
     * @summary Get detail group event
     * @request GET:/group-events/{id}/
     * @secure
     */
    getDetailGroupEvent: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: GroupEvent;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/group-events/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Group event, CMS
     * @name UpdateAGroupEvent
     * @summary Update a group event
     * @request PUT:/group-events/{id}/
     * @secure
     */
    updateAGroupEvent: (id: number, data: GroupEventAdd, params: RequestParams = {}) =>
      this.request<
        {
          data?: GroupEvent;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/group-events/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Group event
     * @name GetGroupEventAttachments
     * @summary Get group event attachments
     * @request GET:/group-events/{id}/attachments/
     * @secure
     */
    getGroupEventAttachments: (
      id: number,
      query?: {
        media_id?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** Error information if the request was unsuccessful */
          error?: object;
          images?: {
            original?: string;
            thumbnail?: string;
          }[];
        },
        any
      >({
        path: `/group-events/${id}/attachments/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Group event
     * @name GetGroupEventImages
     * @summary Get group event images
     * @request GET:/group-events/{id}/images/
     * @secure
     */
    getGroupEventImages: (
      id: number,
      query?: {
        media_id?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: string[];
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/group-events/${id}/images/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Group event
     * @name GetGroupEventMedia
     * @summary Get group event media
     * @request GET:/group-events/{id}/media/
     * @secure
     */
    getGroupEventMedia: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: GroupEventMedia;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/group-events/${id}/media/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Group event
     * @name RateAGroupEvent
     * @summary Rate a group event
     * @request POST:/group-events/{id}/rate/
     * @secure
     */
    rateAGroupEvent: (id: number, data: GroupEventRatingBody, params: RequestParams = {}) =>
      this.request<
        {
          data?: GroupEventRating;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/group-events/${id}/rate/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  images = {
    /**
     * No description
     *
     * @tags Internal, CMS
     * @name ResizeImage
     * @summary Resize image
     * @request POST:/images/resizing
     */
    resizeImage: (
      data: {
        images: {
          path?: string;
        }[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            ok?: boolean;
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/images/resizing`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  news = {
    /**
     * No description
     *
     * @tags News, CMS
     * @name GetListNewsWithPagination
     * @summary Get list news with pagination
     * @request GET:/news/
     */
    getListNewsWithPagination: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        categories?: number[];
        /** @minLength 1 */
        search?: string;
        source?: ("INTERNAL" | "EXTERNAL")[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: NewsList[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/news/`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags News, CMS
     * @name CreateANew
     * @summary Create a new
     * @request POST:/news/
     * @secure
     */
    createANew: (data: NewsCreate, params: RequestParams = {}) =>
      this.request<
        {
          data?: News;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/news/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags News, CMS
     * @name GetListCategories
     * @summary Get list categories
     * @request GET:/news/categories/
     */
    getListCategories: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        /** Search by name, en_name */
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: NewCategory[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/news/categories/`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags News, CMS
     * @name CreateACategoryForNew
     * @summary Create a category for new
     * @request POST:/news/categories/
     * @secure
     */
    createACategoryForNew: (data: NewCategory, params: RequestParams = {}) =>
      this.request<
        {
          data?: NewCategory;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/news/categories/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags News, CMS
     * @name GetAllCategories
     * @summary Get all categories
     * @request GET:/news/categories/all/
     */
    getAllCategories: (params: RequestParams = {}) =>
      this.request<
        {
          data?: object;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/news/categories/all/`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags News, CMS
     * @name GetACategoryForNew
     * @summary Get a category for new
     * @request GET:/news/categories/{id}/
     */
    getACategoryForNew: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: NewCategory;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/news/categories/${id}/`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags News, CMS
     * @name UpdateACategoryForNew
     * @summary Update a category for new
     * @request PUT:/news/categories/{id}/
     * @secure
     */
    updateACategoryForNew: (id: number, data: NewCategory, params: RequestParams = {}) =>
      this.request<
        {
          data?: NewCategory;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/news/categories/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags News, CMS
     * @name GetDetailNew
     * @summary Get detail new
     * @request GET:/news/{id}/
     */
    getDetailNew: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: News;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/news/${id}/`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags News, CMS
     * @name UpdateANew
     * @summary Update a new
     * @request PUT:/news/{id}/
     * @secure
     */
    updateANew: (id: number, data: NewsCreate, params: RequestParams = {}) =>
      this.request<
        {
          data?: News;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/news/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  notificationTemplates = {
    /**
     * No description
     *
     * @tags Notification template, CMS
     * @name GetListNotificationTemplates
     * @summary Get list notification templates
     * @request GET:/notification_templates/
     * @secure
     */
    getListNotificationTemplates: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        /** search by name, title, en_title */
        search?: string;
        /** filter status */
        status?: "Created" | "Started" | "Processing" | "Partial Failure" | "Completed" | "Failed";
        /** filter noti by recurrence type */
        recurrence?: string;
        /** filter type by noti type id */
        type?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: NotificationTemplate[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/notification_templates/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification template, CMS
     * @name CreateNewNotificationTemplate
     * @summary Create new notification template
     * @request POST:/notification_templates/
     * @secure
     */
    createNewNotificationTemplate: (data: CreateNotificationTemplateRequest, params: RequestParams = {}) =>
      this.request<
        {
          data?: NotificationTemplate;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/notification_templates/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification template, CMS
     * @name GetANotificationTemplate
     * @summary Get a notification template
     * @request GET:/notification_templates/{id}/
     * @secure
     */
    getANotificationTemplate: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          data?: NotificationTemplate;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/notification_templates/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification template, CMS
     * @name UpdateANotificationTemplate
     * @summary Update a notification template
     * @request PUT:/notification_templates/{id}/
     * @secure
     */
    updateANotificationTemplate: (id: string, data: UpdateNotificationTemplateRequest, params: RequestParams = {}) =>
      this.request<
        {
          data?: NotificationTemplate;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/notification_templates/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Retrieves the next occurrences of a specified notification template based on its primary key (PK).
     *
     * @tags Notification template, CMS
     * @name RetrieveNextOccurrences
     * @summary Retrieve Next Occurrences
     * @request GET:/notification_templates/{id}/next-occurrences/
     * @secure
     */
    retrieveNextOccurrences: (
      id: number,
      query?: {
        /** The number of occurrences to return. Defaults to 1 if not specified. */
        count?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** An array of datetime strings representing the next occurrences of the notification template. */
          next_occurrences?: string[];
        },
        any
      >({
        path: `/notification_templates/${id}/next-occurrences/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification template, CMS
     * @name PushNotificationTemplate
     * @summary Push notification template
     * @request POST:/notification_templates/{id}/notifications/
     * @secure
     */
    pushNotificationTemplate: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          data?: object;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/notification_templates/${id}/notifications/`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  notificationTypes = {
    /**
     * No description
     *
     * @tags Notification type, CMS
     * @name GetListNotificationTypes
     * @summary Get list notification types
     * @request GET:/notification_types/
     */
    getListNotificationTypes: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        /** search by name and en_name */
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: NotificationTypeList[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/notification_types/`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification type
     * @name CreateANotificationTypes
     * @summary Create a notification types
     * @request POST:/notification_types/
     * @secure
     */
    createANotificationTypes: (
      data: {
        /**
         * @minLength 1
         * @maxLength 50
         */
        en_name: string;
        /** @format uri */
        icon: string | null;
        /**
         * @minLength 1
         * @maxLength 50
         */
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: NotificationType;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/notification_types/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification type, CMS
     * @name GetANotificationType
     * @summary Get a notification type
     * @request GET:/notification_types/{id}/
     */
    getANotificationType: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          data?: NotificationType;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/notification_types/${id}/`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification type
     * @name UpdateANotificationType
     * @summary Update a notification type
     * @request PUT:/notification_types/{id}/
     * @secure
     */
    updateANotificationType: (
      id: string,
      data: {
        /**
         * @minLength 1
         * @maxLength 50
         */
        en_name?: string | null;
        /** @format uri */
        icon?: string | null;
        /**
         * @minLength 1
         * @maxLength 50
         */
        name?: string | null;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: NotificationType;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/notification_types/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  notifications = {
    /**
     * No description
     *
     * @tags Notification
     * @name GetListNotifications
     * @summary Get list notifications
     * @request GET:/notifications/
     * @secure
     */
    getListNotifications: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: Notification[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/notifications/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification
     * @name GetListUnreadNotifications
     * @summary Get list unread notifications
     * @request GET:/notifications/unread_count/
     * @secure
     */
    getListUnreadNotifications: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: UnreadNotificationResponse;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/notifications/unread_count/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification
     * @name GetANotification
     * @summary Get a notification
     * @request GET:/notifications/{id}/
     * @secure
     */
    getANotification: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          data?: Notification;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/notifications/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification
     * @name MarkANotificationAsRead
     * @summary Mark a notification as read
     * @request POST:/notifications/{id}/read/
     * @secure
     */
    markANotificationAsRead: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          data?: object;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/notifications/${id}/read/`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  oasisPrograms = {
    /**
     * No description
     *
     * @tags Oasis program
     * @name GetOasisProgram
     * @summary Get oasis program
     * @request GET:/oasis-programs/
     * @secure
     */
    getOasisProgram: (params: RequestParams = {}) =>
      this.request<
        {
          data?: {
            advising: {
              admission_cv?: boolean;
              idp?: boolean;
            };
            orientation_tests: {
              grade?: string;
              passed?: boolean;
              submitted?: boolean;
              type?: "Academic Integrity Test" | "Code of Conduct Test";
            };
            projects: {
              clubs?: StudentProject[];
              community_services?: StudentProject[];
            };
            skill: {
              career_readiness?: SkillReadiness[];
              college_readiness?: SkillReadiness[];
              pre_graduation_cv?: string | null;
            };
            university_awards: Honor[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/oasis-programs/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Oasis program
     * @name GetAdvisingGroup
     * @summary Get advising group
     * @request GET:/oasis-programs/advising-group/
     * @secure
     */
    getAdvisingGroup: (params: RequestParams = {}) =>
      this.request<
        {
          data?: AdvisingGroup;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/oasis-programs/advising-group/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Oasis program (CMS), CMS
     * @name GetListAdvisingGroupsWithPagination
     * @summary Get list advising groups with pagination
     * @request GET:/oasis-programs/advising-groups/
     * @secure
     */
    getListAdvisingGroupsWithPagination: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        /** Search by generic advisor name/email or session name. */
        search?: string;
        /** @format date */
        date?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: WebAdvisingGroupList[];
          };
        },
        {
          /** Error information if the request was unsuccessful */
          error?: object;
        }
      >({
        path: `/oasis-programs/advising-groups/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Oasis program (CMS), CMS
     * @name CreateAnAdvisingGroup
     * @summary Create an advising group
     * @request POST:/oasis-programs/advising-groups/
     * @secure
     */
    createAnAdvisingGroup: (data: WebAdvisingGroupAdd, params: RequestParams = {}) =>
      this.request<
        {
          data?: WebAdvisingGroup;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/oasis-programs/advising-groups/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Oasis program (CMS), CMS
     * @name ImportAdvisingGroupsViaExcelFile
     * @summary Import advising groups via excel file
     * @request POST:/oasis-programs/advising-groups/import/
     * @secure
     */
    importAdvisingGroupsViaExcelFile: (
      data: {
        filename: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          ok?: boolean;
        },
        any
      >({
        path: `/oasis-programs/advising-groups/import/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Oasis program (CMS), CMS
     * @name DeleteAnAdvisionGroup
     * @summary Delete an advision group
     * @request DELETE:/oasis-programs/advising-groups/{id}/
     * @secure
     */
    deleteAnAdvisionGroup: (id: string, params: RequestParams = {}) =>
      this.request<
        void,
        | {
            /** Error information if the request was unsuccessful */
            error?: object;
          }
        | DislikeEventRequest
      >({
        path: `/oasis-programs/advising-groups/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Oasis program (CMS), CMS
     * @name GetAnAdvisingGroup
     * @summary Get an advising group
     * @request GET:/oasis-programs/advising-groups/{id}/
     * @secure
     */
    getAnAdvisingGroup: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: WebAdvisingGroup;
        },
        {
          /** Error information if record not found. */
          error?: object;
        }
      >({
        path: `/oasis-programs/advising-groups/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Oasis program (CMS), CMS
     * @name UpdateAnAdvisingGroup
     * @summary Update an advising group
     * @request PUT:/oasis-programs/advising-groups/{id}/
     * @secure
     */
    updateAnAdvisingGroup: (id: number, data: WebAdvisingGroupAdd, params: RequestParams = {}) =>
      this.request<
        {
          data?: WebAdvisingGroup;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        {
          /** Error information if the request was unsuccessful */
          error?: object;
        }
      >({
        path: `/oasis-programs/advising-groups/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Oasis program (CMS), CMS
     * @name GetListAdvisorsWithPagination
     * @summary Get list advisors with pagination
     * @request GET:/oasis-programs/advisors/
     * @secure
     */
    getListAdvisorsWithPagination: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        /** Search by advisor name or email. */
        search?: string;
        lecturer?: boolean[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: WebAdvisor[];
          };
        },
        {
          /** Error information if the request was unsuccessful */
          error?: object;
        }
      >({
        path: `/oasis-programs/advisors/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Oasis program (CMS), CMS
     * @name CreateAnAdvisor
     * @summary Create an advisor
     * @request POST:/oasis-programs/advisors/
     * @secure
     */
    createAnAdvisor: (data: WebAdvisorAdd, params: RequestParams = {}) =>
      this.request<
        {
          data?: WebAdvisor;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/oasis-programs/advisors/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Oasis program (CMS), CMS
     * @name GetAllAdvisors
     * @summary Get all advisors
     * @request GET:/oasis-programs/advisors/all/
     * @secure
     */
    getAllAdvisors: (params: RequestParams = {}) =>
      this.request<
        {
          data?: WebAdvisor[];
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/oasis-programs/advisors/all/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Oasis program (CMS), CMS
     * @name DeleteAnAdvisor
     * @summary Delete an advisor
     * @request DELETE:/oasis-programs/advisors/{id}/
     * @secure
     */
    deleteAnAdvisor: (id: string, params: RequestParams = {}) =>
      this.request<
        void,
        | {
            /** Error information if the request was unsuccessful */
            error?: object;
          }
        | DislikeEventRequest
      >({
        path: `/oasis-programs/advisors/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Oasis program (CMS), CMS
     * @name GetAnAdvisor
     * @summary Get an advisor
     * @request GET:/oasis-programs/advisors/{id}/
     * @secure
     */
    getAnAdvisor: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: WebAdvisor;
        },
        {
          /** Error information if record not found. */
          error?: object;
        }
      >({
        path: `/oasis-programs/advisors/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Oasis program (CMS), CMS
     * @name UpdateAnAdvisor
     * @summary Update an advisor
     * @request PUT:/oasis-programs/advisors/{id}/
     * @secure
     */
    updateAnAdvisor: (id: number, data: WebAdvisorAdd, params: RequestParams = {}) =>
      this.request<
        {
          data?: WebAdvisor;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        {
          /** Error information if the request was unsuccessful */
          error?: object;
        }
      >({
        path: `/oasis-programs/advisors/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Oasis program (CMS), CMS
     * @name GetListCohorts
     * @summary Get list cohorts
     * @request GET:/oasis-programs/cohorts/
     */
    getListCohorts: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        /** Search by start year. */
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: {
              cohort: string;
              id: number;
              name?: string;
              number_of_students: number;
              start_year?: number;
            }[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/oasis-programs/cohorts/`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Oasis program (CMS), CMS
     * @name GetListOrientationTestsWithPagination
     * @summary Get list orientation tests with pagination
     * @request GET:/oasis-programs/orientation-tests/
     */
    getListOrientationTestsWithPagination: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        cohort?: number;
        /** Search by student number or student name. */
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: OrientationTest[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/oasis-programs/orientation-tests/`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Oasis program (CMS), CMS
     * @name ImportOrientationTestsViaExcelFile
     * @summary Import orientation tests via excel file
     * @request POST:/oasis-programs/orientation-tests/import/
     * @secure
     */
    importOrientationTestsViaExcelFile: (
      data: {
        cohort: string;
        filename: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          ok?: boolean;
        },
        any
      >({
        path: `/oasis-programs/orientation-tests/import/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Oasis program
     * @name DeleteOasisProgramPreGraduationCv
     * @summary Delete oasis program pre graduation cv
     * @request DELETE:/oasis-programs/pre-graduation-cv/
     */
    deleteOasisProgramPreGraduationCv: (params: RequestParams = {}) =>
      this.request<
        {
          data?: any;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/oasis-programs/pre-graduation-cv/`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Oasis program
     * @name UpdateOasisProgramPreGraduationCv
     * @summary Update oasis program pre graduation cv
     * @request PUT:/oasis-programs/pre-graduation-cv/
     */
    updateOasisProgramPreGraduationCv: (
      data: {
        pre_graduation_cv?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: any;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/oasis-programs/pre-graduation-cv/`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Oasis program (CMS), CMS
     * @name GetOasisProgramProfiles
     * @summary Get oasis program profiles
     * @request GET:/oasis-programs/profiles/
     */
    getOasisProgramProfiles: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        cohort?: number;
        /** Search by student number or student name. */
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: OasisProgramProfile[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/oasis-programs/profiles/`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Oasis program (CMS), CMS
     * @name ImportOasisProgramProfileViaExcelFile
     * @summary Import oasis program profile via excel file
     * @request POST:/oasis-programs/profiles/import/
     * @secure
     */
    importOasisProgramProfileViaExcelFile: (
      data: {
        filename: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          ok?: boolean;
        },
        any
      >({
        path: `/oasis-programs/profiles/import/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Oasis program (CMS), CMS
     * @name GetListSkillsWithPagination
     * @summary Get list skills with pagination
     * @request GET:/oasis-programs/skills/
     */
    getListSkillsWithPagination: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        cohort?: number;
        /** Search by skill name or cohort. */
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: Skill[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/oasis-programs/skills/`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Oasis program (CMS), CMS
     * @name CreateASkill
     * @summary Create a skill
     * @request POST:/oasis-programs/skills/
     * @secure
     */
    createASkill: (data: SkillAdd, params: RequestParams = {}) =>
      this.request<
        {
          data?: Skill;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/oasis-programs/skills/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Oasis program (CMS), CMS
     * @name UpdateOasisProgram
     * @summary Update oasis program
     * @request PUT:/oasis-programs/update/{id}/
     */
    updateOasisProgram: (
      id: number,
      data: {
        workshop_type?: "COLLEGE_READINESS" | "CAREER_READINESS";
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: OasisProgram;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/oasis-programs/update/${id}/`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Oasis program (CMS), CMS
     * @name GetListWorkshopsWithPagination
     * @summary Get list workshops with pagination
     * @request GET:/oasis-programs/workshops/
     */
    getListWorkshopsWithPagination: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        skill?: number;
        /** Search by skill name or cohort. */
        search?: string;
        type?: ("COLLEGE_READINESS" | "CAREER_READINESS")[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: Workshop[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/oasis-programs/workshops/`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Oasis program (CMS), CMS
     * @name CreateAWorkshop
     * @summary Create a workshop
     * @request POST:/oasis-programs/workshops/
     * @secure
     */
    createAWorkshop: (data: WorkshopAdd, params: RequestParams = {}) =>
      this.request<
        {
          data?: Workshop;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/oasis-programs/workshops/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Oasis program (CMS), CMS
     * @name ImportWorkshopsViaExcelFile
     * @summary Import workshops via excel file
     * @request POST:/oasis-programs/workshops/import/
     * @secure
     */
    importWorkshopsViaExcelFile: (
      data: {
        filename: string;
        skill_id: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          ok?: boolean;
        },
        any
      >({
        path: `/oasis-programs/workshops/import/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  serviceRatings = {
    /**
     * No description
     *
     * @tags Facility (CMS), CMS
     * @name CreateAServiceRating
     * @summary Create a service rating
     * @request POST:/service-ratings/
     * @secure
     */
    createAServiceRating: (data: WebServiceRatingPayload, params: RequestParams = {}) =>
      this.request<
        {
          data?: WebServiceRating;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/service-ratings/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  speakers = {
    /**
     * No description
     *
     * @tags Event, CMS
     * @name GetListSpeakers
     * @summary Get list speakers
     * @request GET:/speakers/
     */
    getListSpeakers: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        /** @minLength 1 */
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: SpeakerList[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/speakers/`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event, CMS
     * @name CreateASpeaker
     * @summary Create a speaker
     * @request POST:/speakers/
     * @secure
     */
    createASpeaker: (data: Speaker, params: RequestParams = {}) =>
      this.request<
        {
          data?: Speaker;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/speakers/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event, CMS
     * @name GetDetailSpeaker
     * @summary Get detail speaker
     * @request GET:/speakers/{id}/
     */
    getDetailSpeaker: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: Speaker;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/speakers/${id}/`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event, CMS
     * @name UpdateASpeaker
     * @summary Update a speaker
     * @request PUT:/speakers/{id}/
     * @secure
     */
    updateASpeaker: (id: number, data: Speaker, params: RequestParams = {}) =>
      this.request<
        {
          data?: Speaker;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/speakers/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  storages = {
    /**
     * No description
     *
     * @tags Internal, CMS
     * @name GetAllCacheKeys
     * @summary Get all cache keys
     * @request GET:/storages/cache/
     * @secure
     */
    getAllCacheKeys: (
      query?: {
        /** Optional to search keys */
        pattern?: string;
        type?: "string" | "list" | "set" | "zset" | "hash" | "stream";
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: {
              /** Cache key name */
              key?: string;
              /** Memory usage of the cache key in bytes */
              size?: number;
              /** Time to live (in seconds) before the key expires */
              ttl?: number;
              type?: string;
            }[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/storages/cache/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Internal, CMS
     * @name DeleteACacheKey
     * @summary Delete a cache key
     * @request DELETE:/storages/cache/detail/
     * @secure
     */
    deleteACacheKey: (
      query: {
        /** The cache key to delete */
        key: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        void,
        | {
            /** @example "Cache key not found." */
            detail?: string;
          }
        | {
            /** @example "Error deleting cache key." */
            detail?: string;
          }
      >({
        path: `/storages/cache/detail/`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Internal, CMS
     * @name GetValueOfACacheKey
     * @summary Get value of a cache key
     * @request GET:/storages/cache/detail/
     * @secure
     */
    getValueOfACacheKey: (
      query: {
        /** The cache key to retrieve */
        key: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /**
           * date time of when the cache key was created
           * @format datetime
           * @example "2024-10-25T15:30:00Z"
           */
          created_at?: string;
          /** Cache key name */
          key?: string;
          /** Memory usage of the cache key in bytes */
          size?: number;
          /** Time to live (in seconds) before the key expires */
          ttl?: number;
          /** Value of cache key */
          value?: string;
        },
        {
          /** @example "Cache key not found." */
          detail?: string;
        }
      >({
        path: `/storages/cache/detail/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  studentServices = {
    /**
     * No description
     *
     * @tags Student service
     * @name GetAllStudentService
     * @summary Get all student service
     * @request GET:/student-services/all/
     * @secure
     */
    getAllStudentService: (params: RequestParams = {}) =>
      this.request<
        {
          data?: StudentService[];
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/student-services/all/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Student service
     * @name GetDetailStudentService
     * @summary Get detail student service
     * @request GET:/student-services/{id}/
     * @secure
     */
    getDetailStudentService: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: StudentService;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/student-services/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  supportRequests = {
    /**
     * No description
     *
     * @tags Support request, CMS
     * @name GetListSupportRequests
     * @summary Get list support requests
     * @request GET:/support-requests/
     * @secure
     */
    getListSupportRequests: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        /** search by subject, description or case type */
        search?: string;
        status?: ("New" | "In-Progress" | "Resolved" | "Closed")[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: SupportRequest[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/support-requests/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Support request
     * @name CreateASupportRequest
     * @summary Create a support request
     * @request POST:/support-requests/
     * @secure
     */
    createASupportRequest: (data: SupportRequestAdd, params: RequestParams = {}) =>
      this.request<
        {
          data?: SupportRequest;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/support-requests/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Support request
     * @name GetAllDepartments
     * @summary Get all departments
     * @request GET:/support-requests/departments/all
     */
    getAllDepartments: (params: RequestParams = {}) =>
      this.request<
        {
          data?: {
            case_owner_name?: string;
            case_types?: {
              name?: string;
              value?: string;
            }[];
            id?: number;
            name?: string;
            value?: string;
          }[];
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/support-requests/departments/all`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Support request
     * @name GetASupportRequest
     * @summary Get a support request
     * @request GET:/support-requests/{id}/
     * @secure
     */
    getASupportRequest: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: SupportRequest;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/support-requests/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Support request
     * @name CancelASupportRequest
     * @summary Cancel a support request
     * @request PUT:/support-requests/{id}/cancel
     * @secure
     */
    cancelASupportRequest: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: {
            ok?: boolean;
          };
        },
        any
      >({
        path: `/support-requests/${id}/cancel`,
        method: "PUT",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  surveys = {
    /**
     * No description
     *
     * @tags Survey
     * @name RetrieveTheSurveyForm
     * @summary Retrieve the survey form
     * @request GET:/surveys/
     * @secure
     */
    retrieveTheSurveyForm: (
      query?: {
        /** The type of service to retrieve the corresponding survey form. */
        service_type?: "COUNSELLING";
        /** For filter survey form by form id */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: SurveyForm;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        | {
            /** Error message describing what went wrong */
            error?: string;
          }
        | {
            /** Error message indicating that the survey form was not found */
            error?: string;
          }
      >({
        path: `/surveys/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Survey(CMS)
     * @name GetListSurveyFormWithPagination
     * @summary Get list survey form with pagination
     * @request GET:/surveys/forms/
     * @secure
     */
    getListSurveyFormWithPagination: (
      query?: {
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: SurveyFormAdminList[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        | {
            /** Error message describing what went wrong */
            error?: string;
          }
        | {
            /** Error message indicating that the survey form was not found */
            error?: string;
          }
      >({
        path: `/surveys/forms/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Survey(CMS)
     * @name CreateASurveyForm
     * @summary Create a survey form
     * @request POST:/surveys/forms/
     * @secure
     */
    createASurveyForm: (data: SurveyFormCreate, params: RequestParams = {}) =>
      this.request<
        {
          data?: SurveyFormAdmin;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        | {
            /** Error message describing what went wrong */
            error?: string;
          }
        | {
            /** Error message indicating that the survey form was not found */
            error?: string;
          }
      >({
        path: `/surveys/forms/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Survey(CMS)
     * @name GetListAnswersWithPagination
     * @summary Get list answers with pagination
     * @request GET:/surveys/forms/answers/
     * @secure
     */
    getListAnswersWithPagination: (params: RequestParams = {}) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: SurveyAnswer[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        | {
            /** Error message describing what went wrong */
            error?: string;
          }
        | {
            /** Error message indicating that the survey form was not found */
            error?: string;
          }
      >({
        path: `/surveys/forms/answers/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Survey(CMS)
     * @name GetDetailAnswer
     * @summary Get detail answer
     * @request GET:/surveys/forms/answers/{answer_id}
     * @secure
     */
    getDetailAnswer: (answerId: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: SurveyAnswer;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        | {
            /** Error message describing what went wrong */
            error?: string;
          }
        | {
            /** Error message indicating that the survey form was not found */
            error?: string;
          }
      >({
        path: `/surveys/forms/answers/${answerId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Survey(CMS)
     * @name DeleteASurveyForm
     * @summary Delete a survey form
     * @request DELETE:/surveys/forms/{survey_id}/
     * @secure
     */
    deleteASurveyForm: (surveyId: string, params: RequestParams = {}) =>
      this.request<
        void,
        {
          /** @example "Survey form not found." */
          detail?: string;
        }
      >({
        path: `/surveys/forms/${surveyId}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Survey(CMS)
     * @name GetDetailASurveyForm
     * @summary Get detail a survey form
     * @request GET:/surveys/forms/{survey_id}/
     * @secure
     */
    getDetailASurveyForm: (surveyId: string, params: RequestParams = {}) =>
      this.request<
        {
          data?: SurveyFormAdmin;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        | {
            /** Error message describing what went wrong */
            error?: string;
          }
        | {
            /** Error message indicating that the survey form was not found */
            error?: string;
          }
      >({
        path: `/surveys/forms/${surveyId}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Survey(CMS)
     * @name UpdateSurveyForm
     * @summary Update survey form
     * @request PUT:/surveys/forms/{survey_id}/
     * @secure
     */
    updateSurveyForm: (surveyId: string, data: SurveyFormUpdate, params: RequestParams = {}) =>
      this.request<
        {
          data?: SurveyFormAdmin;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        | {
            /** Error message describing what went wrong */
            error?: string;
          }
        | {
            /** Error message indicating that the survey form was not found */
            error?: string;
          }
      >({
        path: `/surveys/forms/${surveyId}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Survey
     * @name GetListSurveyAnswersWithPagination
     * @summary Get list survey answers with pagination
     * @request GET:/surveys/my-surveys/
     * @secure
     */
    getListSurveyAnswersWithPagination: (params: RequestParams = {}) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: MySurveyAnswer[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        | {
            /** Error message describing what went wrong */
            error?: string;
          }
        | {
            /** Error message indicating that the survey form was not found */
            error?: string;
          }
      >({
        path: `/surveys/my-surveys/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Survey
     * @name GetDetailSurveyAnswer
     * @summary Get detail survey answer
     * @request GET:/surveys/my-surveys/{answer_id}
     * @secure
     */
    getDetailSurveyAnswer: (answerId: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: MySurveyAnswer;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        | {
            /** Error message describing what went wrong */
            error?: string;
          }
        | {
            /** Error message indicating that the survey form was not found */
            error?: string;
          }
      >({
        path: `/surveys/my-surveys/${answerId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Survey
     * @name SubmitAnswersForASurveyForm
     * @summary Submit answers for a survey form
     * @request POST:/surveys/{survey_id}/submit/
     * @secure
     */
    submitAnswersForASurveyForm: (
      surveyId: number,
      data: {
        /** List of response to the survey questions */
        answers?: {
          /**
           * ID of the question being answered
           * @example 2
           */
          question_id?: number;
          value?: string[];
        }[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            attachment_ids?: string[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        | {
            /**
             * Error message describing what went wrong
             * @example "Invalid survey ID or missing responses."
             */
            error?: string;
          }
        | {
            /**
             * Error message indicating that the survey or a question was not found
             * @example "Survey not found."
             */
            error?: string;
          }
      >({
        path: `/surveys/${surveyId}/submit/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  targetCustomers = {
    /**
     * No description
     *
     * @tags Target customer, CMS
     * @name GetListTargetCustomers
     * @summary Get list target customers
     * @request GET:/target_customers/
     * @secure
     */
    getListTargetCustomers: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        /** page size within the paginated result set. */
        page_size?: number;
        /** Search by name. */
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: ReadTargetCustomer[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/target_customers/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Target customer, CMS
     * @name CreateATargetCustomer
     * @summary Create a target customer
     * @request POST:/target_customers/
     * @secure
     */
    createATargetCustomer: (data: CreateTargetCustomerRequest, params: RequestParams = {}) =>
      this.request<
        {
          data?: CreateTargetCustomerRequest;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/target_customers/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Target customer
     * @name ImportUserSetForATargetCustomer
     * @summary Import user set for a target customer
     * @request POST:/target_customers/import_users/
     * @secure
     */
    importUserSetForATargetCustomer: (
      data: {
        /** @format binary */
        file_url: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: ImportTargetCustomerResponse;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/target_customers/import_users/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Target customer, CMS
     * @name GetATargetCustomer
     * @summary Get a target customer
     * @request GET:/target_customers/{id}/
     * @secure
     */
    getATargetCustomer: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          data?: TargetCustomer;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/target_customers/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Target customer, CMS
     * @name UpdateATargetCustomer
     * @summary Update a target customer
     * @request PUT:/target_customers/{id}/
     * @secure
     */
    updateATargetCustomer: (id: string, data: UpdateTargetCustomerRequest, params: RequestParams = {}) =>
      this.request<
        {
          data?: UpdateTargetCustomerRequest;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/target_customers/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Target customer, CMS
     * @name GetAllRecipientOfTargetCustomer
     * @summary Get all recipient of target customer
     * @request GET:/target_customers/{id}/recipients/
     * @secure
     */
    getAllRecipientOfTargetCustomer: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          data?: UserBasicInfo[];
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/target_customers/${id}/recipients/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  userDevices = {
    /**
     * No description
     *
     * @tags User device
     * @name GetListUserDevices
     * @summary Get list user devices
     * @request GET:/user_devices/
     * @secure
     */
    getListUserDevices: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: UserDevice[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/user_devices/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User device
     * @name CreateAUserDevice
     * @summary Create a user device
     * @request POST:/user_devices/
     * @secure
     */
    createAUserDevice: (data: UserDevice, params: RequestParams = {}) =>
      this.request<
        {
          data?: UserDevice;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/user_devices/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User device
     * @name GetAUserDevice
     * @summary Get a user device
     * @request GET:/user_devices/{id}/
     * @secure
     */
    getAUserDevice: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          data?: UserDevice;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/user_devices/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User device
     * @name UpdateAUserDevice
     * @summary Update a user device
     * @request PUT:/user_devices/{id}/
     * @secure
     */
    updateAUserDevice: (id: string, data: UserDevice, params: RequestParams = {}) =>
      this.request<
        {
          data?: UserDevice;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/user_devices/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
}

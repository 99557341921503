import {
  createFileRoute,
  useNavigate,
  useParams,
} from "@tanstack/react-router";
import { notification } from "antd";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components";
import useYupResolver from "@/hooks/useYupResolver";
import { mappingContentToBase64 } from "@/lib/utils";
import { WebFacilityPayload } from "@/types/Api";

import FacilityDetailForm from "../../../-components/DetailForms/FacilityDetailForm";
import { facilitySchema } from "../../../-data";
import { useFacilitiesDetails, useUpdateFacility } from "../../../-data/hooks";
import { facilityDetailLoader } from "../../../-data/loaders";

export const Route = createFileRoute(
  "/_auth/_dashboard/facilities/management/_detail/$id/edit",
)({
  component: () => <EditFacilityPage />,
  loader: facilityDetailLoader,
  beforeLoad: () => {
    return {
      title: t("menu.edit", { ns: "dashboard" }),
    };
  },
});

function EditFacilityPage() {
  const { id } = useParams({
    from: "/_auth/_dashboard/facilities/management/_detail/$id/edit",
  });
  const { data } = useFacilitiesDetails(id);
  const { resolver } = useYupResolver<WebFacilityPayload>(facilitySchema);
  const form = useForm<WebFacilityPayload>({
    defaultValues: data,
    resolver,
  });
  const { t } = useTranslation("dashboard");
  const navigate = useNavigate();
  const { mutate, isPending } = useUpdateFacility();

  const handleSubmit = (params: WebFacilityPayload) => {
    mutate(
      { id, data: mappingContentToBase64(params, "description") },
      {
        onSuccess: () => {
          notification.success({
            message: t("menu.success"),
            description: t("menu.update.success", {
              what: t("menu.facilities.facility", "facility").toLowerCase(),
            }),
          });
          void navigate({ to: "/facilities/management" });
        },
        onError: () => {
          notification.error({
            message: t("menu.error"),
            description: t("menu.update.error", {
              what: t("menu.facilities.facility", "facility").toLowerCase(),
            }),
          });
        },
      },
    );
  };

  return (
    <MainDashboard>
      <FacilityDetailForm
        isEdit
        onClose={() =>
          void navigate({ to: "/facilities/management/$id", params: { id } })
        }
        title={t("menu.edit")}
        form={form}
        isSubmitLoading={isPending}
        onSubmit={handleSubmit}
      />
    </MainDashboard>
  );
}

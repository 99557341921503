import { Form, Input, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { WebEventServicePayload, Event } from "@/types/Api";

interface Props {
  hasDefaultValue?: boolean;
  isEditMode: boolean;
  requestFacility?: WebEventServicePayload & Event["service"];
}

const CateringDuringEvent = ({
  hasDefaultValue,
  isEditMode,
  requestFacility,
}: Props) => {
  const { t } = useTranslation("dashboard");

  return (
    <div className="col-span-2 mt-4">
      <Typography className="font-medium">
        {t("menu.facilities.cateringDuringEvent")}
      </Typography>
      <div className="flex gap-4 mt-2 p-4 px-6 border border-solid border-[#D9D9D9]">
        <Form.Item
          name={["service", "catering_service_form"]}
          label={
            <Typography className="font-medium">
              {t("menu.facilities.formOfService")}
            </Typography>
          }
          initialValue={
            hasDefaultValue && requestFacility?.catering_service_form
              ? requestFacility.catering_service_form
              : undefined
          }
          className="flex-1"
        >
          <Input
            className={hasDefaultValue ? "!text-gray-800" : ""}
            disabled={!isEditMode}
            placeholder={t("menu.editHere")}
          />
        </Form.Item>
        <Form.Item
          name={["service", "catering_people_count"]}
          label={
            <Typography className="font-medium">
              {t("menu.facilities.numberOfPeople")}
            </Typography>
          }
          initialValue={
            hasDefaultValue && requestFacility?.catering_people_count
              ? requestFacility.catering_people_count
              : undefined
          }
          className="flex-1"
        >
          <Input
            className={hasDefaultValue ? "!text-gray-800" : ""}
            disabled={!isEditMode}
            placeholder={t("menu.editHere")}
          />
        </Form.Item>

        <Form.Item
          name={["service", "catering_unit_price"]}
          label={
            <Typography className="font-medium">
              {t("menu.facilities.unitPrice")}
            </Typography>
          }
          initialValue={
            hasDefaultValue && requestFacility?.catering_unit_price
              ? requestFacility.catering_unit_price
              : undefined
          }
          className="flex-1"
        >
          <Input
            className={hasDefaultValue ? "!text-gray-800" : ""}
            disabled={!isEditMode}
            placeholder={t("menu.editHere")}
          />
        </Form.Item>
      </div>
    </div>
  );
};

export default CateringDuringEvent;

import { TFunction } from "i18next";
import * as yup from "yup";

import { validateThumbnail } from "@/lib/utils";
import { NotiRecurrenceForm } from "@/types";
import { NotificationTemplate } from "@/types/Api";

type NotiAction = Exclude<
  NotificationTemplate["action_type"],
  null | undefined
>;

export const NOTIFICATION_ACTIONS: {
  label: NotiAction;
  value: NotiAction;
}[] = [
  {
    label: "External Link",
    value: "External Link",
  },
  {
    label: "Internal Link",
    value: "Internal Link",
  },
  {
    label: "Feature",
    value: "Feature",
  },
];

export const NOTIFICATION_STATUS: {
  label: NotificationTemplate["status"];
  value: NotificationTemplate["status"];
}[] = [
  {
    label: "Created",
    value: "Created",
  },
  {
    label: "Processing",
    value: "Processing",
  },
  {
    label: "Completed",
    value: "Completed",
  },
];

export const WEEKDAYS = {
  MONDAY: "MONDAY",
  TUESDAY: "TUESDAY",
  WEDNESDAY: "WEDNESDAY",
  THURSDAY: "THURSDAY",
  FRIDAY: "FRIDAY",
  SATURDAY: "SATURDAY",
  SUNDAY: "SUNDAY",
} as const;

export const LOCALIZED_WEEKDAYS = {
  vi: {
    MONDAY: "Thứ Hai",
    TUESDAY: "Thứ Ba",
    WEDNESDAY: "Thứ Tư",
    THURSDAY: "Thứ Năm",
    FRIDAY: "Thứ Sáu",
    SATURDAY: "Thứ Bảy",
    SUNDAY: "Chủ Nhật",
  },
  en: {
    MONDAY: "Monday",
    TUESDAY: "Tuesday",
    WEDNESDAY: "Wednesday",
    THURSDAY: "Thursday",
    FRIDAY: "Friday",
    SATURDAY: "Saturday",
    SUNDAY: "Sunday",
  },
} as const;

export const RECURRENCE = {
  NEVER: "NEVER",
  DAILY: "DAILY",
  WEEKLY: "WEEKLY",
  MONTHLY: "MONTHLY",
  YEARLY: "YEARLY",
} as const;

export const HOUR_ONLY = "HH:mm:ss";

export const mappingFormField: {
  recurrence: Record<keyof NotiRecurrenceForm, keyof NotificationTemplate>;
  noti: Partial<Record<keyof NotificationTemplate, keyof NotiRecurrenceForm>>;
} = {
  recurrence: {
    end: "end_date",
    start: "start_date",
    repeatEvery: "repeat_every",
    weekdays: "weekdays",
    type: "recurrence",
  },
  noti: {
    end_date: "end",
    start_date: "start",
    recurrence: "type",
    weekdays: "weekdays",
    repeat_every: "repeatEvery",
  },
} as const;

export const notificationSchema = (t: TFunction) =>
  yup.object().shape(
    {
      title: yup.string().required(
        t("menu.validation.required", {
          what: t("menu.vnTitle", { ns: "dashboard" }),
        }),
      ),
      en_title: yup.string().required(
        t("menu.validation.required", {
          what: t("menu.enTitle", { ns: "dashboard" }),
        }),
      ),
      content: yup.string().required(
        t("menu.validation.required", {
          what: t("menu.content", { ns: "dashboard" }),
        }),
      ),
      en_content: yup.string().required(
        t("menu.validation.required", {
          what: t("menu.enContent", { ns: "dashboard" }),
        }),
      ),
      type: yup.number().required(
        t("menu.validation.required", {
          what: t("menu.notification.type", { ns: "dashboard" }),
        }),
      ),
      related_event: yup.number().when(["to_all_user", "target_customer"], {
        is: (to_all_user?: boolean, target_customer?: number) =>
          !to_all_user && !target_customer,
        then: (schema) =>
          schema.required(
            t("menu.validation.required", {
              what: t("menu.events.event", { ns: "dashboard" }),
            }),
          ),
        otherwise: (schema) => schema.nullable(),
      }),
      target_customer: yup.number().when(["to_all_user", "related_event"], {
        is: (to_all_user?: boolean, target_customer?: number) =>
          !to_all_user && !target_customer,
        then: (schema) =>
          schema.required(
            t("menu.validation.required", {
              what: t("menu.targetCustomer.targetCustomer", {
                ns: "dashboard",
              }),
            }),
          ),
        otherwise: (schema) => schema.nullable(),
      }),
      to_all_user: yup.bool().nullable(),
      start_date: yup.string().required(
        t("menu.validation.required", {
          what: t("menu.startDate", { ns: "dashboard" }),
        }),
      ),
      time: yup.string().required(
        t("menu.validation.required", {
          what: t("menu.time", { ns: "dashboard" }),
        }),
      ),
    },
    [
      ["related_event", "to_all_user"],
      ["related_event", "target_customer"],
      ["to_all_user", "target_customer"],
    ],
  );

export const notiTypesSchema = (t: TFunction) =>
  yup.object().shape({
    name: yup.string().required(
      t("menu.validation.required", {
        what: t("menu.vnTitle", { ns: "dashboard" }),
      }),
    ),
    en_name: yup.string().required(
      t("menu.validation.required", {
        what: t("menu.enTitle", { ns: "dashboard" }),
      }),
    ),
    icon: yup
      .string()
      .required(
        t("menu.validation.required", {
          what: "Icon",
        }),
      )
      .test(
        "is-file-or-uri",
        "Icon must be a valid file or a valid URI",
        validateThumbnail,
      ),
  });

export const notiRecurrenceFormSchema = (t: TFunction) =>
  yup.object().shape({
    repeatEvery: yup
      .number()
      .required("Repeat every is required")
      .min(1, "Repeat every must be at least 1"),
    type: yup.string().required("Recurrence type is required"),
    weekdays: yup.array().required("Weekdays are required"),
    start: yup.string().required(
      t("menu.validation.required", {
        what: t("menu.startDate", { ns: "dashboard" }),
      }),
    ),
    end: yup
      .string()
      .required(
        t("menu.validation.required", {
          what: t("menu.endDate", { ns: "dashboard" }),
        }),
      )
      .test(
        "start-greater-than-end",
        t("menu.validation.greaterThan", {
          greater: t("menu.endDate", { ns: "dashboard" }),
          smaller: t("menu.startDate", { ns: "dashboard" }),
        }),
        function (end) {
          const { start } = this.parent as NotiRecurrenceForm;
          if (start && end) {
            return new Date(start) < new Date(end);
          }
          return true;
        },
      ),
  });

import {
  createFileRoute,
  useNavigate,
  useParams,
} from "@tanstack/react-router";
import { Form, Input, notification, Select, Typography } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { t } from "i18next";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { DatePicker, LayoutDetailsForm, MainDashboard } from "@/components";
import { DATE_ONLY } from "@/lib/constants";
import { filterSelectOptions } from "@/lib/utils";
import { WorkshopAdd } from "@/types/Api";

import { addWorkshopResolver, OASIS_WORKSHOPS_OPTIONS } from "../../../-data";
import { useCreateOASISWorkshop } from "../../../-data/hooks";

export const Route = createFileRoute(
  "/_auth/_dashboard/oasis/skill/_workshops/$id/add",
)({
  component: () => <OASISAddWorkshopsPage />,
  beforeLoad: () => {
    return {
      title: t("menu.add", { ns: "dashboard" }),
    };
  },
});

function OASISAddWorkshopsPage() {
  const { id } = useParams({
    from: "/_auth/_dashboard/oasis/skill/_workshops/$id/add",
  });
  const form = useForm<WorkshopAdd>({
    defaultValues: { en_name: "", name: "", skill: +id },
    resolver: addWorkshopResolver,
  });
  const { t } = useTranslation("dashboard");
  const navigate = useNavigate();
  const { mutate, isPending } = useCreateOASISWorkshop();

  const handleClose = () => {
    void navigate({
      to: "/oasis/skill/$id",
      params: { id: id as unknown as string },
    });
  };
  const handleSubmit = (data: WorkshopAdd) => {
    mutate(data, {
      onSuccess: () => {
        notification.success({
          message: t("menu.success"),
          description: t("menu.create.success", {
            what: t("menu.OASIS.workshops", "workshops").toLowerCase(),
          }),
        });
        handleClose();
      },
      onError: () => {
        notification.error({
          message: t("menu.error"),
          description: t("menu.create.error", {
            what: t("menu.OASIS.workshops", "workshops").toLowerCase(),
          }),
        });
      },
    });
  };

  return (
    <MainDashboard>
      <LayoutDetailsForm
        form={form}
        isEdit
        onClose={handleClose}
        onSubmit={handleSubmit}
        title={t("menu.add")}
        isSubmitLoading={isPending}
      >
        <Controller
          control={form.control}
          name="en_name"
          render={({ field, fieldState: { error } }) => {
            return (
              <Form.Item
                label={
                  <Typography.Text className="font-medium">
                    {t("menu.enTitle")}
                  </Typography.Text>
                }
                validateStatus={error ? "error" : ""}
                help={error?.message}
                required
              >
                <Input
                  {...field}
                  placeholder={t("menu.editHere")}
                  value={field.value}
                />
              </Form.Item>
            );
          }}
        />
        <Controller
          control={form.control}
          name="name"
          render={({ field, fieldState: { error } }) => {
            return (
              <Form.Item
                label={
                  <Typography.Text className="font-medium">
                    {t("menu.title")}
                  </Typography.Text>
                }
                validateStatus={error ? "error" : ""}
                help={error?.message}
                required
              >
                <Input
                  {...field}
                  placeholder={t("menu.editHere")}
                  value={field.value}
                />
              </Form.Item>
            );
          }}
        />
        <Controller
          control={form.control}
          name="type"
          render={({ field, fieldState: { error } }) => {
            return (
              <Form.Item
                label={
                  <Typography.Text className="font-medium">
                    {t("menu.OASIS.type")}
                  </Typography.Text>
                }
                validateStatus={error ? "error" : ""}
                help={error?.message}
                required
              >
                <Select
                  showArrow
                  options={OASIS_WORKSHOPS_OPTIONS}
                  placeholder={t("menu.select", {
                    what: t("menu.OASIS.type").toLowerCase(),
                  })}
                  filterOption={filterSelectOptions}
                  {...field}
                  value={field.value}
                />
              </Form.Item>
            );
          }}
        />
        <Controller
          control={form.control}
          name="date"
          render={({ field, fieldState: { error } }) => {
            return (
              <Form.Item
                label={
                  <Typography.Text className="font-medium">
                    {t("menu.date")}
                  </Typography.Text>
                }
                validateStatus={error ? "error" : ""}
                required
                help={error?.message}
              >
                <DatePicker
                  {...field}
                  className="w-full"
                  onChange={(e) => {
                    field.onChange(e ? dayjs(e).format(DATE_ONLY) : null);
                  }}
                  value={field.value ? dayjs(field.value, DATE_ONLY) : null}
                  placeholder={t("menu.select", {
                    what: t("menu.date"),
                  })}
                  disabledDate={(current: Dayjs | null) => {
                    return !!current && current < dayjs().endOf("day");
                  }}
                />
              </Form.Item>
            );
          }}
        />
      </LayoutDetailsForm>
    </MainDashboard>
  );
}

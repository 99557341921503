import { keepPreviousData, queryOptions } from "@tanstack/react-query";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";
import { atomFamily } from "jotai/vanilla/utils";

import atomWithDebounce from "@/jotai-atoms/atomWithDebounce";
import { getDefaultPaginateValues } from "@/lib/utils";
import { fetchApi, fetchApiClient } from "@/lib/utils/fetchUtils";
import { createQuery } from "@/lib/utils/tanstack-query";
import { PaginateFilterState } from "@/types";
import { BenefitType } from "@/types/Api";
import { BenefitsParams } from "@/types/ApiParams";

export type BenefitsListFilterType = BenefitsParams<"getListBenefits">;
const BENEFITS = "BENEFITS";
const createBenefitQuery = createQuery(BENEFITS);

const {
  getListBenefits,
  getDetailBenefit,
  getAllBenefitTypes,
  getAllBenefitTypesWithPagination,
} = fetchApiClient.benefits;

export const benefitsQueries = {
  list: createBenefitQuery(getListBenefits, {
    placeholderData: keepPreviousData,
  }),
  details: createBenefitQuery(getDetailBenefit),
  allTypes: createBenefitQuery(getAllBenefitTypes),
  types: createBenefitQuery(getAllBenefitTypesWithPagination),
  detailsType: (id: number) => {
    return queryOptions({
      queryKey: ["benefits-type-details", Number(id)],
      queryFn: async () => {
        try {
          const response = await fetchApi.get(
            `benefits/types/${id.toString()}/`,
          );
          const result: { data: BenefitType } = await response.json();
          return result.data;
        } catch (error) {
          throw new Error("Something went wrong!");
        }
      },
    });
  },
};

const benefitsListFilterAtom = atomWithDebounce<BenefitsListFilterType>(
  getDefaultPaginateValues(),
);

const benefitsTypesFilterAtom = atomWithDebounce<PaginateFilterState>(
  getDefaultPaginateValues(),
);

export const benefitsAtoms = {
  filter: benefitsListFilterAtom,
  filterTypes: benefitsTypesFilterAtom,
  list: atomWithSuspenseQuery((get) =>
    benefitsQueries.list(get(benefitsListFilterAtom.debouncedValueAtom)),
  ),
  details: atomFamily((id: number) =>
    atomWithSuspenseQuery(() => benefitsQueries.details(String(id))),
  ),
  allTypes: atomWithSuspenseQuery(() => benefitsQueries.allTypes()),
  types: atomWithSuspenseQuery((get) =>
    benefitsQueries.types(get(benefitsTypesFilterAtom.debouncedValueAtom)),
  ),
  detailsType: atomFamily((id: number) =>
    atomWithSuspenseQuery(() => benefitsQueries.detailsType(id)),
  ),
};

import { TFunction } from "i18next";
import * as yup from "yup";

export const counsellingSchema = (t: TFunction) => {
  return yup.object().shape({
    en_title: yup.string().required(
      t("menu.validation.required", {
        what: t("menu.validation.required", {
          what: t("menu.title", { ns: "dashboard" }),
        }),
      }),
    ),
    questions: yup.array().of(
      yup.object().shape({
        title: yup.string().required(
          t("menu.validation.required", {
            what: t("menu.title", { ns: "dashboard" }),
          }),
        ),
        options: yup.array().of(
          yup.object().shape({
            value: yup.string().required(
              t("menu.validation.required", {
                what: t("menu.counsellingService.option", { ns: "dashboard" }),
              }),
            ),
          }),
        ),
      }),
    ),
  });
};

import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { createFileRoute, Link } from "@tanstack/react-router";
import { Button, Modal, notification, Table } from "antd";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components";
import CheckItem from "@/components/CheckItem";
import { useCreateHandleFilterFunc } from "@/hooks/useCreateHandleFilterFunc";
import { useDataSource } from "@/hooks/useDataSource";
import { useOnRowTable } from "@/hooks/useOnRowTable";
import { calculateTableRecordByIndex } from "@/lib/utils";

import AdvisorsHeader from "../-components/ListHeaders/AdvisorsHeader";
import {
  useDeleteAdvisor,
  useOASISAdvisors,
  useOASISAdvisorsFilter,
} from "../-data/hooks";
import { OASISAdvisorsLoader } from "../-data/loaders";

export const Route = createFileRoute("/_auth/_dashboard/oasis/advisor/")({
  component: () => <ListAdvisorPage />,
  loader: OASISAdvisorsLoader,
  beforeLoad: () => {
    return {
      title: null,
    };
  },
});

function ListAdvisorPage() {
  const { t } = useTranslation("dashboard");
  const onRow = useOnRowTable({ to: "/oasis/advisor/$id" });
  const { data, isFetching } = useOASISAdvisors();
  const { filter, setFilter } = useOASISAdvisorsFilter();
  const { handleSearchChange, handlePaginateChange } =
    useCreateHandleFilterFunc(setFilter);
  const dataSource = useDataSource(data?.results);
  const { mutate } = useDeleteAdvisor();

  return (
    <MainDashboard>
      <div className="bg-white p-6 py-4">
        <AdvisorsHeader onSearchChange={handleSearchChange} />
        <Table
          loading={isFetching}
          pagination={{
            position: ["bottomCenter"],
            total: data?.count,
            showSizeChanger: false,
            current: filter?.page,
            onChange: handlePaginateChange,
          }}
          onRow={onRow}
          dataSource={dataSource}
          columns={[
            {
              title: "No.",
              key: "id",
              width: "1ch",
              render: (_, record, index) => {
                return (
                  <Link
                    className="text-[var(--ant-primary-color)]"
                    to="/oasis/advisor/$id"
                    params={{ id: String(record.id) }}
                  >
                    {calculateTableRecordByIndex(index, filter?.page)}
                  </Link>
                );
              },
            },
            {
              title: t("menu.name"),
              key: "name",
              render: (_, record) => record.name,
            },
            {
              title: "Email",
              key: "email",
              render: (_, record) => record.email,
            },
            {
              title: t("menu.OASIS.Lecturer"),
              key: "lecture",
              className: "!text-center",
              render: (_, record) => <CheckItem checked={record.lecturer} />,
            },
            {
              title: t("menu.action"),
              key: "action",
              className: "flex justify-center",
              render: (_, { id }) => (
                <Button
                  type="text"
                  onClick={(e) => {
                    e.stopPropagation();
                    Modal.confirm({
                      title: t("menu.OASIS.deleteAdvisorTitle"),
                      icon: (
                        <ExclamationCircleOutlined className="!text-red-500" />
                      ),
                      content: t("menu.OASIS.deleteAdvisorTitle"),
                      onOk() {
                        mutate(String(id), {
                          onSuccess: () => {
                            notification.success({
                              message: t("menu.success"),
                              description: t("menu.delete.success", {
                                what: t(
                                  "menu.OASIS.advisor",
                                  "advisor",
                                ).toLowerCase(),
                              }),
                            });
                          },
                          onError: () => {
                            notification.error({
                              message: t("menu.error"),
                              description: t("menu.delete.error", {
                                what: t(
                                  "menu.OASIS.advisor",
                                  "advisor",
                                ).toLowerCase(),
                              }),
                            });
                          },
                        });
                      },
                    });
                  }}
                  icon={<DeleteOutlined className="text-xl" />}
                  danger
                />
              ),
            },
          ]}
        />
      </div>
    </MainDashboard>
  );
}

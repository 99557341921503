import { Form, Input, Typography } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { Fragment } from "react/jsx-runtime";

import { DATE_HOUR_MINUTES } from "@/lib/constants";
import { cn } from "@/lib/utils";
import { WebEventServicePayload, Event } from "@/types/Api";

import { DatePicker } from "../DatePicker";

interface Props {
  hasDefaultValue?: boolean;
  isEditMode: boolean;
  requestFacility?: WebEventServicePayload & Event["service"];
  email?: string;
  username?: string;
  phoneNumber?: string;
}

const EventInfo = ({
  hasDefaultValue,
  isEditMode,
  requestFacility,
  email,
  username,
  phoneNumber,
}: Props) => {
  const { t } = useTranslation("dashboard");
  return (
    <Fragment>
      <div className="col-span-1">
        <Form.Item
          label={
            <Typography className="font-medium">
              {t("menu.eventServices.eventName")}
            </Typography>
          }
          name="_event_title"
          initialValue={
            requestFacility?.event_en_title ?? requestFacility?.event_title
          }
        >
          <Input
            className={hasDefaultValue ? "!text-gray-800" : ""}
            disabled={!isEditMode}
            readOnly
            placeholder={t("menu.editHere")}
          />
        </Form.Item>
      </div>
      <div className="col-span-1">
        <Form.Item
          label={
            <Typography className="font-medium">
              {t("menu.phoneNumber")}
            </Typography>
          }
          name="phone"
          initialValue={phoneNumber}
        >
          <Input
            className={hasDefaultValue ? "!text-gray-800" : ""}
            disabled={!isEditMode}
            readOnly
            placeholder={t("menu.phoneNumber")}
          />
        </Form.Item>
      </div>
      <div className="col-span-1">
        <Form.Item
          label={
            <Typography className="font-medium">{t("menu.name")}</Typography>
          }
          name="name"
          initialValue={username}
        >
          <Input
            className={hasDefaultValue ? "!text-gray-800" : ""}
            disabled={!isEditMode}
            readOnly
            placeholder={t("menu.editHere")}
          />
        </Form.Item>
      </div>
      <div className="col-span-1">
        <Form.Item
          name={["service", "requester", "email"]}
          label={
            <Typography className="font-medium">{t("menu.email")}</Typography>
          }
          initialValue={email}
        >
          <Input
            className={hasDefaultValue ? "!text-gray-800" : ""}
            disabled={!isEditMode}
            readOnly
            placeholder={t("menu.editHere")}
          />
        </Form.Item>
      </div>
      <div className="col-span-1">
        <Form.Item
          label={
            <Typography className="font-medium">
              {t("menu.startDate")}
            </Typography>
          }
          name={["service", "event_start_at"]}
          initialValue={
            hasDefaultValue && requestFacility?.event_start_at
              ? dayjs(requestFacility.event_start_at)
              : undefined
          }
          className={cn(
            "flex-1 cursor-text",
            !isEditMode ? "cursor-not-allowed" : "",
          )}
        >
          <DatePicker
            inputReadOnly
            className="w-full pointer-events-none"
            disabled={!isEditMode}
            format={DATE_HOUR_MINUTES}
          />
        </Form.Item>
      </div>
      <div className="col-span-1">
        <Form.Item
          label={
            <Typography className="font-medium">{t("menu.endDate")}</Typography>
          }
          name={["service", "event_end_at"]}
          initialValue={
            hasDefaultValue && requestFacility?.event_end_at
              ? dayjs(requestFacility.event_end_at)
              : undefined
          }
          className={cn(
            "flex-1 cursor-text",
            !isEditMode ? "cursor-not-allowed" : "",
          )}
        >
          <DatePicker
            className="w-full pointer-events-none"
            inputReadOnly
            disabled={!isEditMode}
            format={DATE_HOUR_MINUTES}
          />
        </Form.Item>
      </div>
    </Fragment>
  );
};

export default EventInfo;

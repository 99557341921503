import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Select,
  Table,
  TableProps,
  Typography,
} from "antd";
import { useTranslation } from "react-i18next";

import { cn, filterSelectOptions } from "@/lib/utils";
import { WebFacility } from "@/types/Api";

interface Props {
  hasDefaultValue?: boolean;
  isEditMode: boolean;
  formData: FormInstance;
  facilitiesMap: Map<number, WebFacility>;
  isRequestNotNew?: boolean;
  facilities: WebFacility[] | undefined;
}

interface Facility {
  facility?: number;
  quantity: number;
  name?: string;
  describe?: string;
}

const FacilitiesTable = ({
  hasDefaultValue,
  isEditMode,
  formData,
  facilitiesMap,
  isRequestNotNew,
  facilities,
}: Props) => {
  const { t } = useTranslation("dashboard");
  const facilitiesWatchValues = Form.useWatch(
    ["service", "facilities"],
    formData,
  ) as Facility[] | undefined;
  const facilitiesFormValues = formData.getFieldValue([
    "service",
    "facilities",
  ]) as Facility[] | undefined;
  const facilitiesValues = (facilitiesWatchValues ?? facilitiesFormValues)?.map(
    (item) => {
      const mapItem = facilitiesMap.get(item.facility ?? -1);
      return {
        ...item,
        name: item.name ?? mapItem?.name,
      };
    },
  );
  const shouldShowButtonAddNewFacility =
    isEditMode &&
    !isRequestNotNew &&
    (facilitiesWatchValues?.length ?? 0) < (facilities?.length ?? 0);

  return (
    <div className="col-span-2 mt-4">
      <Typography className="font-medium">
        {t("menu.facilities.requestedFacilityDetails")}
      </Typography>
      <div className="mt-2">
        <Form.List
          name={["service", "facilities"]}
          rules={[
            {
              validator: async (_, value?: unknown[]) => {
                if (!value || value.length < 1) {
                  return Promise.reject(new Error("error"));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          {(_, { add, remove }, { errors }) => {
            const tableColumns: TableProps<Facility>["columns"] = [
              {
                title: t("menu.facilities.facilityName"),
                dataIndex: "name",
                key: "name",
                width: "40%",
                render: (_, record, index) => {
                  return (
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: t("menu.validation.required", {
                            what: t("menu.facilities.facility"),
                          }),
                        },
                      ]}
                      initialValue={record.name}
                      name={[index, "name"]}
                    >
                      {!record.facility ? (
                        <Select
                          className={cn(
                            hasDefaultValue ? "!text-gray-800" : "",
                            "w-full",
                          )}
                          onSelect={(_, option) => {
                            formData.setFieldValue(
                              ["service", "facilities", index, "_facility"],
                              (option as { key?: number }).key,
                            );
                            formData.setFieldValue(
                              ["service", "facilities", index, "quantity"],
                              1,
                            );
                          }}
                          placeholder={t("menu.select", {
                            what: t("menu.facilities.facility").toLowerCase(),
                          })}
                          disabled={!isEditMode}
                          filterOption={filterSelectOptions}
                        >
                          {facilities?.map((item) => {
                            const itemIdStr = item.id.toString();
                            return (
                              <Select.Option
                                key={item.id}
                                value={item.name}
                                name={item.name}
                                quantity={item.quantity}
                                disabled={facilitiesValues?.some(
                                  (_item) =>
                                    _item.facility?.toString() === itemIdStr ||
                                    (
                                      _item as unknown as {
                                        _facility?: number;
                                      }
                                    )._facility?.toString() === itemIdStr,
                                )}
                              >
                                <div className="flex items-center">
                                  <img
                                    className="object-contain mr-2"
                                    width={48}
                                    height={32}
                                    src={item.image ?? ""}
                                    alt={item.name}
                                  />
                                  <span>
                                    {item.name} /{" "}
                                    {t("menu.facilities.quantity")}:{" "}
                                    {item.quantity}
                                  </span>
                                </div>
                              </Select.Option>
                            );
                          })}
                        </Select>
                      ) : (
                        record.name
                      )}
                    </Form.Item>
                  );
                },
              },
              {
                title: <div>{t("menu.facilities.quantity")}</div>,
                dataIndex: "quantity",
                key: "quantity",
                width: "20%",
                render: (_, record, index) => {
                  const facilityId =
                    record.facility ??
                    +(
                      (
                        record as unknown as {
                          _facility?: number;
                        }
                      )._facility ?? -1
                    );
                  const facility = facilitiesMap.get(facilityId);
                  return (
                    <Form.Item
                      name={[index, "quantity"]}
                      initialValue={
                        hasDefaultValue && record.quantity ? record.quantity : 1
                      }
                      className="flex-1"
                    >
                      {!record.facility ? (
                        <InputNumber
                          className="w-full"
                          onChange={(value) => {
                            if (facility) {
                              formData.setFieldValue(
                                ["service", "facilities", index, "quantity"],
                                Math.min(
                                  facility.quantity ?? 1,
                                  value as number,
                                ),
                              );
                            }
                          }}
                          type="number"
                          placeholder={t("menu.facilities.quantity")}
                        />
                      ) : (
                        record.quantity
                      )}
                    </Form.Item>
                  );
                },
              },
              {
                title: <div>{t("menu.facilities.describe")}</div>,
                dataIndex: "describe",
                key: "describe",
                width: "20%",
                render: (_, record, index) => {
                  return (
                    <Form.Item
                      name={[index, "describe"]}
                      initialValue={
                        hasDefaultValue && record.quantity
                          ? record.describe
                          : undefined
                      }
                      className="flex-1"
                    >
                      {!record.facility ? (
                        <Input placeholder={t("menu.facilities.describe")} />
                      ) : (
                        record.describe ?? "--/--"
                      )}
                    </Form.Item>
                  );
                },
              },
            ];

            if (isEditMode && !isRequestNotNew) {
              tableColumns.push({
                title: t("menu.action"),
                key: "action",
                render: (_, record, index) => {
                  return (
                    <Form.Item>
                      <div className="flex gap-2">
                        {!record.facility && (
                          <Button
                            disabled={!isEditMode}
                            className="text-brand-primary font-medium pl-0 hover:!bg-transparent"
                            type="text"
                            onClick={() => {
                              const _facility = formData.getFieldValue([
                                "service",
                                "facilities",
                                index,
                                "_facility",
                              ]) as number;
                              if (!Number.isNaN(+_facility)) {
                                formData.setFieldValue(
                                  ["service", "facilities", index, "facility"],
                                  _facility,
                                );
                              }
                            }}
                          >
                            {t("menu.save")}
                          </Button>
                        )}
                        <Button
                          type="text"
                          danger
                          className="font-medium pl-0 hover:!bg-transparent"
                          onClick={() => {
                            remove(index);
                          }}
                          disabled={!isEditMode}
                        >
                          {t("menu.button.delete")}
                        </Button>
                      </div>
                    </Form.Item>
                  );
                },
              });
            }

            return (
              <>
                <Table
                  columns={tableColumns}
                  dataSource={
                    facilitiesValues?.map((field) => ({
                      ...field,
                    })) ?? []
                  }
                  pagination={false}
                />
                {shouldShowButtonAddNewFacility && (
                  <div className="border p-3 px-4 border-solid border-[#D9D9D9]">
                    <Button
                      onClick={() => {
                        add({
                          facility: undefined,
                          quantity: 1,
                          name: undefined,
                          id: crypto.randomUUID(),
                        });
                      }}
                      className="text-brand-primary font-medium border-dashed w-full border-brand-primary"
                    >
                      <PlusOutlined /> {t("menu.addNew")}
                    </Button>
                  </div>
                )}
                {errors.length > 0 && (
                  <Typography className="mt-1 text-red-500 font-medium">
                    {t("menu.validation.required", {
                      what: t("menu.facilities.facilities"),
                    })}
                  </Typography>
                )}
              </>
            );
          }}
        </Form.List>
      </div>
    </div>
  );
};

export default FacilitiesTable;

import { keepPreviousData, queryOptions } from "@tanstack/react-query";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";
import { atomFamily } from "jotai/vanilla/utils";

import atomWithDebounce from "@/jotai-atoms/atomWithDebounce";
import { fetchApiClient } from "@/lib/utils/fetchUtils";
import { PaginateFilterState } from "@/types";
import { Feedback } from "@/types/Api";

const KEYS = {
  index: "feedback",
  list: "list",
  details: "details",
} as const;

type FeedbackListFilterState =
  | (Parameters<typeof fetchApiClient.feedbacks.getListFeedbacks>[0] &
      PaginateFilterState & {
        rating?: Feedback["rating"][];
        subject?: Feedback["subject"][];
      })
  | undefined;

export const feedbackQueries = {
  list: (params: FeedbackListFilterState) =>
    queryOptions({
      queryKey: [KEYS.index, KEYS.list, params],
      queryFn: async () => {
        try {
          const { data } =
            await fetchApiClient.feedbacks.getListFeedbacks(params);
          return data;
        } catch (error) {
          throw new Error("Something went wrong!");
        }
      },
      placeholderData: keepPreviousData,
    }),
  details: (id: string) => {
    return queryOptions({
      queryKey: [KEYS.index, KEYS.details, id] as const,
      queryFn: async () => {
        try {
          const { data } = await fetchApiClient.feedbacks.getAFeedback(+id);
          return data;
        } catch (error) {
          throw new Error("Something went wrong!");
        }
      },
    });
  },
};

const feedBackListStateAtom = atomWithDebounce<FeedbackListFilterState>({
  page: 1,
  search: "",
});

export const feedbackAtoms = {
  filter: feedBackListStateAtom,
  list: atomWithSuspenseQuery((get) =>
    feedbackQueries.list(get(feedBackListStateAtom.debouncedValueAtom)),
  ),
  details: atomFamily((id: string) =>
    atomWithSuspenseQuery(() => feedbackQueries.details(id)),
  ),
};

import { keepPreviousData } from "@tanstack/react-query";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";
import { atomFamily } from "jotai/vanilla/utils";

import atomWithDebounce from "@/jotai-atoms/atomWithDebounce";
import { getDefaultPaginateValues } from "@/lib/utils";
import { fetchApiClient } from "@/lib/utils/fetchUtils";
import { createQuery } from "@/lib/utils/tanstack-query";
import { TargetCustomerParams } from "@/types/ApiParams";

const TARGET_CUSTOMER = "TARGET_CUSTOMER";
const createTargetCustomerQuery = createQuery(TARGET_CUSTOMER);
const {
  getListTargetCustomers,
  getATargetCustomer,
  getAllRecipientOfTargetCustomer,
} = fetchApiClient.targetCustomers;

type TargetCustomersFilterState =
  TargetCustomerParams<"getListTargetCustomers">;

export const targetCustomerQueries = {
  list: createTargetCustomerQuery(getListTargetCustomers, {
    placeholderData: keepPreviousData,
  }),
  all: createTargetCustomerQuery(getListTargetCustomers),
  details: createTargetCustomerQuery(getATargetCustomer),
  allRecipient: createTargetCustomerQuery(getAllRecipientOfTargetCustomer),
};

const targetCustomerFilterAtom = atomWithDebounce<TargetCustomersFilterState>(
  getDefaultPaginateValues(),
);

export const targetCustomerAtoms = {
  filter: targetCustomerFilterAtom,
  list: atomWithSuspenseQuery((get) => {
    return targetCustomerQueries.list(
      get(targetCustomerFilterAtom.debouncedValueAtom),
    );
  }),
  all: atomWithSuspenseQuery(() => targetCustomerQueries.all()),
  details: atomFamily((id: string) => {
    return atomWithSuspenseQuery(() => targetCustomerQueries.details(id));
  }),
  allRecipient: atomFamily((id: string) => {
    return atomWithSuspenseQuery(() => targetCustomerQueries.allRecipient(id));
  }),
};

import { PlusOutlined } from "@ant-design/icons";
import { Form, FormInstance, Typography, UploadFile } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useImage } from "@/hooks";
import { SUPPORTED_IMAGE_TYPES } from "@/lib/constants";

import { UploadImage } from "../UploadImage";

interface Props {
  form: FormInstance;
  isEdit?: boolean;
  initialValue?: string;
}
const ImageNamePath = ["service", "layout"];

const ImageLayout = ({ form, isEdit, initialValue }: Props) => {
  const { t } = useTranslation("dashboard");
  const { uploadImage } = useImage();
  const [fileList, setFiledList] = useState<UploadFile[]>(
    initialValue
      ? [
          {
            uid: crypto.randomUUID(),
            name: initialValue,
            status: "done",
            url: initialValue,
            thumbUrl: initialValue,
          },
        ]
      : [],
  );

  return (
    <Form.Item
      label={
        <Typography.Text className="font-medium">
          {t("menu.facilities.imageLayout")}
        </Typography.Text>
      }
      initialValue={initialValue}
      className="col-span-2"
      name={ImageNamePath}
    >
      <UploadImage
        disabled={!isEdit}
        showUploadList={{
          showDownloadIcon: false,
          showPreviewIcon: true,
          showRemoveIcon: isEdit,
        }}
        accept={SUPPORTED_IMAGE_TYPES.join(",")}
        onRemove={(file) => {
          setFiledList((prev) => {
            const result = prev.filter((item) => item.uid !== file.uid);
            return [...result];
          });
          form.setFieldValue(ImageNamePath, undefined);
        }}
        customRequest={(options) => {
          void uploadImage(
            { file: options.file as File },
            {
              onSuccess: (url) => {
                form.setFieldValue(ImageNamePath, url);
                setFiledList([
                  {
                    name: url,
                    url: url,
                    thumbUrl: url,
                    status: "done",
                    uid: crypto.randomUUID(),
                  },
                ]);
              },
            },
          );
        }}
        fileList={fileList}
        emptyComponent={isEdit ? null : undefined}
      >
        {isEdit && fileList.length === 0 && (
          <div className="flex flex-col items-center gap-1">
            <PlusOutlined />
            <span>{t("menu.upload")}</span>
          </div>
        )}
      </UploadImage>
    </Form.Item>
  );
};

export default ImageLayout;

import { Badge, Typography } from "antd";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

import { NotificationTemplate } from "@/types/Api";

interface Props {
  status: NotificationTemplate["status"];
}

const statusColors = {
  created: "#1890FF",
  processing: "#FA8C16",
  completed: "#52C41A",
};

const getStatusColor = (
  status: NotificationTemplate["status"],
  t?: TFunction,
) => {
  switch (status) {
    case "Created":
    case "Started":
      return t ? t("menu.statuses.created", { ns: "dashboard" }) : "created";
    case "Processing":
      return t
        ? t("menu.statuses.processing", { ns: "dashboard" })
        : "processing";
    case "Partial Failure":
    case "Completed":
    case "Failed":
    default:
      return t
        ? t("menu.statuses.completed", { ns: "dashboard" })
        : "completed";
  }
};

const NotificationStatus = ({ status }: Props) => {
  const { t } = useTranslation("dashboard");
  return (
    <Typography.Text>
      <Badge
        color={
          status
            ? statusColors[getStatusColor(status) as keyof typeof statusColors]
            : statusColors.completed
        }
      />{" "}
      {getStatusColor(status, t)}
    </Typography.Text>
  );
};

export default NotificationStatus;

import { FunnelPlotOutlined } from "@ant-design/icons";
import { useNavigate } from "@tanstack/react-router";
import { Button, Dropdown, Select, Typography } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ListHeader, Stack } from "@/components";
import { cn, filterSelectOptions } from "@/lib/utils";
import { NotificationType } from "@/types/Api";

import { NOTIFICATION_STATUS } from "../-data";
import {
  useAllNotiTypes,
  useListNotificationFilter,
  useListNotifications,
} from "../-data/hooks";
import { NotificationListFilterType } from "../-data/store";

const NotificationListHeader = () => {
  const { isFetching } = useListNotifications();
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState<NotificationListFilterType>({
    type: undefined,
    status: undefined,
  });
  const { data: notiTypes } = useAllNotiTypes();

  const navigate = useNavigate();
  const { filter: notificationFilter, setFilter: setNotificationFilter } =
    useListNotificationFilter();
  const { t } = useTranslation("dashboard");

  const handleChange = (search: string) => {
    setNotificationFilter((prev) => {
      return {
        ...prev,
        search,
        page: 1,
      };
    });
  };
  const handleAddNew = () => {
    void navigate({ to: "/notifications/add" });
  };
  const handleApplyFilter = () => {
    setNotificationFilter((prev) => {
      return {
        ...prev,
        ...filter,
        page: 1,
      };
    });
    setOpen(false);
  };
  const handleResetFilter = () => {
    setNotificationFilter({
      page: 1,
      search: "",
    });
  };

  const shouldDisableResetBtn =
    !notificationFilter?.status && !notificationFilter?.type;

  return (
    <ListHeader
      isLoading={isFetching}
      title={t("menu.list")}
      placeholder={t("menu.search.title")}
      onAddNewClick={handleAddNew}
      onChange={handleChange}
      search={notificationFilter?.search}
      filter={
        <Dropdown
          open={open}
          onOpenChange={setOpen}
          trigger={["click"]}
          menu={{
            className: "w-[272px]",
            items: [
              {
                label: (
                  <Stack className="justify-between gap-6">
                    <Button
                      size="small"
                      type="text"
                      onClick={handleResetFilter}
                      disabled={shouldDisableResetBtn}
                      className={cn(
                        !shouldDisableResetBtn &&
                          "text-[var(--ant-primary-color)]",
                      )}
                    >
                      {t("menu.button.reset")}
                    </Button>
                    <Button
                      size="small"
                      type="text"
                      className="bg-brand-primary text-white"
                      onClick={handleApplyFilter}
                      loading={isFetching}
                    >
                      {t("menu.button.ok")}
                    </Button>
                  </Stack>
                ),
                className: cn("hover:bg-transparent cursor-default px-2"),
                key: "action",
              },
              { type: "divider" },
              {
                label: (
                  <div className="flex flex-col gap-2">
                    <Typography.Text className="font-medium">
                      {t("menu.notification.type")}
                    </Typography.Text>
                    <Select
                      showArrow
                      allowClear
                      maxTagTextLength={24}
                      placeholder={t("menu.select", {
                        what: t("menu.notification.type").toLowerCase(),
                      })}
                      className="w-full"
                      options={(
                        notiTypes as NotificationType[] | undefined
                      )?.map((item) => {
                        return {
                          value: item.id,
                          label: item.en_name || item.name,
                        };
                      })}
                      onChange={(type) => {
                        setFilter((prev) => {
                          return {
                            ...prev,
                            type,
                          };
                        });
                      }}
                      value={filter?.type}
                      filterOption={filterSelectOptions}
                    />
                  </div>
                ),
                className: "hover:bg-transparent bg-transparent",
                key: "type",
              },
              {
                label: (
                  <div className="flex flex-col gap-2">
                    <Typography.Text className="font-medium">
                      {t("menu.status")}
                    </Typography.Text>
                    <Select
                      showArrow
                      allowClear
                      maxTagTextLength={24}
                      placeholder={t("menu.select", {
                        what: t("menu.status").toLowerCase(),
                      })}
                      className="w-full"
                      options={NOTIFICATION_STATUS}
                      onChange={(status) => {
                        setFilter((prev) => {
                          return {
                            ...prev,
                            status,
                          };
                        });
                      }}
                      value={filter?.status}
                      filterOption={filterSelectOptions}
                    />
                  </div>
                ),
                className: "hover:bg-transparent bg-transparent",
                key: "status",
              },
            ],
          }}
        >
          <Stack
            className="cursor-pointer gap-1"
            onClick={() => {
              setOpen(!open);
            }}
          >
            <span>{t("menu.filter")}</span>
            <FunnelPlotOutlined className="text-[#8C8C8C]" />
          </Stack>
        </Dropdown>
      }
    />
  );
};

export default NotificationListHeader;

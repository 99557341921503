import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { notification } from "antd";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components";
import useYupResolver from "@/hooks/useYupResolver";
import { mappingContentToBase64 } from "@/lib/utils";
import { WebFacilityPayload } from "@/types/Api";

import FacilityDetailForm from "../-components/DetailForms/FacilityDetailForm";
import { facilitySchema } from "../-data";
import { useCreateFacility } from "../-data/hooks";
import { facilityAddLoader } from "../-data/loaders";

export const Route = createFileRoute(
  "/_auth/_dashboard/facilities/management/add",
)({
  component: () => <AddFacilityPage />,
  loader: facilityAddLoader,
  beforeLoad: () => {
    return {
      title: t("menu.add", { ns: "dashboard" }),
    };
  },
});

function AddFacilityPage() {
  const { resolver } = useYupResolver<WebFacilityPayload>(facilitySchema);
  const form = useForm<WebFacilityPayload>({
    defaultValues: {},
    resolver,
  });
  const { t } = useTranslation("dashboard");
  const navigate = useNavigate();
  const { mutate, isPending } = useCreateFacility();

  const handleSubmit = (params: WebFacilityPayload) => {
    mutate(mappingContentToBase64(params, "description"), {
      onSuccess: () => {
        notification.success({
          message: t("menu.success"),
          description: t("menu.create.success", {
            what: t("menu.facilities.facility", "facility").toLowerCase(),
          }),
        });
        void navigate({ to: "/facilities/management" });
      },
      onError: () => {
        notification.error({
          message: t("menu.error"),
          description: t("menu.create.error", {
            what: t("menu.facilities.facility", "facility").toLowerCase(),
          }),
        });
      },
    });
  };

  return (
    <MainDashboard>
      <FacilityDetailForm
        isEdit
        onClose={() => void navigate({ to: "/facilities/management" })}
        title={t("menu.details")}
        form={form}
        isSubmitLoading={isPending}
        onSubmit={handleSubmit}
      />
    </MainDashboard>
  );
}

import { BlobClient } from "@azure/storage-blob";
import { useCallback } from "react";
import { joinURL } from "ufo";

import { BACKEND_API_URI, CDN_URL } from "@/lib/constants";
import { fetchWithToken } from "@/lib/utils/fetchUtils";

import { useAppMutation } from "./useAppMutation";

export const useFile = () => {
  const { mutateAsync: getUploadUrl } = useAppMutation({
    mutationFn: async ({
      filename,
      containerName,
    }: {
      filename: string;
      containerName?: string;
    }): Promise<string> => {
      const response = await fetchWithToken(
        `${BACKEND_API_URI}/azure-blob-upload/get-sas-token/`,
        {
          body: JSON.stringify({
            filename,
            container_name: containerName,
          }),
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      if (response.status !== 200) {
        throw new Error("Failed to get upload url");
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
      return (await response.json()).upload_url;
    },
  });

  const getFileUrl = useCallback((filename: string): string => {
    if (filename.startsWith("https://")) {
      return filename;
    }

    return joinURL(CDN_URL, filename);
  }, []);

  const { mutateAsync: uploadFile } = useAppMutation({
    mutationFn: async ({
      filename,
      file,
      containerName,
    }: {
      file: File;
      filename?: string;
      containerName?: string;
    }): Promise<string> => {
      const finalFilename = filename ?? file.name;
      const isImageFile = file.type.includes("image");
      const timestamp = new Date().toISOString().replace(/[:.-]/g, "");
      const uploadUrl = await getUploadUrl({
        filename: isImageFile ? `${timestamp}_${finalFilename}` : finalFilename,
        containerName,
      });
      const blobClient = new BlobClient(uploadUrl);
      await blobClient.getBlockBlobClient().uploadData(file);
      const fileUrl = getFileUrl(uploadUrl);
      if (fileUrl.includes("?")) {
        return fileUrl.split("?")[0];
      }
      return fileUrl;
    },
  });

  const getFilePath = useCallback((url: string) => {
    try {
      const parsed = new URL(url);
      return parsed.pathname.replace("/files/", "");
    } catch (e) {
      return url;
    }
  }, []);

  return {
    uploadFile,
    getFileUrl,
    getUploadUrl,
    getFilePath,
  };
};

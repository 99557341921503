import {
  createFileRoute,
  useNavigate,
  useParams,
} from "@tanstack/react-router";
import { Button, Divider, Form, Menu, Typography } from "antd";
import { t } from "i18next";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components";
import RatingForm from "@/components/RatingForm";
import RequestFacilitiesForm from "@/components/RequestFacilitiesForm";
import {
  useAllFacilities,
  useEventServiceRequestsDetails,
} from "@/jotai-atoms/event-service";
import { cn } from "@/lib/utils";
import { WebEventService } from "@/types/Api";

import ConfirmModal from "../-components/ConfirmFacilityModal";
import { eventServiceRequestDetailLoader } from "../-data/loaders";

export const Route = createFileRoute(
  "/_auth/_dashboard/facilities/requests/$id",
)({
  beforeLoad: () => {
    return {
      title: t("menu.details", { ns: "dashboard" }),
    };
  },
  component: () => <FacilityRequestPage />,
  loader: eventServiceRequestDetailLoader,
});

//FIXME: should update form after submit with reduce quantity

const { Title } = Typography;

function FacilityRequestPage() {
  const { id } = useParams({
    from: "/_auth/_dashboard/facilities/requests/$id",
  });
  const { data } = useEventServiceRequestsDetails(id);
  const [formData] = Form.useForm();
  const { t } = useTranslation("dashboard");
  const navigate = useNavigate();
  const [menuKeys, setMenuKeys] = useState<"detail" | "rating">("detail");
  const { data: facilities } = useAllFacilities();
  const [modal, setModal] = useState<{
    open: boolean;
    type: "reject" | "approve";
  }>({ open: false, type: "approve" });

  const handleClose = () => {
    void navigate({ to: "/facilities/requests" });
  };

  const handleOk = () => {
    setModal({ open: true, type: "approve" });
  };

  const isNew = data?.status === "NEW";

  return (
    <MainDashboard>
      <div className="bg-white p-6 py-4 h-full flex flex-col">
        <div className="flex">
          <Menu
            mode="horizontal"
            className="border-b-0 flex-1"
            selectedKeys={[menuKeys]}
            onSelect={(value) => {
              setMenuKeys(value.key as typeof menuKeys);
            }}
            items={[
              {
                label: (
                  <Title
                    level={4}
                    className={cn(
                      "px-2",
                      menuKeys === "detail" ? "!text-brand-primary" : "",
                    )}
                  >
                    {t("menu.details")}
                  </Title>
                ),
                key: "detail",
                className: "px-4",
              },
              {
                label: (
                  <Title
                    level={4}
                    className={cn(
                      "px-2",
                      menuKeys === "rating" ? "!text-brand-primary" : "",
                    )}
                  >
                    {t("menu.facilities.rating")}
                  </Title>
                ),
                key: "rating",
                className: "px-4",
              },
            ]}
          />
        </div>
        <Divider
          style={{ width: "calc(100% + 3rem)", margin: "0 -1.5rem 0" }}
        />
        <Form layout="vertical" className=" grid grid-cols-2 gap-x-4 mt-4">
          <RequestFacilitiesForm
            requestFacility={
              {
                ...data,
                facilities: data?.service_facilities,
              } as WebEventService
            }
            hasDefaultValue
            isEditMode={false}
            formData={formData}
            username={
              (data?.requester.last_name ?? "") +
              (data?.requester.first_name ?? "")
            }
            email={data?.requester.email ?? ""}
            className={cn(
              "grid-cols-2 gap-x-4 flex-1 col-span-2",
              menuKeys === "detail" ? "grid" : "hidden",
            )}
            facilities={facilities}
          />
          <RatingForm
            rating={data?.rate}
            hasDefaultValue
            isEditMode={false}
            formData={formData}
            className={cn(
              "grid-cols-2 gap-x-4 flex-1 col-span-2",
              menuKeys === "rating" ? "grid" : "hidden",
            )}
          />
        </Form>
        <div className="mt-auto flex w-full ">
          {menuKeys === "detail" && isNew && (
            <Button
              size="large"
              type="primary"
              danger
              className="w-[114px]"
              onClick={() => {
                setModal({ open: true, type: "reject" });
              }}
            >
              {t("menu.reject")}
            </Button>
          )}
          <div className="ml-auto flex gap-4">
            <Button size="large" className="w-[114px]" onClick={handleClose}>
              {t("menu.close")}
            </Button>
            {menuKeys === "detail" && isNew && (
              <Button
                size="large"
                type="primary"
                onClick={handleOk}
                className="w-[114px] bg-brand-primary"
              >
                {t("menu.approved")}
              </Button>
            )}
          </div>
        </div>
      </div>
      <ConfirmModal
        facilities={facilities ?? []}
        requestFacilities={data?.service_facilities}
        type={modal.type}
        open={modal.open}
        onClose={() => {
          setModal({ open: false, type: "approve" });
        }}
        onSuccess={(facilities) => {
          const oldValues = formData.getFieldValue(
            "service",
          ) as WebEventService;
          formData.setFieldValue("service", {
            ...oldValues,
            facilities,
          });
        }}
        facilityId={Number(id)}
      />
    </MainDashboard>
  );
}

import "@tanstack/react-query";
import { useAtomValue, useSetAtom } from "jotai";
import { HTTPError } from "ky";

import { useAppMutation } from "@/hooks/useAppMutation";
import { queryClient } from "@/lib/config/queryClient";
import { fetchApi } from "@/lib/utils/fetchUtils";
import { ErrorResponse } from "@/types";
import { Benefit, BenefitType } from "@/types/Api";

import { benefitsAtoms, benefitsQueries } from "./store";

export const useListBenefits = () => {
  return useAtomValue(benefitsAtoms.list);
};

export const useBenefitsDetails = (id: number) => {
  return useAtomValue(benefitsAtoms.details(id));
};

interface IBenefitUpdate extends Omit<Benefit, "created_by" | "type"> {
  created_by?: number;
  type?: number;
}

export const useUpdateBenefits = () => {
  return useAppMutation({
    mutationFn: async (data: IBenefitUpdate) => {
      try {
        if (!data.id) {
          throw new Error();
        }
        await fetchApi.put(`benefits/${data.id.toString()}`, {
          body: JSON.stringify(data),
        });
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
    onSuccess: (_, { id }) => {
      if (id) {
        queryClient.removeQueries({
          queryKey: benefitsQueries.details(String(id)).queryKey,
        });
        benefitsAtoms.details.remove(id);
      }
    },
  });
};

export const useCreateBenefits = () => {
  return useAppMutation({
    mutationFn: async (data: IBenefitUpdate) => {
      try {
        await fetchApi.post(`benefits/`, {
          body: JSON.stringify(data),
        });
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
  });
};

export const useAllBenefitTypes = () => {
  return useAtomValue(benefitsAtoms.allTypes);
};

export const useBenefitTypes = () => {
  return useAtomValue(benefitsAtoms.types);
};

export const useBenefitsTypesFilter = () => {
  const benefitsFilterType = useAtomValue(
    benefitsAtoms.filterTypes.currentValueAtom,
  );
  const setBenefitsFilterType = useSetAtom(
    benefitsAtoms.filterTypes.debouncedValueAtom,
  );
  return { benefitsFilterType, setBenefitsFilterType };
};

export const useBenefitTypeDetails = (id: number) => {
  return useAtomValue(benefitsAtoms.detailsType(id));
};

export const useUpdateBenefitType = () => {
  return useAppMutation({
    mutationFn: async (data: BenefitType) => {
      try {
        if (!data.id) {
          throw new Error();
        }
        const response = await fetchApi.put(
          `benefits/types/${data.id.toString()}/`,
          {
            body: JSON.stringify(data),
          },
        );
        await response.json();
      } catch (error) {
        const result = await (error as HTTPError).response.json();
        throw (
          ((result as ErrorResponse).error as Error | undefined) ??
          new Error("Something went wrong!")
        );
      }
    },
    onSuccess: (_, { id }) => {
      if (id) {
        queryClient.removeQueries({
          queryKey: benefitsQueries.detailsType(id).queryKey,
        });
        benefitsAtoms.detailsType.remove(id);
      }
    },
  });
};

export const useCreateBenefitType = () => {
  return useAppMutation({
    mutationFn: async (data: BenefitType) => {
      try {
        if (!data.id) {
          throw new Error();
        }
        await fetchApi.post(`benefits/types/`, {
          body: JSON.stringify(data),
        });
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
  });
};

export const useBenefitListFilter = () => {
  const benefitsFilter = useAtomValue(benefitsAtoms.filter.currentValueAtom);
  const setBenefitsFilter = useSetAtom(benefitsAtoms.filter.debouncedValueAtom);
  return { benefitsFilter, setBenefitsFilter };
};

import { yupResolver } from "@hookform/resolvers/yup";
import { Modal, Input, notification, Typography, Form } from "antd";
import { Fragment } from "react";
import { Controller, Resolver, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import {
  eventServiceAtoms,
  eventServiceQueries,
  useUpdateEventServiceStatus,
} from "@/jotai-atoms/event-service";
import { queryClient } from "@/lib/config/queryClient";
import { WebEventServiceUpdateStatus, WebFacility } from "@/types/Api";

interface Props {
  open: boolean;
  onClose: () => void;
  type: "reject" | "approve";
  facilityId: number;
  facilities: WebFacility[];
  requestFacilities: WebEventServiceUpdateStatus["facilities"];
  onSuccess?: (facilities: WebEventServiceUpdateStatus["facilities"]) => void;
}

interface FormData {
  requestFacilities: WebEventServiceUpdateStatus["facilities"];
  reason?: string;
}

const ConfirmModal = ({
  open,
  onClose,
  facilityId,
  type,
  facilities,
  requestFacilities,
  onSuccess,
}: Props) => {
  const isApproved = type === "approve";
  const { t } = useTranslation("dashboard");
  const { mutate: updateEventServiceStatus, isPending } =
    useUpdateEventServiceStatus();

  const title = isApproved
    ? t("menu.facilities.approveFacilityRequestTitle")
    : t("menu.facilities.rejectFacilityRequestTitle");

  const content = isApproved
    ? t("menu.facilities.approveFacilityRequestDesc")
    : t("menu.facilities.rejectFacilityRequestDesc");

  const notiSuccessDesc = isApproved
    ? t("menu.facilities.approveSuccess")
    : t("menu.facilities.rejectSuccess");
  const notiErrorDesc = isApproved
    ? t("menu.facilities.approveFailed")
    : t("menu.facilities.rejectFailed");

  const facilitiesMap = new Map(
    facilities.map((item) => {
      return [item.id, item];
    }),
  );

  const form = useForm<FormData>({
    defaultValues: {
      requestFacilities,
      reason: "",
    },
    resolver: yupResolver(
      yup.object().shape({
        reason: yup.string().test(
          "reason-required",
          t("menu.validation.required", {
            what: t("menu.facilities.reason"),
          }),
          (value) => isApproved || !!value,
        ),
      }),
    ) as unknown as Resolver<FormData>,
  });

  const { fields } = useFieldArray({
    control: form.control,
    name: "requestFacilities",
  });

  const handleClose = () => {
    form.reset();
    onClose();
  };

  const handleFormSubmit = (data: FormData) => {
    const { reason, requestFacilities } = data;
    updateEventServiceStatus(
      {
        id: Number(facilityId),
        data: {
          status: isApproved ? "APPROVED" : "REJECTED",
          reason,
          facilities: requestFacilities,
        },
      },
      {
        onSuccess: (_, { id }) => {
          notification.success({
            message: t("menu.success"),
            description: notiSuccessDesc,
          });
          onSuccess?.(requestFacilities);
          handleClose();
          if (id) {
            queryClient.removeQueries({
              queryKey: eventServiceQueries.details(id).queryKey,
            });
            eventServiceAtoms.details.remove(String(id));
          }
        },
        onError: () => {
          notification.error({
            message: t("menu.error"),
            description: notiErrorDesc,
          });
        },
      },
    );
  };

  const handleOk = () => {
    void form.handleSubmit(handleFormSubmit)();
  };

  return (
    <Modal
      title={title}
      open={open}
      okButtonProps={{ loading: isPending }}
      onOk={handleOk}
      onCancel={handleClose}
    >
      <div className="-my-4">
        {isApproved ? (
          <div>
            <Typography className="text-xs text-gray-400">
              {t("menu.facilities.onlyReduceQuantity")}
            </Typography>
            {fields.map((item, index) => {
              const facilityItem = facilitiesMap.get(Number(item.facility));
              return (
                <div className="flex items-center m-2 " key={item.id}>
                  <div className="w-1/2 flex items-center">
                    <img
                      className="object-contain mr-1"
                      width={48}
                      height={32}
                      src={facilityItem?.image ?? ""}
                      alt={facilityItem?.name}
                    />
                    <span className="whitespace-nowrap">
                      {facilityItem?.name} / {t("menu.facilities.quantity")} :{" "}
                      {item.quantity}
                    </span>
                  </div>
                  <Controller
                    control={form.control}
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                    name={`requestFacilities.${index}.quantity`}
                    render={({ field }) => {
                      return (
                        <Input
                          className="ml-2 w-1/4"
                          max={item.quantity}
                          type="number"
                          {...field}
                          onChange={(e) => {
                            const value = e.target.value;
                            const originalQuantity =
                              item.quantity ?? facilityItem?.quantity ?? 1;
                            field.onChange(
                              Math.min(Number(value), originalQuantity),
                            );
                          }}
                        />
                      );
                    }}
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <Fragment>
            <Typography className="font-medium text-md">
              {t("menu.facilities.typeReason")}
            </Typography>
            <Controller
              control={form.control}
              name="reason"
              render={({ field, fieldState: { error } }) => {
                return (
                  <Form.Item
                    validateStatus={error ? "error" : ""}
                    help={error?.message}
                    required
                  >
                    <Input
                      placeholder={t("menu.facilities.reason")}
                      className="mt-2"
                      {...field}
                    />
                  </Form.Item>
                );
              }}
            />
          </Fragment>
        )}
        {content}
      </div>
    </Modal>
  );
};

export default ConfirmModal;

import { useQueryClient } from "@tanstack/react-query";
import { createFileRoute, Link } from "@tanstack/react-router";
import { Table, Badge, Switch, Modal, BadgeProps, notification } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import CheckItem from "@/components/CheckItem";
import { MainDashboard } from "@/components/MainDashboard";
import { useCreateHandleFilterFunc } from "@/hooks/useCreateHandleFilterFunc";
import { useOnRowTable } from "@/hooks/useOnRowTable";
import { formatFullDateTime } from "@/lib/utils";

import EventGroupSelection from "../-components/EventGroupSelection";
import EventListHeader from "../-components/EventListHeader";
import {
  useListEvents,
  useListEventsFilter,
  useSetGroupIds,
  useUpdateEvent,
} from "../-data/hooks";
import { eventListLoader } from "../-data/loaders";

export const Route = createFileRoute("/_auth/_dashboard/events/_singleEvent/")({
  component: () => <EventsPage />,
  loader: eventListLoader,
  beforeLoad: () => {
    return {
      title: null,
    };
  },
});

const STATUS_COLOR: Record<string, BadgeProps["status"]> = {
  UPCOMING: "processing",
  ONGOING: "success",
  COMPLETED: "default",
  CANCELLED: "error",
};

const EventsPage = () => {
  const { t } = useTranslation("dashboard");
  const { data, isFetching } = useListEvents();
  const { filter: eventListFilter, setFilter: setDebounceEventListFilter } =
    useListEventsFilter();
  const onRow = useOnRowTable({ to: "/events/$id" });
  const { handlePaginateChange, handleSearchChange } =
    useCreateHandleFilterFunc(setDebounceEventListFilter);

  const { mutateAsync: updateEvent } = useUpdateEvent();
  const queryClient = useQueryClient();

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const setGroupIds = useSetGroupIds();

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setGroupIds(newSelectedRowKeys as number[]);
  };
  const handleClearSelect = () => {
    setSelectedRowKeys([]);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    preserveSelectedRowKeys: true,
  };

  return (
    <div className="relative">
      {selectedRowKeys.length > 0 && (
        <EventGroupSelection
          onCancel={handleClearSelect}
          selectedRowKeys={selectedRowKeys}
        />
      )}
      <MainDashboard>
        <div className="bg-white p-6 py-4">
          <EventListHeader
            onResetFilter={() => {
              setDebounceEventListFilter((prev) => {
                return {
                  ...prev,
                  search: "",
                  page: 1,
                  status: undefined,
                  is_highlight: undefined,
                };
              });
            }}
            onApplyFilter={(filter) => {
              setDebounceEventListFilter((prev) => {
                return {
                  ...prev,
                  page: 1,
                  published:
                    typeof filter.published === "boolean"
                      ? [filter.published]
                      : [],
                  source: filter.source && [filter.source],
                  status: filter.status,
                  is_highlight:
                    typeof filter.isHighlight === "boolean"
                      ? [filter.isHighlight]
                      : [],
                };
              });
            }}
            onChange={handleSearchChange}
            onSearch={handleSearchChange}
            search={eventListFilter?.search}
            isLoading={isFetching}
            shouldDisableResetBtn={
              typeof eventListFilter?.is_highlight !== "boolean" &&
              (eventListFilter?.status?.length ?? 0) === 0
            }
          />
          <Table
            loading={isFetching}
            dataSource={
              data?.results.map((item) => ({
                ...item,
                key: item.id,
              })) ?? []
            }
            onRow={onRow}
            rowSelection={rowSelection}
            columns={[
              {
                title: "ID",
                key: "id",
                render: (_, record) => {
                  return (
                    <Link
                      to="/events/$id"
                      params={{ id: record.id?.toString() ?? "" }}
                    >
                      {record.id}
                    </Link>
                  );
                },
              },

              {
                title: t("menu.events.title"),
                key: "title",
                render: (_, record) => {
                  return (
                    <span>{(record.en_title ?? record.title) || "-"}</span>
                  );
                },
              },
              {
                title: t("menu.events.type"),
                key: "event_types",
                render: (_, record) => {
                  return <span>{record.event_types?.[0]?.en_name}</span>;
                },
              },

              {
                title: t("menu.events.owner"),
                key: "created_by",
                render: (_, record) => {
                  return (
                    <span>
                      {(record.created_by?.first_name ?? "") +
                        " " +
                        (record.created_by?.last_name ?? "")}
                    </span>
                  );
                },
              },
              {
                title: t("menu.status"),
                key: "status",
                render: (_, record) => {
                  let statusText = "";
                  if (record.status) {
                    statusText =
                      record.status.charAt(0).toUpperCase() +
                      record.status.slice(1).toLowerCase();
                  }
                  return (
                    <Badge
                      className="flex flex-nowrap items-center"
                      status={
                        record.status ? STATUS_COLOR[record.status] : "default"
                      }
                      text={statusText}
                    />
                  );
                },
              },
              {
                title: t("menu.events.source"),
                key: "event_source",
                render: (_, record) => {
                  return (
                    <span>
                      {record.external_id
                        ? t("menu.external")
                        : t("menu.internal")}
                    </span>
                  );
                },
              },
              {
                title: t("menu.updatedDate"),
                key: "updated_at",
                render: (_, record) => {
                  return <span> {formatFullDateTime(record.updated_at)}</span>;
                },
              },
              {
                title: t("menu.highlight"),
                key: "is_highlight",
                className: "!text-center",
                render: (_, record) => {
                  return <CheckItem checked={!!record.is_highlight} />;
                },
              },
              {
                title: t("menu.facilities.facilities"),
                key: "facilities",
                className: "!text-center",
                render: (_, record) => {
                  return (
                    <CheckItem
                      checked={
                        !!(record as { has_facility?: boolean }).has_facility
                      }
                    />
                  );
                },
              },
              {
                title: t("menu.published"),
                key: "published",
                className: "!text-center",
                render: (_, record) => {
                  return (
                    <div className="flex justify-center">
                      <Switch
                        onClick={(checked, e) => {
                          e.stopPropagation();
                          Modal.confirm({
                            title: checked
                              ? t("menu.confirmPublishedTitle", {
                                  what: t("menu.events.single"),
                                })
                              : t("menu.confirmUnpublishedTitle", {
                                  what: t("menu.events.single"),
                                }),
                            content: checked
                              ? t("menu.confirmPublishedDesc", {
                                  what: t("menu.events.single"),
                                })
                              : t("menu.confirmUnpublishedDesc", {
                                  what: t("menu.events.single"),
                                }),
                            autoFocusButton: null,
                            cancelButtonProps: {
                              size: "large",
                              className: "w-[108px]",
                            },
                            okButtonProps: {
                              size: "large",
                              className: "w-[108px] bg-brand-primary",
                            },
                            onOk: async () => {
                              await updateEvent(
                                {
                                  ...record,
                                  status:
                                    record.status === "CANCELLED"
                                      ? record.status
                                      : "ACTIVE",
                                  event_types:
                                    record.event_types?.map(
                                      (item) => item.id as unknown as number,
                                    ) ?? [],
                                  thumbnail: record.thumbnail as
                                    | string
                                    | undefined,
                                  created_by: record.created_by
                                    ?.id as unknown as number,
                                  speakers:
                                    record.speakers?.map(
                                      (item) => item.id as unknown as number,
                                    ) ?? [],
                                  published: checked,
                                },
                                {
                                  onSuccess: () => {
                                    notification.success({
                                      message: t("menu.success"),
                                      description: t("menu.update.success", {
                                        what: t("menu.events.single"),
                                      }),
                                    });
                                    void queryClient.invalidateQueries({
                                      queryKey: ["event-list"],
                                      exact: false,
                                    });
                                  },
                                  onError: () => {
                                    notification.error({
                                      message: t("menu.error"),
                                      description: t("menu.update.error", {
                                        what: t("menu.events.single"),
                                      }),
                                    });
                                  },
                                },
                              );
                            },
                            maskClosable: true,
                          });
                        }}
                        checked={record.published}
                      />
                    </div>
                  );
                },
              },
              {
                title: t("menu.events.reg"),
                key: "event_reg",
                className: "!text-center",
                render: (_, record) => {
                  return (
                    <Link
                      to="/events/$id/registration"
                      params={{ id: record.id?.toString() ?? "" }}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      {t("menu.view")}
                    </Link>
                  );
                },
              },
            ]}
            pagination={{
              position: ["bottomCenter"],
              total: data?.count,
              showSizeChanger: false,
              disabled: isFetching,
              current: eventListFilter?.page,
              onChange: handlePaginateChange,
            }}
          />
        </div>
      </MainDashboard>
    </div>
  );
};

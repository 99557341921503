import { atomWithSuspenseQuery } from "jotai-tanstack-query";
import { atomFamily } from "jotai/vanilla/utils";

import atomWithDebounce from "@/jotai-atoms/atomWithDebounce";
import { getDefaultPaginateValues } from "@/lib/utils";
import { fetchApiClient } from "@/lib/utils/fetchUtils";
import { createQuery } from "@/lib/utils/tanstack-query";
import { FacilitiesParams } from "@/types/ApiParams";

export type FacilitiesFilterState =
  FacilitiesParams<"getListFacilitiesWithPagination">;

const FACILITIES = "FACILITIES";
const createFacilitiesQuery = createQuery(FACILITIES);

const facilitiesAtomFilter = atomWithDebounce<FacilitiesFilterState>(
  getDefaultPaginateValues(),
);

export const facilitiesQueries = {
  list: createFacilitiesQuery(
    fetchApiClient.facilities.getListFacilitiesWithPagination,
  ),
  allFacilityType: createFacilitiesQuery(
    fetchApiClient.facilityTypes.getAllFacilityTypes,
  ),
  details: createFacilitiesQuery(fetchApiClient.facilities.getAFacility),
};

export const facilitiesAtoms = {
  filter: facilitiesAtomFilter,
  list: atomWithSuspenseQuery((get) =>
    facilitiesQueries.list(get(facilitiesAtomFilter.debouncedValueAtom)),
  ),
  allFacilityType: atomWithSuspenseQuery(() =>
    facilitiesQueries.allFacilityType({}),
  ),
  details: atomFamily((id: string) =>
    atomWithSuspenseQuery(() => facilitiesQueries.details(Number(id))),
  ),
};

import { Form, FormInstance, InputNumber, Typography } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { cn } from "@/lib/utils";
import { WebServiceRatingPayload } from "@/types/Api";

import { Editor } from "./Editor";

interface Props {
  className?: string;
  hasDefaultValue: boolean;
  isEditMode: boolean;
  formData: FormInstance;
  rating?: Omit<Partial<WebServiceRatingPayload>, "event_service">;
}

const RatingForm = ({
  className,
  hasDefaultValue,
  isEditMode,
  rating,
}: Props) => {
  const [comment, setComment] = useState(rating?.comment ?? "");
  const { t } = useTranslation("dashboard");

  return (
    <div className={className}>
      <div className="col-span-1">
        <Form.Item
          label={
            <Typography className="font-medium">
              {t("menu.facilities.rating")}
            </Typography>
          }
          name={["service", "rate", "rating"]}
          required
          initialValue={
            hasDefaultValue && rating?.rating
              ? Number(rating.rating)
              : undefined
          }
          rules={[
            {
              min: 1,
              message: t("menu.validation.min", { min: 1 }),
              type: "number",
            },
            {
              max: 5,
              message: t("menu.validation.max", { max: 5 }),
              type: "number",
            },
            {
              required: true,
              message: t("menu.required"),
            },
          ]}
        >
          <InputNumber
            className={cn("w-full", hasDefaultValue ? "!text-gray-800" : "")}
            disabled={!isEditMode}
            placeholder={t("menu.editHere")}
            readOnly={hasDefaultValue}
            type="number"
          />
        </Form.Item>
      </div>
      <div className="col-span-1">
        <Form.Item
          label={
            <Typography className="font-medium">
              {t("menu.events.numberOfParticipants")}
            </Typography>
          }
          required
          name={["service", "rate", "total_participants"]}
          initialValue={
            hasDefaultValue && rating?.total_participants
              ? rating.total_participants
              : undefined
          }
          rules={[
            {
              required: true,
              message: t("menu.required"),
            },
          ]}
        >
          <InputNumber
            className={cn("w-full", hasDefaultValue ? "!text-gray-800" : "")}
            disabled={!isEditMode}
            placeholder={t("menu.editHere")}
            readOnly={hasDefaultValue}
            type="number"
          />
        </Form.Item>
      </div>
      <div className="col-span-2">
        <Form.Item
          label={
            <Typography className="font-medium">
              {t("menu.events.comment")}
            </Typography>
          }
          name={["service", "rate", "comment"]}
          initialValue={
            hasDefaultValue && rating?.comment ? rating.comment : undefined
          }
        >
          <Editor
            placeholder={t("menu.editHere")}
            onChange={(data) => {
              setComment(data);
            }}
            data={comment}
            disabled={!isEditMode}
            readonly={hasDefaultValue}
          />
        </Form.Item>
      </div>
    </div>
  );
};

export default RatingForm;

import { createFileRoute, Link } from "@tanstack/react-router";
import { Badge, Table } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components";
import { useCreateHandleFilterFunc } from "@/hooks/useCreateHandleFilterFunc";
import { useDataSource } from "@/hooks/useDataSource";
import { useOnRowTable } from "@/hooks/useOnRowTable";
import {
  useEventServiceRequests,
  useEventServiceRequestsFilter,
} from "@/jotai-atoms/event-service";
import { DATE_ONLY } from "@/lib/constants";

import EventServiceRequestsHeader from "../-components/ListHeaders/EventServiceRequestsHeader";
import { eventServiceRequestsLoader } from "../-data/loaders";

export const Route = createFileRoute("/_auth/_dashboard/facilities/requests/")({
  component: () => <EventServiceRequestsPage />,
  beforeLoad: () => {
    return {
      title: null,
    };
  },
  loader: eventServiceRequestsLoader,
});

function EventServiceRequestsPage() {
  const { t } = useTranslation("dashboard");
  const onRow = useOnRowTable({ to: "/facilities/requests/$id" });
  const { data, isFetching } = useEventServiceRequests();
  const { filter, setFilter } = useEventServiceRequestsFilter();
  const { handleSearchChange, handlePaginateChange } =
    useCreateHandleFilterFunc(setFilter);
  const dataSource = useDataSource(data?.results);

  return (
    <MainDashboard>
      <div className="bg-white p-6 py-4">
        <EventServiceRequestsHeader onSearchChange={handleSearchChange} />
        <Table
          loading={isFetching}
          pagination={{
            position: ["bottomCenter"],
            total: data?.count,
            showSizeChanger: false,
            current: filter?.page,
            onChange: handlePaginateChange,
          }}
          onRow={onRow}
          dataSource={dataSource}
          columns={[
            {
              title: "ID",
              key: "id",
              width: "1ch",
              render: (_, record) => {
                return (
                  <Link
                    className="text-[var(--ant-primary-color)]"
                    to="/oasis/advisor/$id"
                    params={{ id: String(record.id) }}
                  >
                    {record.id}
                  </Link>
                );
              },
            },
            {
              title: t("menu.eventServices.eventName"),
              key: "name",
              render: (_, record) =>
                record.event_en_title ?? record.event_title,
            },
            {
              title: t("menu.eventServices.requestingDepartment"),
              key: "department",
              render: (_, record) => record.requesting_department,
            },
            {
              title: t("menu.facilities.type"),
              key: "type",
              render: (_, record) =>
                record.event_types?.[0]?.en_name ?? "--/--",
            },
            {
              title: t("menu.status"),
              key: "rating",
              render: (_, record) => {
                const badgeStatus = {
                  APPROVED: "success",
                  NEW: "processing",
                  REJECTED: "default",
                } as const;
                return (
                  <Badge
                    className="flex items-center"
                    text={record.status_display}
                    status={badgeStatus[record.status]}
                  />
                );
              },
            },
            {
              title: t("menu.facilities.rating"),
              key: "rating",
              render: (_, record) => record.rating,
            },
            {
              title: t("menu.updatedDate"),
              key: "date",
              render: (_, record) =>
                record.updated_at
                  ? dayjs(record.updated_at).format(DATE_ONLY)
                  : "",
            },
          ]}
        />
      </div>
    </MainDashboard>
  );
}

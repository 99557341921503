import { Form, Select, Typography } from "antd";
import { Controller, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Fragment } from "react/jsx-runtime";

import { filterSelectOptions } from "@/lib/utils";
import { NotificationFormType, NotificationUserCategories } from "@/types";

interface Props {
  targetType: NotificationUserCategories;
  form: UseFormReturn<NotificationFormType>;
  isEdit?: boolean;
  targetCustomersOptions: { label: string; value?: string | number }[];
  eventsOptions: { label: string; value?: string | number }[];
}

const ContentTargetType = ({
  targetType,
  form,
  isEdit,
  targetCustomersOptions,
  eventsOptions,
}: Props) => {
  const { t } = useTranslation("dashboard");

  if (targetType === "customer") {
    return (
      <Controller
        control={form.control}
        name="target_customer"
        key="target_customer"
        render={({ field, fieldState: { error } }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.targetCustomer.listOfTargetCustomer")}
                </Typography.Text>
              }
              className="col-span-2 mb-0"
              validateStatus={error ? "error" : ""}
              help={error?.message}
            >
              <Select
                {...field}
                showArrow
                showSearch
                maxTagTextLength={24}
                options={targetCustomersOptions}
                filterOption={filterSelectOptions}
                placeholder={t("menu.select", {
                  what: t("menu.targetCustomer.targetCustomer").toLowerCase(),
                })}
                disabled={!isEdit}
                value={field.value}
              />
            </Form.Item>
          );
        }}
      />
    );
  }

  if (targetType === "event") {
    return (
      <Controller
        control={form.control}
        name="related_event"
        key="related_event"
        render={({ field, fieldState: { error } }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.events.list")}
                </Typography.Text>
              }
              className="col-span-2 mb-0"
              validateStatus={error ? "error" : ""}
              help={error?.message}
            >
              <Select
                {...field}
                showArrow
                showSearch
                maxTagTextLength={24}
                options={eventsOptions}
                filterOption={filterSelectOptions}
                placeholder={t("menu.select", {
                  what: t("menu.events.single"),
                })}
                disabled={!isEdit}
                value={field.value}
              />
            </Form.Item>
          );
        }}
      />
    );
  }

  return <Fragment />;
};

export default ContentTargetType;

import { TFunction } from "i18next";
import * as yup from "yup";

export const facilitySchema = (t: TFunction) =>
  yup.object().shape({
    name: yup.string().required(
      t("menu.validation.required", {
        what: t("menu.name", { ns: "dashboard" }),
      }),
    ),
    description: yup.string().nullable(),
    type: yup.number().nullable(),
    image: yup.string().nullable(),
    quantity: yup
      .number()
      .required(
        t("menu.validation.required", {
          what: t("menu.facilities.quantity", { ns: "dashboard" }),
        }),
      )
      .min(
        1,
        t("menu.validation.atLeast", {
          what: t("menu.facilities.quantity", { ns: "dashboard" }),
          number: 1,
        }),
      ),
  });
